export const multiselectStyles = {
  container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer bg-gray-200 text-sm leading-snug outline-none rounded h-[24px]',
  containerDisabled: 'cursor-default bg-gray-100',
  containerOpen: 'rounded-b-none',
  containerOpenTop: 'rounded-t-none',
  containerActive: 'ring-1 ring-gray-500 border-none outline-none focus:border-none focus:outline-none',
  singleLabel: 'absolute left-0 right-12 pointer-events-none overflow-hidden overflow-ellipsis whitespace-nowrap bg-transparent leading-snug px-2 py-1',
  multipleLabel: 'text-sm absolute left-0 right-12 pointer-events-none overflow-hidden overflow-ellipsis whitespace-nowrap bg-transparent leading-snug px-2 py-1',
  search: 'w-full bg-gray-200 border-none absolute inset-0 outline-none box-border text-sm rounded px-2 py-0.5 focus:ring-gray-500',
  tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0',
  tag: 'min-w-0 break-all bg-blue-200 text-text-primary text-sm py-0.5 pl-2 rounded mr-1 mb-1 flex items-center',
  tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
  tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
  tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
  tagsSearch: 'absolute bg-transparent inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full focus:outline-none focus:border-transparent focus:ring-0',
  tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
  placeholder: 'absolute left-0 right-6 overflow-hidden overflow-ellipsis whitespace-nowrap pointer-events-none bg-transparent leading-snug px-2 text-gray-500 text-sm',
  caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-5 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
  caretOpen: 'rotate-180 pointer-events-auto',
  clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
  clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
  spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
  dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-500 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
  dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
  dropdownHidden: 'hidden',
  options: 'flex flex-col p-0 m-0 list-none w-max min-w-full',
  optionsTop: 'flex-col-reverse',
  group: 'p-0 m-0',
  groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
  groupLabelPointable: 'cursor-pointer',
  groupLabelPointed: 'bg-gray-300 text-gray-700',
  groupLabelSelected: 'bg-indigo-600 text-white',
  groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
  groupLabelSelectedPointed: 'bg-indigo-600 text-white opacity-90',
  groupLabelSelectedDisabled: 'text-indigo-100 bg-indigo-600 bg-opacity-50 cursor-not-allowed',
  groupOptions: 'p-0 m-0',
  option: 'flex items-center justify-start box-border text-left cursor-pointer text-xs leading-snug py-1 px-2',
  optionPointed: 'text-gray-800 bg-gray-100',
  optionSelected: 'text-primary-600 bg-primary-100',
  optionDisabled: 'text-gray-300 cursor-not-allowed',
  optionSelectedPointed: 'text-primary-600 bg-primary-100 opacity-90',
  optionSelectedDisabled: 'text-indigo-100 bg-indigo-500 bg-opacity-50 cursor-not-allowed',
  noOptions: 'py-2 px-3 text-sm text-gray-900 bg-white',
  noResults: 'py-2 px-3 text-sm text-gray-900 bg-white',
  fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent py-1',
  spacer: 'h-6 py-px box-content'
}
