<template>
  <div
    class="relative w-full"
  >
    <Tooltip
      :closeable="true"
      :force-show="true"
      :reference-props="{
        'class': {
          'overflow-auto rounded': true,
        }
      }"
    >
      <SubDatatable
        v-model:items="rows"
        v-model:pagination="pagination"
        v-model:sorted-columns="filters.sorts.value"
        :is-loading="isLoading"
        :columns="computedColumns"
      >
        <template
          v-for="col in computedColumns"
          :key="col.field"
          #[col.slotName]="{ value, row }"
        >
          <ApprovalStatusComponent
            v-if="col.renderingType === 'approvalStatus' || col.field.endsWith('OptinStatus')"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <ArrayComponent
            v-else-if="col.renderingType === 'array'"
            :value="value || []"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <ToggleComponent
            v-else-if="typeof col.renderingType === 'string' && ['boolean', 'toggle'].includes(col.renderingType)"
            v-model="row.data[col.field]"
            :is-disabled="true"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <CurrencyComponent
            v-else-if="col.renderingType === 'currency'"
            :value="value || []"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <DateComponent
            v-else-if="col.renderingType === 'date'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <EnumComponent
            v-else-if="col.renderingType === 'enum'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <LetterComponent
            v-else-if="col.renderingType === 'letter'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <ListComponent
            v-else-if="col.renderingType === 'list'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <PriorityComponent
            v-else-if="col.renderingType === 'priority'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <TargetableComponent
            v-else-if="col.renderingType === 'targetable'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <TruncateComponent
            v-else-if="col.renderingType === 'truncate'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <WarningComponent
            v-else-if="col.renderingType === 'warning'"
            :error="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <WebsiteStatus
            v-else-if="col.renderingType === 'websiteStatus'"
            :status="value"
          />
          <BidderLinesStatus
            v-else-if="col.renderingType === 'bidderLinesStatus'"
            :status="value"
          />
          <CountryComponent
            v-else-if="col.renderingType === 'country'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <LargeNumberComponent
            v-else-if="col.renderingType === 'largeNumber'"
            :value="value"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
          />
          <component
            :is="col.renderer && col.renderer(row)"
            v-else-if="col.renderingType === 'custom'"
            v-bind="col.attrs ? col.attrs(value, row) : {}"
            :row="row"
          />
          <template v-else>
            {{ value }}
          </template>
        </template>
      </SubDatatable>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios'
import { computed, defineComponent, PropType, ref, unref } from 'vue'
import { useRequest } from 'vue-request'

import { defaultPagination, PaginateWithoutRecords } from '@/types/paginate'

import ApprovalStatusComponent from '@/plugins/datatable/Components/ApprovalStatusComponent.vue'
import ArrayComponent from '@/plugins/datatable/Components/ArrayComponent.vue'
import CountryComponent from '@/plugins/datatable/Components/CountryComponent.vue'
import CurrencyComponent from '@/plugins/datatable/Components/CurrencyComponent.vue'
import DateComponent from '@/plugins/datatable/Components/DateComponent.vue'
import EnumComponent from '@/plugins/datatable/Components/EnumComponent.vue'
import LargeNumberComponent from '@/plugins/datatable/Components/LargeNumberComponent.vue'
import LetterComponent from '@/plugins/datatable/Components/LetterComponent.vue'
import ListComponent from '@/plugins/datatable/Components/ListComponent.vue'
import PriorityComponent from '@/plugins/datatable/Components/PriorityComponent.vue'
import TargetableComponent from '@/plugins/datatable/Components/TargetableComponent.vue'
import ToggleComponent from '@/plugins/datatable/Components/ToggleComponent.vue'
import TruncateComponent from '@/plugins/datatable/Components/TruncateComponent.vue'
import WarningComponent from '@/plugins/datatable/Components/WarningComponent.vue'
import { useDatatable, useDatatableColumns } from '@/plugins/datatable/datatable'
import { Column, FieldType } from '@/plugins/datatable/datatable.d'
import SubDatatable from '@/plugins/datatable/SubDatatable.vue'
import { extractResponse } from '@/plugins/datatable/utils'
import { ServerFilters } from '@/plugins/filters/filters'

import Tooltip from '@/components/Tooltip/Tooltip.vue'
import BidderLinesStatus from '@/features/websites/components/BidderLinesStatus.vue'
import WebsiteStatus from '@/features/websites/components/WebsiteStatus.vue'

import AppButton from '../Buttons/AppButton.vue'

interface ColmunWithSlotName extends Column {
  slotName: string
}

export default defineComponent({
  components: {
    ArrayComponent,
    DateComponent,
    ToggleComponent,
    EnumComponent,
    PriorityComponent,
    SubDatatable,
    ApprovalStatusComponent,
    Tooltip,
    CurrencyComponent,
    LetterComponent,
    ListComponent,
    TargetableComponent,
    TruncateComponent,
    WarningComponent,
    WebsiteStatus,
    CountryComponent,
    LargeNumberComponent,
    BidderLinesStatus,
    AppButton
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true
    },
    api: {
      type: Function as PropType<(...opts: any) => Promise<AxiosResponse>>,
      required: true
    },
    queryParams: {
      type: Object as PropType<Record<string, any>>,
      default: undefined
    },
    apiArgs: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    apiProcessing: {
      type: Function as PropType<(...opts: any) => any[]>,
      required: false,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:loading', 'records-updated', 'rows-selected', 'update:columns'],
  setup (props, { emit }) {
    const disclosureButtonRef = ref<any>()

    const pagination = ref<PaginateWithoutRecords>(defaultPagination())
    const records = ref<any[]>([])

    const datatableColumns = useDatatableColumns(props.columns)
    const computedColumns = computed<ColmunWithSlotName[]>(() => datatableColumns.columns.value.map((c: Column) => {
      const copy = Object.assign({}, c) as ColmunWithSlotName
      copy.slotName = copy.field.replaceAll('.', '__')
      return copy
    }))
    const dtColumns = computed({
      get () {
        return datatableColumns.columns.value
      },
      set (columns: Column[]) {
        datatableColumns.columns.value = columns
      }
    })

    const api = (queryParams?: any) => {
      emit('update:loading', true)

      let params = {}

      if (props.queryParams?.filter && queryParams?.filter) {
        params = {
          ...queryParams,
          ...props.queryParams,
          filter: [
            ...(Array.isArray(props.queryParams?.filter) ? props.queryParams?.filter : [props.queryParams?.filter]),
            ...(Array.isArray(queryParams.filter) ? queryParams.filter : [queryParams.filter])
          ]
        }
      } else if (props.queryParams) {
        params = { ...queryParams, ...props.queryParams }
      } else {
        params = { ...queryParams }
      }

      return props.api(...props.apiArgs.map(p => unref(p)), params)
    }

    const { run, loading } = useRequest(api, {
      manual: true,
      onSuccess: (response) => {
        if (response) {
          if (response.data) {
            const { records: r, pagination: p } = extractResponse<any>(response.data)
            records.value.push(...props.apiProcessing ? props.apiProcessing(r) : r)
            pagination.value = p
            emit('records-updated', r)
          }
        }
      },
      onAfter: () => {
        emit('update:loading', false)
      }
    })

    const filters = new ServerFilters(run, datatableColumns, 'unknown', pagination, undefined, true)
    const { rows } = useDatatable(records, datatableColumns, filters, {
      sorts: 'server'
    })

    return {
      disclosureButtonRef,
      datatableColumns,
      dtColumns,
      computedColumns,
      rows,
      filters,
      pagination,
      loading,
      FieldType
    }
  }
})
</script>
