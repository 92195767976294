import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { getPermission } from '@/plugins/permissions'
import segment from '@/plugins/segmentio'

import { useAppStore } from '@/store/app.store'
import { useContextStore } from '@/store/context.store'
import { useInviteStore } from '@/store/invite.store'
import { useProfileStore } from '@/store/profile.store'

import { LoginForm } from '@/models/auth'

import { login as loginService } from '@/services/auth'

import { router } from '@/router'

import { useContextNotificationsStore } from './contextNotifications.store'

export const useAuthStore = defineStore('auth', () => {
  const appStore = useAppStore()
  const inviteStore = useInviteStore()
  const profileStore = useProfileStore()
  const contextNotificationStore = useContextNotificationsStore()
  const contextStore = useContextStore()

  const accessTokenExpiration = ref<number | null>(600000)
  const silentRefreshRetry = ref(false)
  const isFreshToken = ref(false)

  const accessToken = useLocalStorage<string | null>('accessToken', null)

  const isLogged = computed((): boolean => {
    return !!accessToken.value
  })

  const setAccessToken = (newAccessToken: string): void => {
    accessToken.value = newAccessToken
    isFreshToken.value = true
  }

  const setAccessTokenExpiration = (expiration: number) : void => {
    accessTokenExpiration.value = expiration
  }

  const setSilentRefreshRetry = (value: boolean): void => {
    silentRefreshRetry.value = value
  }

  const removeAccessToken = (): void => {
    accessToken.value = null
  }

  const login = async ({
    form,
    redirect
  }: {
    form: LoginForm
    redirect?: string
  }): Promise<void> => {
    try {
      if (!appStore.clientId) {
        await appStore.generateClientId()
      }
      if (appStore.clientId) {
        const response = await loginService({ ...form, clientId: appStore.clientId })

        if (response && response.data.accessToken) {
          setAccessToken(response.data.accessToken)

          accessTokenExpiration.value = response.data.expiration

          if (inviteStore.currentInvite) {
            router.push({
              name: 'invitationJoin',
              query: { token: inviteStore.currentInvite.token }
            })
          } else {
            if (redirect) {
              router.push(redirect)
            } else {
              router.push({ name: 'home' })
            }
          }
          const permission = getPermission(contextStore.context)
          segment.track('signedIn', { rememberMe: form.rememberMe, roles: permission?.roles })
        }
      }
    } catch (e: any) {
      if (e.response) {
        if (e.response.data?.message) {
          throw e
        }
      }
      console.error(e)
      router.push({ name: 'login' })
    }
  }

  const logout = (): void => {
    contextNotificationStore.clearInterval()
    profileStore.clearProfile()
    removeAccessToken()
    silentRefreshRetry.value = false
  }

  return {
    accessTokenExpiration,
    setAccessToken,
    setAccessTokenExpiration,
    silentRefreshRetry,
    setSilentRefreshRetry,
    isFreshToken,
    accessToken,
    isLogged,
    login,
    logout
  }
})
