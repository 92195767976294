<template>
  <form
    method="POST"
    class="flex flex-col h-full divide-y divide-gray-200"
    :novalidate="noValidation"
    @submit.prevent="handleSubmit"
  >
    <div :class="wrapperClass">
      <slot />
    </div>

    <slot
      name="actions"
      :on-cancel="cancelFunc"
      :is-submitting="isSubmitting"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      :is-view-only="viewOnly"
    >
      <PanelFormActions
        :on-cancel="cancelFunc"
        :is-submitting="isSubmitting"
        :is-disabled="isDisabled"
        :is-loading="isLoading"
        :is-view-only="viewOnly"
        :is-form-type-create="isFormTypeCreate"
      />
    </slot>
    <ConfirmModal
      v-if="withConfirm"
      :title="withConfirmOptions?.title || t('messages.updateConfirm.title')"
      :confirm-button-text="withConfirmOptions?.confirmButtonText"
      :cancel-button-text="withConfirmOptions?.cancelButtonText"
      :confirm-button-appearance="withConfirmOptions?.confirmButtonAppearance || 'primary'"
      :icon="withConfirmOptions?.icon"
      :icon-appearance="withConfirmOptions?.iconAppearance"
      :open="showConfirm"
      @confirm="onConfirm"
      @close="onCloseConfirm"
    >
      <slot name="modal-text">
        <i18n-t
          keypath="messages.updateConfirm.main"
          tag="p"
        >
          <template #strong>
            <strong>{{ t('messages.updateConfirm.strong') }}</strong>
          </template>
          <template #strong2>
            <strong>{{ t('messages.updateConfirm.strong2') }}</strong>
          </template>
        </i18n-t>
      </slot>
    </ConfirmModal>
    <ConfirmModal
      v-if="withCancelConfirm"
      :title="withCancelConfirmOptions?.title || t('messages.formCloseConfirm.title') "
      :confirm-button-text="withCancelConfirmOptions?.confirmButtonText || t('actions.discard')"
      :cancel-button-text="withCancelConfirmOptions?.cancelButtonText"
      :confirm-button-appearance="withCancelConfirmOptions?.confirmButtonAppearance"
      :icon="withCancelConfirmOptions?.icon"
      :icon-appearance="withCancelConfirmOptions?.iconAppearance"
      :open="showCancelConfirm"
      @confirm="onConfirmCancel"
      @close="onCloseCancelConfirm"
    >
      <slot name="cancel-modal-text">
        <i18n-t
          keypath="messages.formCloseConfirm.main"
          tag="p"
        >
          <template #strong>
            <strong>{{ t('messages.formCloseConfirm.strong') }}</strong>
          </template>
        </i18n-t>
      </slot>
    </ConfirmModal>
  </form>
</template>

<script lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import useConfirm, { ConfirmOptions } from '@/composables/useConfirm'

import { useAppStore } from '@/store/app.store'

import ConfirmModal from '@/components/Modal/Confirm.vue'

import { PANEL_CLOSE_EVENT, PanelCloseEvent, panelEmitter } from './panel'
import PanelFormActions from './PanelFormActions.vue'

export default defineComponent({
  components: {
    ConfirmModal,
    PanelFormActions
  },
  props: {
    isSubmitting: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function as PropType<() => void>,
      required: true
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isFormTypeCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    noValidation: {
      type: Boolean,
      default: false
    },
    withConfirm: {
      type: Boolean,
      required: false,
      default: false
    },
    withCancelConfirm: {
      type: Boolean,
      required: false,
      default: false
    },
    withConfirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: () => ({
        icon: InformationCircleIcon,
        iconAppearance: 'info'
      } as ConfirmOptions)
    },
    withCancelConfirmOptions: {
      type: Object as PropType<ConfirmOptions | undefined>,
      required: false,
      default: undefined
    },
    wrapperClass: {
      type: String,
      required: false,
      default: 'flex-1 h-0 overflow-y-auto'
    }
  },
  setup (props) {
    const { t } = useI18n()

    const appStore = useAppStore()

    const {
      showConfirm,
      onClick: handleSubmit,
      onConfirm,
      onCloseConfirm
    } = useConfirm(computed(() => props.withConfirm), computed(() => props.onSubmit || (() => {})), computed(() => props.withConfirmOptions?.onClose))

    const onCancel = computed(() => () => {
      if (props.onCancel) {
        props.onCancel()
      }
      appStore.closePanel()
    })
    const {
      showConfirm: showCancelConfirm,
      onClick: handleCancel,
      onConfirm: onConfirmCancel,
      onCloseConfirm: onCloseCancelConfirm
    } = useConfirm(computed(() => props.withCancelConfirm), onCancel, computed(() => props.withCancelConfirmOptions?.onClose))

    const cancelFunc = computed((): (() => void) => {
      if (props.withCancelConfirm) {
        return () => { showCancelConfirm.value = true }
      }
      return onCancel.value
    })

    const onPanelCloseEvent = (ev: PanelCloseEvent) => {
      if (!showCancelConfirm.value && !ev.keyEvent.defaultPrevented) {
        ev.keyEvent.preventDefault()
        ev.keyEvent.stopPropagation()
        cancelFunc.value()
      }
      ev.cancelled = true
    }

    onMounted(() => {
      panelEmitter.on(PANEL_CLOSE_EVENT, onPanelCloseEvent)
    })

    onBeforeUnmount(() => {
      panelEmitter.off(PANEL_CLOSE_EVENT, onPanelCloseEvent)
    })

    return {
      t,
      showConfirm,
      handleSubmit,
      onConfirm,
      onCloseConfirm,

      showCancelConfirm,
      handleCancel,
      onConfirmCancel,
      onCloseCancelConfirm,

      cancelFunc
    }
  }
})
</script>
