import { AxiosError } from 'axios'

import i18n from '@/plugins/i18n'

export type FieldErrors = {
  $fields?: Record<string, FieldErrors>
  $elements?: Record<string, FieldErrors>
  $errors?: string[]
  // [field: string]: FieldErrors // For vuelidate compatibility
}

export interface ServerErrors {
  fieldsErrors?: Record<string, FieldErrors>
  message?: string
}

export function handleServerErrors (err: unknown, beforeTransform?: (errors: any) => any): ServerErrors {
  const serverError = err as AxiosError<any>

  if (serverError?.response?.data) {
    if (serverError.response.data.validationError) {
      let errors = serverError.response.data.validationError

      if (beforeTransform) {
        errors = beforeTransform(errors)
      }

      return {
        fieldsErrors: transformServerErrors(errors)
      }
    }

    const { message: serverMessage, error } = serverError.response.data

    // todo handle when message or error are not strings (if needed)
    if (typeof serverMessage === 'string' || typeof error === 'string') {
      return {
        message: serverMessage || error
      }
    }
  }

  return {
    message: i18n.global.t('labels.unknownError')
  }
}

function transformServerErrors (errs?: Record<string, any>): Record<string, FieldErrors> | undefined {
  if (errs === undefined) {
    return undefined
  }
  const fieldErrors: Record<string, FieldErrors> = {}

  Object.entries(errs).forEach(e => {
    fieldErrors[e[0]] = {
      $errors: e[1].errors,
      $elements: transformServerErrors(e[1].elements),
      $fields: transformServerErrors(e[1].fields)
    }

    if (e[1].fields) {
      Object.entries(e[1].fields as Record<string, any>).forEach(ef => {
        (fieldErrors[e[0]] as any)[ef[0]] = ef[1].errors
      })
    }
  })

  return fieldErrors
}

export function cleanExternalErrors (errs?: FieldErrors): boolean {
  if (!errs) {
    return true
  }

  if (errs.$elements) {
    Object.entries(errs.$elements).forEach(e => {
      if (cleanExternalErrors(e[1])) {
        delete errs.$elements![e[0]]
      }
    })

    if (Object.keys(errs.$elements).length === 0) {
      errs.$elements = undefined
    }
  }
  if (errs.$fields) {
    Object.entries(errs.$fields).forEach(e => {
      if (cleanExternalErrors(e[1])) {
        delete errs.$fields![e[0]]
      }
    })
    if (Object.keys(errs.$fields).length === 0) {
      errs.$fields = undefined
    }
  }

  if (errs.$errors && errs.$errors.length === 0) {
    errs.$errors = undefined
  }

  return !errs.$errors && !errs.$elements && !errs.$fields
}
