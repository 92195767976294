import { Component, InputHTMLAttributes } from 'vue'

import { ConfirmOptions } from '@/composables/useConfirm'

import FormComponent from '@/components/Form/FormToggle.vue'

import { FormRenderer, DefaultProps } from './'

interface Props extends DefaultProps {
  truthyColor?: string
  falsyColor?: string
  attr?: InputHTMLAttributes
  values?: { checked: any, unchecked: any }
  labelPosition?: 'top' | 'right' | 'left'
  uncheckConfirm?: boolean
  uncheckConfirmOptions?: ConfirmOptions
  events?: Record<string, Function>
}

export class FormToggle implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    return { ...this.defaultProps, ...this.defaultProps.attr }
  }

  events (): Record<string, Function> {
    return this.defaultProps.events || {}
  }

  component (): Component {
    return FormComponent
  }
}
