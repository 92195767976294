import { isDate, isValid } from 'date-fns'
import camelCase from 'lodash/camelCase'
import { Ref, toRaw } from 'vue'
import { LocationQueryValue, Router } from 'vue-router'

import { Column, DatatableColumns, FieldType } from '@/plugins/datatable/datatable.d'
import { Filter, Sort, SortOrder } from '@/plugins/filters'

import { useViewStore } from '@/store/view.store'

import { View } from '@/models/view'

import { arrayOperators, CondFilter, CondOperator, dateRangeOperators } from './operators'

export interface Source {
  filters: Ref<Filter[]>
  filtersCond: Ref<CondFilter | undefined>
  sorts: Ref<Sort[]>
  currentPage: Ref<number>
  currentView: Ref<View | undefined | null>
  search: Ref<string>
}

export class QueryParser {
  source: Source
  columns: DatatableColumns

  constructor (columns: DatatableColumns, router: Router, source: Source, ignoreQueryURL: boolean = false) {
    this.source = source
    this.columns = columns

    if (!ignoreQueryURL && router.currentRoute.value.query) {
      if (router.currentRoute.value.query.search && typeof router.currentRoute.value.query.search === 'string') {
        this.parseSearch(router.currentRoute.value.query.search)
      }

      if (router.currentRoute.value.query.view && typeof router.currentRoute.value.query.view === 'string') {
        // TODO check parseInt error (if view param is not a number)
        this.parseView(parseInt(router.currentRoute.value.query.view, 10))
      }

      if (router.currentRoute.value.query.page && typeof router.currentRoute.value.query.page === 'string') {
        this.parsePagination(parseInt(router.currentRoute.value.query.page, 10))
      }

      if (router.currentRoute.value.query.sort) {
        this.parseSorts(router.currentRoute.value.query.sort)
      }

      if (router.currentRoute.value.query.filter) {
        this.parseFilters(router.currentRoute.value.query.filter, CondFilter.AND)
      }

      if (router.currentRoute.value.query.or) {
        this.parseFilters(router.currentRoute.value.query.or, CondFilter.OR)
      }
    }
  }

  formatField (fieldKey: string): string {
    const byFilterKey = this.columns.columns.value.find((c: Column) => c.filterKey === fieldKey)
    if (byFilterKey) {
      return byFilterKey.filterKey!
    }
    const byField = this.columns.columns.value.find((c: Column) => c.field === fieldKey.split('.').map(camelCase).join('.'))
    if (byField) {
      return byField.field
    }

    return ''
  }

  parseSorts (value: LocationQueryValue[] | string, append: boolean = false): void {
    if (value) {
      const localSorts = []

      if (Array.isArray(value)) {
        value.forEach((sort) => {
          if (sort) {
            const sortSplited = sort.split(',')

            if (sortSplited.length === 2) {
              const field = this.formatField(sortSplited[0])
              if (field !== '') {
                const sortOrder = sortSplited[1] as SortOrder
                localSorts.push({ field, sortOrder })
              }
            }
          }
        })
      } else {
        const sortSplited = value.split(',')

        if (sortSplited.length === 2) {
          const field = this.formatField(sortSplited[0])
          if (field !== '') {
            const sortOrder = sortSplited[1] as SortOrder
            localSorts.push({ field, sortOrder })
          }
        }
      }

      if (append) {
        const newSorts = localSorts.filter(s => this.source.sorts.value.findIndex(s2 => s2.field === s.field) === -1)
        this.source.sorts.value.push(...newSorts)
        return
      }
      this.source.sorts.value = localSorts
    }
  }

  parseFilters (value: LocationQueryValue[] | string, cond: CondFilter, append: boolean = false): void {
    this.source.filtersCond.value = cond

    const filters: Filter[] = []

    // Handle multiples filters value
    if (Array.isArray(value)) {
      filters.push(...value.map((v) => v?.split('||'))
        .map((v) => this.parseFilter(v))
        .filter((v) => v !== undefined) as Filter[])
    } else {
      const filter = value.split('||')

      const parsedFilter = this.parseFilter(filter)

      if (parsedFilter) {
        filters.push(parsedFilter)
      }
    }

    if (append) {
      const source = toRaw(this.source.filters.value)
      const sourceFields = new Set(source.map(s => s.fieldKey))
      this.source.filters.value = source.concat(filters.filter(i => !sourceFields.has(i.fieldKey)))
      return
    }
    this.source.filters.value = filters
  }

  parseFilter (filter?: string[]): Filter | undefined {
    if (filter && filter.length > 1) {
      const fieldKey = this.formatField(filter[0])
      if (fieldKey === '') {
        return undefined
      }
      const operator = filter[1] as CondOperator

      // Check if the operator is valid
      if (!Object.values(CondOperator).includes(operator)) {
        return undefined
      }

      let value: any = filter[2]

      // Check if the value is present
      if (value) {
        // Handle number value
        if (!isNaN(value)) {
          value = parseFloat(value)

          if (arrayOperators.includes(operator)) {
            value = [value]
          }
        }

        // Handle string separate by , ( like an array )
        if (typeof (value) === 'string') {
          if (value.includes(',')) {
            const valueSplited = value.split(',')

            value = valueSplited
            if (dateRangeOperators.includes(operator) &&
                isValid(new Date(valueSplited[0])) && isDate(new Date(valueSplited[0]))) {
              value = { start: new Date(valueSplited[0]), end: new Date(valueSplited[1]) }
            }
          } else {
            if (arrayOperators.includes(operator)) {
              value = [value]
            }
          }
        }
      }

      return {
        fieldKey,
        operator,
        value,
        fieldType: FieldType.UNKNOWN
      }
    }

    return undefined
  }

  parseSearch (value: string): void {
    this.source.search.value = value
  }

  parsePagination (value: number): void {
    this.source.currentPage.value = value
  }

  parseView (value: number): void {
    const viewStore = useViewStore()

    const view = viewStore.views.find((v) => v.id === value)

    this.source.currentView.value = view || null
  }
}
