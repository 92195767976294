import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { Ref, nextTick, ref, watch } from 'vue'
import { Router, useRouter } from 'vue-router'

import { PaginateWithoutRecords } from '@/types/paginate'

import { Context } from '@/plugins/context'

import { useContextStore } from '@/store/context.store'

import { View } from '@/models/view'

import { SetupCube } from '../cube/cube'
import { DatatableColumns, Row } from '../datatable/datatable.d'

import { QueryBuilder, QueryBuilderParams } from './builder'
import { CondFilter } from './operators'
import { QueryParser } from './parser'

import { ColumnProperty, Filter, Sort, SortOrder } from './index'

type UpdaterFn = (queryParams: QueryBuilderParams) => void

export class BaseFilters {
  sorts: Ref<Sort[]> = ref([])
  defaultColumns: ColumnProperty[] = []
  columns: Ref<ColumnProperty[]>
  search: Ref<string> = ref('')
  updateColumns: (columns: ColumnProperty[]) => void

  constructor (columns: DatatableColumns) {
    this.sorts = ref([])

    // Columns
    this.defaultColumns = columns.visibleColumns
    this.columns = ref(columns.visibleColumns)
    this.updateColumns = columns.updateColumnsVisibility(columns.columns)
  }

  getClassName (): string {
    return this.constructor.name
  }
}

export class ClientFilters extends BaseFilters {
  constructor (columns: DatatableColumns) {
    super(columns)

    watch(
      [this.sorts],
      () => {
        // TODO implement local filters
      }, { deep: true }
    )
  }
}

/**
 * @deprecated use ServerFilters
 */
export function Filters (updater: UpdaterFn, columns: DatatableColumns, tableName: string, pagination: Ref<PaginateWithoutRecords>): ServerFilters {
  return new ServerFilters(updater, columns, tableName, pagination)
}

export class ServerFilters extends BaseFilters {
  router: Router
  queryParser: QueryParser
  queryBuilder: QueryBuilder

  tableName: string

  filters: Ref<Filter[]> = ref([])
  filtersUpdated: Ref<Filter[]>

  filtersCond: Ref<CondFilter | undefined>

  properties: Ref<ColumnProperty[]>
  pagination: Ref<PaginateWithoutRecords>
  currentPage: Ref<number>
  selectedRows?: Ref<Row[]>
  currentView: Ref<View | undefined | null>
  defaultSorts?: Sort[]

  currentContext: Context | undefined

  debouncedUpdater: DebouncedFunc<() => void>

  // Views
  canSaveView: Ref<boolean>

  constructor (updater: UpdaterFn, columns: DatatableColumns, tableName: string, pagination: Ref<PaginateWithoutRecords>, selectedRows?: Ref<Row[]>, ignoreQueryURL: boolean = false, cube?: SetupCube) {
    super(columns)

    this.router = useRouter()

    this.tableName = tableName

    this.filtersUpdated = ref([])
    this.filtersCond = ref()

    this.properties = ref([])
    this.pagination = pagination
    this.currentPage = ref(1)
    this.selectedRows = selectedRows
    this.currentView = ref()

    this.canSaveView = ref(false)

    const contextStore = useContextStore()
    this.currentContext = contextStore.context || undefined

    this.queryBuilder = new QueryBuilder(ignoreQueryURL ? undefined : this.router)

    this.queryParser = new QueryParser(columns, this.router, {
      filters: this.filters,
      filtersCond: this.filtersCond,
      sorts: this.sorts,
      currentPage: this.currentPage,
      search: this.search,
      currentView: this.currentView
    }, ignoreQueryURL)

    this.updateQueryBuilder()

    // We use a debounced function to avoid multiple queries when we programmatically update
    // all `Filters` properties like we do during a View initialization.
    this.debouncedUpdater = debounce(() => {
      if (cube) {
        cube.applyFilters({
          filters: this.filters,
          filtersCond: this.filtersCond,
          sorts: this.sorts,
          currentPage: this.currentPage,
          search: this.search,
          currentView: this.currentView
        })
        cube.run()
      } else {
        updater(this.queryBuilder.queryObject.value)
      }
    }, 300)

    this.debouncedUpdater()

    this.initWatchers(columns)
  }

  initWatchers (columns: DatatableColumns): void {
    watch(
      columns.columns,
      () => {
        this.columns.value = columns.columns.value.map((c: any) => ({ field: c.field, isVisible: typeof c.isVisible === 'boolean' ? c.isVisible : true }))
      }, { deep: true }
    )

    watch(
      this.pagination,
      (oldPagination, newPagination) => {
        if (oldPagination.currentPage > 0) {
          if (newPagination.currentPage > 0) {
            if (newPagination.currentPage !== this.currentPage.value) {
              this.currentPage.value = newPagination.currentPage
            }
          }
        }
      }, { deep: true }
    )

    watch(
      [this.filters, this.sorts, this.currentPage, this.search],
      () => {
        this.updateQueryBuilder()
        this.debouncedUpdater()
      }, { deep: true }
    )

    watch(
      () => [this.search, this.filters],
      () => {
        this.resetPage()
      }, { deep: true }
    )

    watch(
      () => this.router.currentRoute.value.path,
      () => {
        const contextStore = useContextStore()

        if (contextStore?.context?.resourceId !== this.currentContext?.resourceId ||
          contextStore?.context?.resourceType !== this.currentContext?.resourceType) {
          this.currentContext = contextStore.context

          this.search.value = ''
        }
      }
    )

    watch(
      this.currentView,
      (newView, oldView) => {
        const isInitial = oldView === undefined
        if (newView === undefined && isInitial) {
          nextTick(() => {
            this.currentView.value = null
          })
          return
        }

        // Update the query String
        this.queryBuilder.setView(this.currentView.value, this.updateColumns)

        // Update all the ref's
        this.canSaveView.value = true

        if (this.currentView.value) {
          if (this.currentView.value.content) {
            const overrideCond = isInitial && this.filtersCond.value !== undefined
            if (oldView !== undefined) {
              this.sorts.value = []
              this.filters.value = []
            }
            if (this.currentView.value.content.sort) {
              this.queryParser.parseSorts(this.currentView.value.content.sort, isInitial)
            }

            if (this.currentView.value.content.filter) {
              this.queryParser.parseFilters(this.currentView.value.content.filter, overrideCond ? this.filtersCond.value! : CondFilter.AND, isInitial)
            }

            if (this.currentView.value.content.or) {
              this.queryParser.parseFilters(this.currentView.value.content.or, overrideCond ? this.filtersCond.value! : CondFilter.OR, isInitial)
            }

            if (this.filters.value.length) {
              this.filtersUpdated.value = this.filters.value
            }
          }
        } else if (oldView !== newView && oldView !== undefined) {
          this.canSaveView.value = false
          this.sorts.value = this.defaultSorts ? [...this.defaultSorts] : []
          this.filters.value = []
          this.filtersUpdated.value = []
          this.updateColumns(this.defaultColumns)
        }
      }, { immediate: true }
    )

    watch(
      () => this.router.currentRoute.value.query.view,
      (v) => {
        let viewId: number | undefined
        if (v) {
          try {
            const value = Array.isArray(v) ? v[0] : v
            if (value === null) {
              return
            }
            viewId = parseInt(value)
          } catch (e) {
            return
          }
        }
        if (!viewId) {
          return
        }
        this.queryParser.parseView(viewId)
      }
    )
  }

  resetPage (): void {
    this.currentPage.value = 1
    this.pagination.value.currentPage = 1
  }

  updateQueryBuilder (): void {
    this.queryBuilder.setSorts(this.sorts.value)
    this.queryBuilder.setSearch(this.search.value)
    this.queryBuilder.setPagination(this.currentPage.value)

    if (this.filtersCond.value) {
      this.queryBuilder.setFilters(this.filters.value, this.filtersCond.value)
    }
  }

  setDefaultSort (...sorts: Sort[]): void {
    this.defaultSorts = sorts
    if (this.sorts.value.length === 0) {
      if (sorts.length === 0) {
        sorts.push({ field: 'id', sortOrder: SortOrder.ASC })
      }
      this.sorts.value.push(...sorts)
      this.queryBuilder.setSorts(this.sorts.value)
    }
  }

  setDefaultFilter (...filter: Filter[]): void {
    if (this.filters.value.length === 0) {
      this.filtersCond.value = CondFilter.AND
      this.filters.value.push(...filter)
      this.filtersUpdated.value = this.filters.value
      this.queryBuilder.setFilters(this.filters.value, this.filtersCond.value)
    }
  }

  get viewContent (): Record<string, any> {
    const result: { [key: string]: any } = {}

    if (this.queryBuilder.queryObject.value.filter) {
      result.filter = this.queryBuilder.queryObject.value.filter
    }

    if (this.queryBuilder.queryObject.value.or) {
      result.or = this.queryBuilder.queryObject.value.or
    }

    if (this.queryBuilder.queryObject.value.sort) {
      result.sort = this.queryBuilder.queryObject.value.sort
    }

    result.properties = this.columns.value

    return result
  }
}
