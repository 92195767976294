<template>
  <Popper
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    :reference-props="{
      'class': 'p-2 relative cursor-pointer select-none ' + (hasFilters ? 'text-primary-500 hover:text-primary-700' : 'text-text-primary hover:text-primary-500')
    }"
    :popper-props="{
      'class': 'absolute bg-white p-5 border border-gray-border rounded-md z-30 w-[800px] max-w-full'
    }"
    trigger="click-to-toggle"
    placement="bottom-end"
    :teleport-props="{ to: 'body' }"
  >
    <template #reference>
      <AdjustmentsVerticalIcon class="w-4 h-4" />
      <span
        v-if="hasFilters"
        class="absolute top-0 right-0 font-extrabold text-2xs text-text-primary"
      >
        {{ filtersController.filters.value.length }}
      </span>
    </template>

    <template #default>
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <AdjustmentsVerticalIcon class="w-4 h-4 text-text-primary" />
          <span class="text-sm font-semibold capitalize">{{ t('app.filter', 100) }}</span>
        </div>

        <div>
          <AppButton
            appearance="clear"
            size="sm"
            @click.stop="addFilter"
          >
            {{ t('actions.add') }}
          </AppButton>
        </div>
      </div>

      <div
        v-for="(f, index) in validatedFilters"
        :key="f.filter.id"
        class="flex flex-col space-y-4 mt-1.5"
      >
        <div class="flex items-center gap-4 py-2">
          <div class="w-[12%] text-sm text-right">
            <div v-if="index === 0">
              {{ t('filters.conditions.where') }}
            </div>
            <div v-else-if="index === 1">
              <select
                v-model="selectedCond"
                class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
              >
                <option :value="CondFilter.AND">
                  {{ translatedCondName[CondFilter.AND] }}
                </option>
                <option :value="CondFilter.OR">
                  {{ translatedCondName[CondFilter.OR] }}
                </option>
              </select>
            </div>
            <div v-else>
              {{ translatedCondName[selectedCond] }}
            </div>
          </div>
          <div class="w-[25%]">
            <select
              v-model="f.filter.fieldKey"
              class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
              @change="onUpdateFieldKey(f.filter.id)"
            >
              <option
                v-for="filter in availableFilters"
                :key="filter.fieldKey"
                :value="filter.fieldKey"
                :selected="filter.fieldKey === f.filter.fieldKey"
              >
                {{ filter.fieldName }}
              </option>
            </select>
          </div>
          <div class="w-[26%]">
            <select
              v-model="f.filter.operator"
              class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
              @change="onOperatorChange(f.filter.id)"
            >
              <option
                v-for="operator in getOperatorsByFieldType(f.filter.fieldType)"
                :key="operator"
                :value="operator"
                :selected="operator === f.filter.operator"
              >
                {{ translatedOperatorName[operator] }}
              </option>
            </select>
          </div>
          <Tooltip
            :disabled="f.valid"
            :closeable="true"
            :force-show="true"
            :placement="'top'"
            :reference-props="{
              class: 'w-[32%] flex gap-2'
            }"
          >
            <div
              v-for="(fieldValue, i) in getFieldsByFieldTypeAndOperator(f.filter.fieldType, f.filter.operator)"
              :key="i"
              class="w-full"
            >
              <input
                v-if="fieldValue.componentType === 'input' || fieldValue.componentType === undefined"
                :value="getInputValue(f.filter.fieldType, f.filter.operator, i, f.filter.value)"
                :disabled="fieldValue.disable"
                :type="fieldValue.fieldType"
                class="px-2 py-0.5 border-none bg-gray-200 text-sm rounded w-full focus:ring-gray-500"
                :class="{'outline-1 outline-red-400 ring-1 ring-red-400': !f.valid}"
                :placeholder="t('filters.value')"
                @change="onInputChange(f.filter.fieldType, f.filter.operator, i, f.filter.id, $event)"
              >
              <DatePicker
                v-if="fieldValue.componentType === 'date'"
                :key="datePickerKey"
                v-model="f.filter.value"
                :is-range="fieldValue.fieldType === 'daterange'"
                :max-date="getMaxDateByFieldKey(f.filter.fieldKey)"
              >
                <template #default="{ inputValue, togglePopover }">
                  <input
                    :value="getDatePickerValue(inputValue, fieldValue.fieldType)"
                    readonly
                    class="px-2 py-0.5 h-[24px] border-none bg-gray-200 text-sm rounded w-full focus:outline-none focus:ring-1 focus:ring-gray-500"
                    :class="{'outline-1 outline-red-400 ring-1 ring-red-400': !f.valid}"
                    :placeholder="t('filters.selectDate')"
                    @click.stop="togglePopover"
                  >
                </template>
              </DatePicker>
              <div v-if="fieldValue.componentType === 'multiselect' && getOptionsByFieldKey(f.filter.fieldKey).length > 0">
                <Multiselect
                  v-model="f.filter.value"
                  :classes="computedMultiselectStyles(f.valid)"
                  :mode="f.filter.fieldType === FieldType.STRING_LIST && ![CondOperator.IN, CondOperator.NOT_IN].includes(f.filter.operator) ? 'single' : 'multiple'"
                  track-by="name"
                  value-prop="id"
                  label="name"
                  :searchable="true"
                  :hide-selected="false"
                  :options="getOptionsByFieldKey(f.filter.fieldKey)"
                  :placeholder="t('filters.selectValue')"
                  :can-clear="false"
                />
              </div>
              <div v-if="fieldValue.componentType === 'multivalues' || (fieldValue.componentType === 'multiselect' && hasAsyncOptions(f.filter.fieldKey))">
                <Multiselect
                  v-model="f.filter.value"
                  label="value"
                  mode="multiple"
                  :classes="computedMultiselectStyles(f.valid)"
                  :close-on-select="false"
                  :delay="200"
                  :resolve-on-load="true"
                  :searchable="true"
                  :hide-selected="false"
                  :placeholder="t('filters.selectValue')"
                  :options="(query: string)=> getOptionsByFieldKeyExec(f.filter,query)"
                />
              </div>
            </div>

            <template
              v-if="!valid"
              #title
            >
              <div class="flex items-center">
                <span class="text-xs font-normal">{{ t('labels.required') }}</span>
              </div>
            </template>
          </Tooltip>
          <div class="w-[5%] flex justify-center">
            <button
              class="px-2 py-1 rounded hover:bg-gray-200"
              @click.stop="removeFilter(f.filter.id)"
            >
              <TrashIcon class="w-4 h-4 text-text-primary" />
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="localFilters.length === 0"
        class="flex items-center justify-center w-full font-medium h-60"
      >
        {{ t('filters.noFilters') }}
      </div>

      <div
        v-if="localFilters.length > 0"
        class="flex justify-between mt-1.5"
      >
        <AppButton
          appearance="clear"
          size="sm"
          @click.stop="resetFilters"
        >
          {{ t('actions.reset') }}
        </AppButton>

        <AppButton
          appearance="primary"
          size="sm"
          :is-disabled="!valid"
          @click="applyFilters"
        >
          {{ t('actions.apply') }}
        </AppButton>
      </div>
    </template>
  </popper>
</template>

<script lang="ts">
import { AdjustmentsVerticalIcon, TrashIcon } from '@heroicons/vue/24/solid'
import Multiselect from '@vueform/multiselect'
import { v1 as uuidv1 } from 'uuid'
import { DatePicker } from 'v-calendar'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { FieldType } from '@/plugins/datatable/datatable.d'
import { ColumnFilter, Filter } from '@/plugins/filters'
import { BaseFilters, ServerFilters } from '@/plugins/filters/filters'
import { CondFilter, CondOperator, CondOperatorValidator, getFieldsByFieldTypeAndOperator, getOperatorsByFieldType, operatorsByFieldType, translatedCondName, translatedOperatorName } from '@/plugins/filters/operators'

import { FilterResponse } from '@/services/datatableFilters'

import AppButton from '@/components/Buttons/AppButton.vue'
import Popper from '@/components/Tooltip/Popper.vue'

import Tooltip from '../Tooltip/Tooltip.vue'

import { multiselectStyles } from './Multiselect'

interface UniqueFilter {
  id: string
}

export default defineComponent({
  components: {
    Popper,
    AppButton,
    DatePicker,
    Multiselect,
    AdjustmentsVerticalIcon,
    TrashIcon,
    Tooltip
  },
  props: {
    availableFilters: {
      type: Array as PropType<ColumnFilter[]>,
      required: true
    },
    filters: {
      type: Object as PropType<BaseFilters>,
      required: true
    }
  },
  emits: ['update:filters'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const filtersController = computed({
      get () {
        return props.filters as ServerFilters
      },
      set (filters: ServerFilters) {
        emit('update:filters', filters)
      }
    })

    const selectedCond = ref<CondFilter>(CondFilter.AND)

    const options: any[] = []

    const localFilters = ref<Array<UniqueFilter & Filter>>([])

    const hasFilters = computed(() => filtersController.value.filters.value.length > 0)

    const validatedFilters = computed(() => localFilters.value.map(f => ({ filter: f, valid: CondOperatorValidator[f.operator](f.value) })))
    const valid = computed(() => validatedFilters.value.every(f => f.valid))

    const getFieldTypeFromFieldkey = (fieldKey: string) => {
      const filter = props.availableFilters.find((a) => a.fieldKey === fieldKey)

      if (filter) {
        return filter.fieldType
      }

      return undefined
    }

    const updateLocalFilters = () => {
      localFilters.value = []
      filtersController.value.filters.value.forEach((f) => {
        const fieldType = getFieldTypeFromFieldkey(f.fieldKey)

        if (fieldType) {
          localFilters.value.push({
            id: uuidv1(),
            fieldKey: f.fieldKey,
            fieldType,
            operator: f.operator,
            value: f.value
          })
        }
      })

      if (filtersController.value.filtersCond.value) {
        selectedCond.value = filtersController.value.filtersCond.value
      }
    }

    watch(
      [filtersController.value.filtersUpdated, filtersController.value.currentView],
      () => updateLocalFilters()
    )

    onMounted(() => updateLocalFilters())

    const onUpdateFieldKey = (id: string) => {
      const filter = localFilters.value.find((f) => f.id === id)

      if (filter) {
        const initalColumnFilter = props.availableFilters.find((f) => f.fieldKey === filter.fieldKey)

        if (initalColumnFilter) {
          filter.fieldType = initalColumnFilter.fieldType

          onFieldChange(filter, filter.fieldType)
        }
      }
    }

    const addFilter = () => {
      if (props.availableFilters.length) {
        localFilters.value.push({
          id: uuidv1(),
          fieldKey: props.availableFilters[0].fieldKey,
          fieldType: props.availableFilters[0].fieldType,
          operator: getOperatorsByFieldType(props.availableFilters[0].fieldType)[0]
        })
      }
    }

    const removeFilter = (id: string) => {
      const filterIndex = localFilters.value.findIndex((f) => f.id === id)

      if (filterIndex > -1) {
        localFilters.value.splice(filterIndex, 1)

        if (localFilters.value.length === 0) {
          resetFilters()
        }
      }
    }

    const resetFilters = () => {
      localFilters.value = []

      filtersController.value.filters.value = []
    }

    const applyFilters = () => {
      filtersController.value.filters.value = localFilters.value.map(f => ({ ...f }))
      filtersController.value.filtersCond.value = selectedCond.value
    }

    const onFieldChange = (filter: Filter, fieldType: FieldType) => {
      filter.operator = getOperatorsByFieldType(fieldType)[0]
      filter.value = undefined
    }

    const onOperatorChange = (id: string) => {
      const filter = localFilters.value.find((f) => f.id === id)

      if (filter) {
        filter.value = null
        datePickerKey.value = datePickerKey.value + 1
      }
    }

    const getDatePickerValue = (inputValue: any, fieldType?: string) => {
      if (fieldType === 'daterange') {
        return inputValue.start && inputValue.end ? `${inputValue.start} | ${inputValue.end}` : undefined
      }

      return inputValue
    }

    // Use to re-render DatePicker component
    // Because in case the where we update the operator
    // The value is not updated...
    const datePickerKey = ref(0)

    const getInputValue = (fieldType: FieldType, operator: CondOperator, index: number, value: any) => {
      if (!value) {
        return value
      }

      const countFields = getFieldsByFieldTypeAndOperator(fieldType, operator)

      if (countFields && countFields.length === 2) {
        return value[index]
      }

      return value
    }

    const onInputChange = (fieldType: FieldType, operator: CondOperator, index: number, id: string, e: Event) => {
      const filter = localFilters.value.find((f) => f.id === id)

      if (filter) {
        const countFields = getFieldsByFieldTypeAndOperator(fieldType, operator)
        const target = e.target as any

        if (countFields && countFields.length === 2) {
          if (!filter.value) {
            filter.value = []
          }

          filter.value[index] = target.value
        } else {
          filter.value = target.value
        }
      }
    }

    const getMaxDateByFieldKey = (fieldKey: string) => {
      const f = props.availableFilters.find((f) => f.fieldKey === fieldKey)

      if (f && f.dateOptions?.maxDate) {
        if (f.dateOptions?.maxDate === 'no') {
          return undefined
        }

        return f.dateOptions?.maxDate
      }

      return new Date()
    }

    const getOptionsByFieldKey = (fieldKey: string) => {
      const f = props.availableFilters.find((f) => f.fieldKey === fieldKey)

      if (f && f.options) {
        return f.options() || []
      }

      return []
    }

    const hasAsyncOptions = (fieldKey: string) => {
      const f = props.availableFilters.find((f) => f.fieldKey === fieldKey)
      return !!(f?.asyncOptions)
    }

    const getOptionsByFieldKeyExec = async (filter: Filter, query: string) => {
      const f = props.availableFilters.find((f) => f.fieldKey === filter.fieldKey)

      let options: FilterResponse[] = []

      if (filter.value && Array.isArray(filter.value)) {
        filter.value.forEach((v) => { options.push({ value: v } as FilterResponse) })
      }

      if (f && f.asyncOptions) {
        const result = await f.asyncOptions(filter.fieldKey, {
          ...(query && { search: query })
        }).then((response) => {
          if (response && response.status === 200) {
            return response.data
          }
          throw new Error('Fetch error')
        })

        options = options.concat(result)
      }

      // filter duplicated entries
      options = options.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.value === value.value
        ))
      )

      return options || []
    }

    const computedMultiselectStyles = (valid: boolean) => {
      // :class="{'outline-1 outline-red-400 ring-1 ring-red-400': !f.valid}"
      const classes = Object.assign({}, multiselectStyles)
      if (!valid) {
        classes.container += ' !border-none !ring-1 !ring-red-400'
        classes.search += ' !ring-0'
      }
      return classes
    }

    return {
      filtersController,
      selectedCond,
      onUpdateFieldKey,
      addFilter,
      removeFilter,
      resetFilters,
      applyFilters,
      localFilters,
      validatedFilters,
      t,
      hasFilters,
      valid,

      onFieldChange,
      onOperatorChange,
      getDatePickerValue,
      datePickerKey,

      getInputValue,
      onInputChange,
      getOptionsByFieldKey,
      hasAsyncOptions,
      getOptionsByFieldKeyExec,
      options,

      // Filters Plugins
      FieldType,
      CondOperator,
      CondFilter,
      translatedOperatorName,
      translatedCondName,
      operatorsByFieldType,
      getOperatorsByFieldType,
      getFieldsByFieldTypeAndOperator,
      getMaxDateByFieldKey,

      // Multiselect
      computedMultiselectStyles
    }
  }
})
</script>
