<template>
  <Popover class="relative">
    <PopoverButton
      class="inline-flex items-center w-full text-base rounded outline-none"
      :class="computedBtnClass"
    >
      <span
        v-if="label"
        class="w-full text-left capitalize"
      >
        {{ label }}{{ single && modelValue.length > 0 ? ': ' + valueLabel(modelValue[0]) : '' }}
      </span>
      <slot name="label" />
      <ChevronDownIcon
        v-if="!compactButton"
        class="w-5 h-5 ml-2"
        aria-hidden="true"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel
        v-if="!disabled"
        class="absolute z-20 min-w-full text-black select-none sm:text-sm"
        :class="popoverPanelClass"
      >
        <div
          class="flex flex-col min-w-full pt-1 mt-1 overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
          :class="popoverClass"
          @vue:mounted="onPopoverOpen"
        >
          <div class="relative flex flex-row px-2 border-b border-gray-300">
            <MagnifyingGlassIcon
              class="absolute top-0 bottom-0 w-4 h-4 m-auto"
              aria-hidden="true"
            />
            <input
              ref="searchInput"
              v-model="search"
              :placeholder="`${t('actions.search')}…`"
              class="flex-grow w-px py-1 pl-6 outline-none"
            >
            <XMarkIcon
              v-if="actualValue.length > 0 && canClear"
              class="absolute top-0 bottom-0 right-0 w-5 h-5 m-auto mr-2 bg-white cursor-pointer"
              :title="t('actions.clear')"
              @click="clear"
            />
          </div>
          <div
            class="p-2 overflow-x-hidden overflow-y-auto max-h-40"
            @scroll="onScroll"
          >
            <div
              ref="container"
              class="min-h-5"
            >
              <div
                v-if="cluster.length === 0"
                class="text-gray-400"
                :style="{transform: translation}"
              >
                {{ t('messages.resultCount', cluster.length) }}
              </div>
              <div
                v-for="v in cluster"
                :key="typeof v === 'object' ? v.value : v"
                class="flex flex-row items-center group"
                :style="{transform: translation}"
              >
                <template
                  v-for="(state, i) in [getItemState(v)]"
                  :key="i"
                >
                  <span class="p-1">
                    <input
                      :type="single ? 'radio' : 'checkbox'"
                      :checked="state.checked"
                      class="w-4 h-4 border-gray-400 text-primary-600 focus:ring-primary-500"
                      :class="{
                        'rounded-full': single,
                        'rounded': !single,
                        'bg-gray-300': state.disabled,
                        'cursor-not-allowed': state.disabled,
                        'cursor-pointer': !state.disabled,
                      }"
                      :disabled="state.disabled"
                      @input="single ? only($event, v) : onInput($event, v)"
                    >
                  </span>
                  <span
                    class="flex-grow min-w-0 p-1 overflow-hidden whitespace-nowrap overflow-ellipsis"
                    :title="typeof v === 'object' ? v.label : v"
                    :class="{
                      'cursor-not-allowed': state.disabled,
                      '!opacity-60': state.disabled,
                      'cursor-pointer': !state.disabled,
                      [entryClass]: true
                    }"
                    @click="single ? only($event, v) : onInput($event, v)"
                  >
                    {{ typeof v === 'object' ? v.label : v }}
                  </span>
                  <span
                    v-if="!single"
                    class="hidden group-hover:inline-block"
                  >
                    <button
                      type="button"
                      class="text-xs px-1 py-0.5 border border-gray-400 rounded-sm text-gray-700 hover:bg-gray-200"
                      @click="only($event, v)"
                    >
                      Only
                    </button>
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import difference from 'lodash/difference'
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { MultiselectValue } from '@/plugins/dashboard'

import { clusterize } from './cluster'

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    MagnifyingGlassIcon,
    XMarkIcon
  },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    value: {
      type: Array as PropType<string[] | undefined>,
      default: undefined
    },
    label: {
      type: String,
      default: () => undefined
    },
    values: {
      type: Array as PropType<Array<string | MultiselectValue>>,
      required: true
    },
    compactButton: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    canClear: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number, // The maximum number of elements selected
      default: undefined
    },
    popoverClass: {
      type: String as PropType<string>,
      default: () => ''
    },
    popoverPanelClass: {
      type: String as PropType<string>,
      default: () => ''
    },
    entryClass: {
      type: String as PropType<string>,
      default: () => ''
    },
    buttonClass: {
      type: String as PropType<string>,
      default: () => ''
    }
  },
  emits: ['input', 'update:modelValue'],
  setup (props, { emit }) {
    // Data
    const search = ref('')
    const searchInput = ref(null as HTMLInputElement | null)
    const container = ref(null as HTMLElement | null)

    const { t } = useI18n()

    const sortAlphabetically = (a: string | MultiselectValue, b: string | MultiselectValue): number => {
      const aVal = typeof a === 'object' ? a.label : a
      const bVal = typeof b === 'object' ? b.label : b
      if (aVal < bVal) {
        return -1
      }
      if (aVal > bVal) {
        return 1
      }
      return 0
    }

    // Computed
    const filteredValues = computed(() => {
      if (search.value === '') {
        return props.values.slice().sort(sortAlphabetically)
      }
      return props.values.filter(v => {
        const isObject = typeof v === 'object'
        if (isObject) {
          return (
            Array.isArray(v.value)
              ? v.value.some(e => e.toLowerCase().includes(search.value.toLowerCase()))
              : v.value.toLowerCase().includes(search.value.toLowerCase())
          ) || v.label.toLowerCase().includes(search.value.toLowerCase())
        }
        return v.toLowerCase().includes(search.value.toLowerCase())
      }).sort(sortAlphabetically)
    })
    const actualValue = computed(() => props.value !== undefined ? props.value : props.modelValue)

    const computedBtnClass = computed(() => {
      const classes: string[] = []
      classes.push(props.buttonClass)
      if (props.disabled) {
        classes.push('text-gray-300', 'cursor-not-allowed', 'focus:!ring-transparent', '!border-gray-400')
      }
      if (!props.compactButton) {
        classes.push('px-3', 'py-2', 'border', 'border-gray-400', 'focus:ring-primary-500', 'focus:border-primary-500')
      }
      return classes
    })

    // Methods
    const isChecked = (v: string | MultiselectValue): boolean => {
      if (typeof v === 'object') {
        if (Array.isArray(v.value)) {
          return difference(v.value, actualValue.value).length === 0
        }
        return actualValue.value.includes(v.value)
      }
      return actualValue.value.includes(v)
    }

    const getItemState = (v: string | MultiselectValue) => {
      const checked = isChecked(v)
      const disabled = props.max !== undefined && !checked && actualValue.value.length >= props.max
      return {
        checked,
        disabled
      }
    }

    const onInput = (event: Event, entry: string | MultiselectValue) => {
      event.preventDefault()
      const value = typeof entry === 'object' ? entry.value : entry
      const values = [...actualValue.value]

      if (Array.isArray(value)) {
        if (isChecked(entry)) {
          // Remove all
          value.forEach(v => {
            const i = values.indexOf(v)
            if (i !== -1) {
              values.splice(i, 1)
            }
          })
        } else {
          // Select missing
          value.filter(v => !values.includes(v)).forEach(v => {
            values.push(v)
          })
        }
      } else {
        const i = values.indexOf(value)
        if (i !== -1) {
          values.splice(i, 1)
        } else {
          values.push(value)
        }
      }
      if (props.max !== undefined && values.length > props.max) {
        return
      }
      emit('update:modelValue', values)
      emit('input', values)
    }

    const onPopoverOpen = () => {
      search.value = ''
      searchInput.value?.focus()
      // FIXME if overflow right or left (out of view), invert X origin
    }

    const only = (event: Event, v: string | MultiselectValue) => {
      event.preventDefault()
      const value = typeof v === 'object' ? v.value : v
      const newValue = [value]
      emit('update:modelValue', newValue)
      emit('input', newValue)
    }

    const clear = () => {
      const newValue: string[] = []
      emit('update:modelValue', newValue)
      emit('input', newValue)
      onPopoverOpen()
    }

    const valueLabel = (value: string) => {
      const val = props.values.find(v => (typeof v === 'object' ? v.value : v) === value)
      if (val === undefined) {
        return value
      }
      return typeof val === 'object' ? val.label : val
    }

    return {
      // Refs
      searchInput,
      container,

      // Data
      search,

      // Computed
      actualValue,
      computedBtnClass,

      // Methods
      onInput,
      isChecked,
      getItemState,
      onPopoverOpen,
      only,
      clear,
      valueLabel,

      // Misc
      t,
      ...clusterize(container, filteredValues)
    }
  }
})
</script>
