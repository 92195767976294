<template>
  <Tooltip
    :disabled="message == ''"
    trigger="hover"
    :reference-props="{
      title: '',
      class: ['relative inline-flex', $attrs.class]
    }"
    :force-show="false"
  >
    <template #default="{ visible }">
      <QuestionMarkSolid :class="['w-4 h-4 transition-opacity absolute top-0 left-0', {'opacity-0': !visible}]" />
      <QuestionMarkOutline :class="['w-4 h-4 transition-opacity', {'opacity-0': visible}]" />
    </template>
    <template #title>
      <span
        class="text-sm font-normal"
        :class="messageClass"
      >
        {{ message }}
      </span>
    </template>
  </Tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import QuestionMarkOutline from '../Icons/QuestionMarkOutline.vue'
import QuestionMarkSolid from '../Icons/QuestionMarkSolid.vue'

import Tooltip from './Tooltip.vue'

export default defineComponent({
  components: {
    QuestionMarkSolid,
    QuestionMarkOutline,
    Tooltip
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    messageClass: {
      type: [String, Object],
      default: ''
    }
  }
})
</script>
