export type FormField = { [key: string]: string }

export enum FormType {
  CREATE = 'create',
  EDIT = 'edit'
}

export interface FormSelectOption<T = string | number> {
  key: T
  name: string
  disabled?: boolean
}

export interface FormSelectOptionWithId<T = string | number> {
  id: T
  name: string
  disabled?: boolean
}

export type SubmitAction = 'create' | 'update' | 'delete'
