import useVuelidate, { ErrorObject, Validation, ValidationArgs } from '@vuelidate/core'
import { AxiosResponse } from 'axios'
import { computed, ComputedRef, reactive, Ref, ref, unref, watch } from 'vue'

import { FormType } from '@/types/form'

import { alwaysValid } from '@/plugins/validation'

import { TargetingProperty } from '@/components/Targetings/targetings'

import { extractDirtyFields, touchInvalidFields } from './dirtyFields'
import { FormRenderer } from './renderer/index'
import { cleanExternalErrors, FieldErrors, handleServerErrors, ServerErrors } from './serverErrors'

export type ColumnGrid = 1|2|3|4|5|6|7|8|9|10|11|12

export interface FieldTemplate <T> {
  key?: string // used to bind the validation error
  columnGrid?: ColumnGrid
  wrapperClass?: string
  wrapperKey?: string
  fieldClass?: string
  placement?: 'sectionHeader'
  renderer?: FormRenderer<unknown>
  fields?: Array<FieldTemplate<T>>
  isSlotComponent?: boolean
  receiveFullErrorObject?: boolean // Receive the full error object instead of the error path

  condition?: (...args: any) => boolean // will be applied to the v-if="" attribute in the rendered component
  store?: () => Promise<any>
  transformValue?: (v: any) => any
 }

export interface FormTemplate <T, K> {
  defaultForm: ComputedRef<T>,
  formType?: FormType,
  fieldPrefix?: string,

  rules: (form: T) => ComputedRef<ValidationArgs<Partial<T>>>,
  submitHandler: (dirtyForm: T, form: T) => AxiosResponse<any> | Promise<any>,
  onSuccess?: (response: AxiosResponse<K>) => void | Promise<void>,
  onError?: (error: any, serverErrors?: ServerErrors) => void,
  onBeforeHandleServerErrors?: <T>(errors: T) => T
}

export interface FormState {
  isOkToSubmit: boolean
  isSubmitting: boolean
  error: string
}

export function useForm <T extends object, K = any> (template: FormTemplate<T, K>): ({
  form: T
  errors: ComputedRef<ErrorObject[]>
  state: Ref<FormState>
  validation: Ref<Validation<ValidationArgs<Partial<T>>, T>>
  [k: string]: any
}) {
  const formType = template.formType || FormType.EDIT

  const form = reactive(template.defaultForm.value) as T
  const initialForm = ref<T>({ ...template.defaultForm.value })

  // External Results from the server
  const externalResults = ref<Record<string, FieldErrors>>(reactive({}))

  const validation = useVuelidate(template.rules(form), form, { $autoDirty: true, $externalResults: externalResults })

  const isOkToSubmit = computed((): boolean => {
    if (formType === FormType.CREATE || validation.value.$anyDirty) {
      return !validation.value.$invalid
    }

    return false
  })

  const isSubmitting = ref(false)
  const error = ref('')

  const state = computed(():FormState => ({
    isOkToSubmit: isOkToSubmit.value,
    isSubmitting: isSubmitting.value,
    error: error.value
  }))

  watch(
    () => externalResults.value,
    () => {
      Object.entries(externalResults.value).forEach(e => {
        if (cleanExternalErrors(e[1])) {
          delete externalResults.value[e[0]]
        }
      })
      if (Object.keys(externalResults.value).length === 0) {
        validation.value.$clearExternalResults()
      }
    },
    { deep: true, flush: 'post' }
  )

  watch(
    template.defaultForm,
    () => {
      Object.assign(form, template.defaultForm.value)
      // template.defaultForm is updated once, after the resource has been fetch (edit form)
      // We can safely update the "initial" version here.
      initialForm.value = { ...form }
      validation.value.$reset() // Reset the dirty state
    }
  )

  const errors = computed(() => validation.value.$errors)

  const reset = (): void => {
    if (initialForm.value) {
      Object.assign(form, initialForm.value)
    }
    validation.value.$reset()
  }

  // Submit the form
  const onSubmit = async ():Promise<void | undefined> => {
    const payload = ref<T>() // the content of the form to send

    // if the formType is "Create", we need to validate the whole form before it can be submit. This
    // ensure a user has not skipped a field which will not be set as $dirty.
    // On the other side, during an update, we don't want this behavior to avoid to send all fields
    // (these which are not dirty) in the payload.
    if (formType === 'create') {
      const isValid = await validation.value.$validate()
      payload.value = form

      if (!isValid && template.onError) {
        template.onError('')
      }
    } else {
      touchInvalidFields(form, validation)
      payload.value = extractDirtyFields<T>(form, validation)
    }

    if (validation.value.$errors.length || validation.value.$silentErrors.length) {
      return
    }

    isSubmitting.value = true

    try {
      const response = await template.submitHandler(payload.value, form)

      error.value = ''

      if (template.onSuccess && response) {
        await template.onSuccess(response)
      }
    } catch (err: any) {
      if (!err.response) {
        console.error(err)
      }
      if (err.response?.status === 429) {
        return
      }
      const serverErrors = handleServerErrors(err, template.onBeforeHandleServerErrors)

      if (serverErrors.message) {
        error.value = serverErrors.message
      }

      if (serverErrors.fieldsErrors) {
        externalResults.value = reactive((template.fieldPrefix ? serverErrors.fieldsErrors[template.fieldPrefix].$fields as Record<string, FieldErrors> : serverErrors.fieldsErrors) || {})

        // Set fields as dirty in case of validationError.
        touchExternalResultKeys(validation.value, externalResults.value)
      }

      if (template.onError) {
        template.onError(err, serverErrors)
      }
    } finally {
      isSubmitting.value = false
    }
  }

  return {
    form,
    state,
    onSubmit,
    validation,
    errors,
    reset,
    externalResults
  }
}

export function touchExternalResultKeys (validation: Record<string, any>, errs: Record<string, FieldErrors>): void {
  Object.entries(errs).forEach(e => {
    if (!validation[e[0]]) {
      console.error(`form validation: field "${e[0]}" was not found in the validation rules. Add "${e[0]}: {}" to your rule set to fix this error.`)
      return
    }
    if (e[1].$fields) {
      touchExternalResultKeys(validation[e[0]], e[1].$fields)
    }
    if (e[1].$errors || e[1].$elements) {
      validation[e[0]].$touch()
    }
  })
}

export function useVuelidateError (errs: ErrorObject[]): FieldErrors | undefined {
  if (!errs.length) {
    return undefined
  }

  const message = unref(errs[0].$message)
  if (typeof message === 'string') {
    return { $errors: [message] }
  }

  return message as FieldErrors
}

// targetingRules generates an empty set of rules for targetings. This allows vuelidate to map
// the targeting form fields to the model values.
export function targetingRules (targetings: TargetingProperty[]): Record<string, any> {
  const rules: Record<string, any> = { alwaysValid }

  targetings.forEach(t => {
    rules[t] = {
      alwaysValid,
      action: { alwaysValid },
      metas: { alwaysValid },
      values: { alwaysValid }
    }
  })

  return rules
}
