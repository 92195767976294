import {
  CheckCircleIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  StopCircleIcon,
  WrenchIcon
} from '@heroicons/vue/20/solid'
import { defineStore } from 'pinia'
import { Component, Ref, computed } from 'vue'

import { useContextNotificationsStore } from '@/store/contextNotifications.store'

import { BadgeTheme } from '@/components/Badge/theme'
import ActionRequired from '@/components/Icons/ActionRequired.vue'

export interface Counter {
  count: number | Ref<number>
  type: 'warning' | 'danger' | 'info' | 'default' | 'success'
  icon?: Component
}

export const getCounterBadgeTheme = (counterType: Counter['type']): BadgeTheme => {
  switch (counterType) {
    case 'danger':
      return BadgeTheme.DANGER
    case 'warning':
      return BadgeTheme.WARNING
    case 'info':
      return BadgeTheme.INFO
    case 'success':
      return BadgeTheme.SUCCESS
    default:
      return BadgeTheme.BASE
  }
}

export const useCounterStore = defineStore('counter', () => {
  const contextNotificationsStore = useContextNotificationsStore()

  const counters = computed<Record<string, Counter>>(() => {
    return {
      websiteErrorCount: {
        count: contextNotificationsStore.publisherWebsiteErrors,
        type: 'danger',
        icon: ExclamationCircleIcon
      },
      websiteAdstxtErrorCount: {
        count: contextNotificationsStore.publisherWebsiteAdstxtErrorCount,
        type: 'danger',
        icon: DocumentIcon
      },
      websiteSellersjsonErrorCount: {
        count: contextNotificationsStore.publisherWebsiteSellersjsonErrorCount,
        type: 'danger',
        icon: DocumentIcon
      },
      websiteInfoCount: {
        count: contextNotificationsStore.publisherWebsiteInfoCount,
        type: 'info',
        icon: InformationCircleIcon
      },
      websiteWarningCount: {
        count: contextNotificationsStore.publisherWebsiteWarnings,
        type: 'warning',
        icon: ExclamationTriangleIcon
      },
      websiteAdstxt11IssuesCount: {
        count: contextNotificationsStore.publisherWebsiteAdstxt11IssuesCount,
        type: 'info',
        icon: WrenchIcon
      },
      seatsWaitingCount: {
        count: contextNotificationsStore.publisherSeatsWaiting,
        type: 'warning',
        icon: ActionRequired
      },
      seatsDisconnectedCount: {
        count: contextNotificationsStore.publisherSeatsDisconnected,
        type: 'default',
        icon: NoSymbolIcon
      },
      seatsConnectedCount: {
        count: contextNotificationsStore.publisherSeatsConnected,
        type: 'success',
        icon: CheckCircleIcon
      },
      whitelistsPendingCount: {
        count: contextNotificationsStore.publisherWhitelistsPendingCount,
        type: 'warning',
        icon: ActionRequired
      },
      whitelistsUnwhitelistedCount: {
        count: contextNotificationsStore.publisherWhitelistsUnwhitelistedCount,
        type: 'danger',
        icon: StopCircleIcon
      },
      // -- Bidder Context related counters
      bidderWhitelistActionRequiredCount: {
        count: contextNotificationsStore.bidderWhitelistActionRequiredCount,
        type: 'warning',
        icon: ActionRequired
      },
      bidderWhitelistWhitelistedCount: {
        count: contextNotificationsStore.bidderWhitelistWhitelistedCount,
        type: 'success',
        icon: CheckCircleIcon
      },
      bidderWhitelistBlockedCount: {
        count: contextNotificationsStore.bidderWhitelistBlockedCount,
        type: 'default',
        icon: NoSymbolIcon
      }
    }
  })

  return {
    counters
  }
})
