import { addDays, addHours, addMonths, addQuarters, addWeeks, startOfDay, startOfHour, startOfMonth, startOfQuarter, startOfWeek } from 'date-fns' // TODO this may cause issues

import { dateOffsetToUTC, toUTC } from '@/utils/dates'

import { Period, Granularity } from '.'

/**
 * Generic time granularity implementation. See `TIME_GRANULARITIES`.
 */
export class TimeGranularity implements Granularity<Date> {
  startOf: Period
  addFunc: (value: Date, amount: number) => Date
  timeFormat: string
  offset: boolean

  constructor (startOf: Period, addFunc: (value: Date, amount: number) => Date, timeFormat: string, offset: boolean = true) {
    this.startOf = startOf
    this.addFunc = addFunc
    this.timeFormat = timeFormat
    this.offset = offset
  }

  rollup (value: Date): Date {
    const date = this.startOf(value, { weekStartsOn: 1 })
    return this.offset ? dateOffsetToUTC(date) : date
  }

  add (value: Date, amount: number): Date {
    return this.addFunc(value, amount)
  }

  format (value: Date): string {
    return toUTC(value, this.timeFormat)
  }
}

export const TIME_GRANULARITIES: Record<string, TimeGranularity> = {
  day: new TimeGranularity(startOfDay, addDays, 'yyyy-MM-dd'),
  week: new TimeGranularity(startOfWeek, addWeeks, 'yyyy-MM-dd'),
  hour: new TimeGranularity(startOfHour, addHours, 'yyyy-MM-dd Ka', false),
  // minute: new TimeGranularity(startOfMinute, addMinutes, 'yyyy-MM-dd HH:mm'),
  month: new TimeGranularity(startOfMonth, addMonths, 'yyyy-MM'),
  // second: new TimeGranularity(startOfSecond, addSeconds, 'yyyy-MM-dd'),
  quarter: new TimeGranularity(startOfQuarter, addQuarters, 'QQQ yyyy')
}

export type TimeGranularityName = 'day' | 'week' | 'month' | 'quarter' | 'hour'
