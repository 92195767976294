import { createI18n } from 'vue-i18n'

import enUS from '@/locales/en-US.json'

const defaultLocale = 'en-US'

function userLocale (): string {
  let locale = defaultLocale

  if (typeof window !== 'undefined') {
    const settings = localStorage.getItem('settings')
    if (settings) {
      const s = JSON.parse(settings)
      if (s.locale) {
        locale = s.locale
      }
    }
  }

  return locale
}

const i18n = createI18n<false>({
  legacy: false,
  locale: userLocale(),
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS
  },
  datetimeFormats: {
    'fr-FR': {
      short: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timezone: 'Europe/Paris'
      }
    }
  },
  numberFormats: {
    'fr-FR': {
      currency: {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'EUR'
      }
    }
  }
})

export default i18n
