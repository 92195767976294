<template>
  <div
    :class="wrapperClass"
    class="relative"
  >
    <Tooltip
      :disabled="(fieldErrors?.$errors?.length || 0) <= 0"
      :closeable="true"
      :force-show="true"
    >
      <label
        v-if="label"
        :for="idValue"
        class="flex w-full text-xs font-semibold text-text-primary"
        :aria-label="label"
      >
        <span
          v-if="label"
          :class="[
            mode === 'materialize' ? 'hidden' : 'mb-2',
            $attrs.required || isRequired ? `after:content-['*']` : ''
          ]"
        >
          {{ label }}
        </span>
        <span
          v-if="hints.textTop"
          class="ml-auto mb-2 text-xs font-normal text-slate-400"
        > {{ hints.textTop }}</span>
      </label>
      <Popover
        v-slot="{ open }"
        class="relative"
      >
        <PopoverButton
          as="div"
          :class="[
            inputClasses,
            mode === 'materialize'
              ? 'peer order-2 transition-all focus:pt-[1.375rem] focus:pb-1 autofill:pt-[1.375rem] autofill:pb-1'
              : '',
            open && !isDisabled ? '!border-primary-500': ''
          ]"
        >
          <div class="flex items-center">
            <template v-if="selectedPreset !== customOptionId">
              <span class="font-semibold">{{ DATE_RANGE_PRESETS_OPTIONS[selectedPreset].label }}</span>
            </template>
            <template v-else>
              <span class="font-semibold">{{ format(range.start, 'yyyy-MM-dd') }}</span>

              <template v-if="startOfDay(range.start).getTime() !== startOfDay(range.end).getTime()">
                <span class="align-text-bottom">
                  <ArrowLongRightIcon
                    class="w-4 h-4 inline-block mx-1"
                    aria-hidden="true"
                  />
                </span>

                <span class="font-semibold">{{ format(range.end, 'yyyy-MM-dd') }}</span>
              </template>
            </template>

            <CalendarIcon
              v-if="withLeftIcon"
              class="absolute top-1/2 -translate-y-1/2 left-3 h-5 w-5"
              aria-hidden="true"
            />

            <ChevronDownIcon
              class="absolute top-1/2 -translate-y-1/2 right-2 w-4 h-4 text-slate-400"
              aria-hidden="true"
            />
          </div>
        </PopoverButton>

        <transition
          v-if="!isDisabled"
          enter-active-class="transition duration-150 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel
            class="absolute z-20 mt-2
        w-full sm:max-w-[440px]
        overflow-hidden
        select-none text-text-primary
        bg-white border border-gray-border rounded-md shadow-lg"
          >
            <div
              class="flex
            flex-col items-center
            sm:flex-row sm:items-start sm:space-x-4
            w-full
            max-w-full overflow-visible"
            >
              <DatePicker
                v-model="range"
                mode="date"
                is-range
                :max-date="maxDragDate"
                color="indigo"
              />
              <div class="w-full bg-white">
                <div
                  class="w-full
              text-center sm:text-left
              font-semibold
              py-2 px-3 sm:pt-3.5
              border-t sm:border-0"
                >
                  {{ t('labels.period') }}
                </div>
                <div class="flex flex-col max-h-60 overflow-auto">
                  <input
                    v-model="selectedPreset"
                    class="hidden"
                  >
                  <div
                    v-for="option in DATE_RANGE_PRESETS_OPTIONS"
                    :key="option.id"
                    :class="selectedPreset === option.id ? '!text-primary-500 font-semibold' : 'text-text-primary'"
                    class="py-2 px-3 cursor-pointer hover:bg-gray-200
                    text-center sm:text-left
                    text-base leading-snug"
                    @click.stop="selectedPreset = option.id"
                  >
                    {{ option.label }}
                  </div>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
      <span
        v-if="mode=== 'materialize'"
        class="order-1 absolute top-[50%] -mt-[11px] left-[1px] pointer-events-none transition-all block pl-5 pr-2.5 text-base leading-5.5 text-slate-500 font-semibold border-none
                peer-focus:text-xs peer-focus:top-[6px] peer-focus:mt-0 peer-focus:leading-4.5
                peer-autofill:text-xs peer-autofill:top-[6px] peer-autofill:mt-0 peer-autofill:leading-4.5
                w-full truncate"
        :class="[
          (range.start !== null) ? '!text-xs !top-[6px] !mt-0 !leading-4.5' : '',
          $attrs.required || isRequired ? `after:content-['*']` : '',
          withLeftIcon ? '!pl-10' : ''
        ]"
      >
        {{ label }}
      </span>
      <template
        v-if="(fieldErrors?.$errors?.length || 0) > 0"
        #title
      >
        <div class="flex flex-col gap-0.5">
          <p
            v-for="(err, i) in fieldErrors!.$errors"
            :key="i"
            class="text-xs font-normal inline-block"
          >
            {{ err }}
          </p>
        </div>
      </template>
    </Tooltip>
    <Tooltip
      v-if="hints.tooltip"
      :reference-props="{
        class: 'absolute right-6 ' + (mode === 'materialize' ? 'top-4' : 'top-0')
      }"
    >
      <InformationCircleIcon class="h-5 w-5 text-primary-400" />
      <template #content>
        <p class="text-sm">
          {{ hints.tooltip }}
        </p>
      </template>
    </Tooltip>
    <FormHelpText
      v-if="hints.textBottom"
      :help-text="hints.textBottom"
    />
  </div>
</template>

<script lang="ts">

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { CalendarIcon, ArrowLongRightIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { ErrorObject } from '@vuelidate/core'
import { format, startOfDay, endOfToday } from 'date-fns'
import { DatePicker } from 'v-calendar'
import { defineComponent, ref, computed, PropType, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidateError } from '@/plugins/form'

import { DATE_RANGE_PRESETS_OPTIONS, customOptionId, equalDateRanges } from '@/utils/dateRange'
import { randomString } from '@/utils/utils'

import { multiselectTailwindClasses } from '@/components/Multiselect'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

import FormHelpText from './FormHelpText.vue'

import { FormInputHints, FormInputMode } from '.'

// TODO validation error support is incomplete.
// This is only used in InstantReport form, which transforms the date range object to dateFrom and dateTo
// This view also doesn't use validation

export default defineComponent({
  components: {
    CalendarIcon,
    ArrowLongRightIcon,
    ChevronDownIcon,
    InformationCircleIcon,
    DatePicker,
    Popover,
    PopoverButton,
    PopoverPanel,
    Tooltip,
    FormHelpText
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object as PropType<{start: Date, end: Date}>,
      required: true
    },
    withLeftIcon: {
      type: Boolean,
      required: false,
      default: () => true
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String as PropType<FormInputMode>,
      required: false,
      default: 'materialize'
    },
    hints: {
      type: Object as PropType<FormInputHints>,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup (props, { attrs, emit }) {
    const { t } = useI18n()

    // Calendar
    const range = computed<{start: Date, end: Date}>({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const idValue = `${props.name}-${randomString(12)}`

    const maxDragDate = ref(endOfToday() as Date | undefined)

    const multiselectClasses = computed(() => {
      const classes = Object.assign({}, multiselectTailwindClasses)
      classes.option = classes.option.replaceAll('py-2', 'py-0.5')
      classes.container += ' sm:hidden md:flex lg:hidden'
      return classes
    })

    const inputClasses = computed(() => {
      const css = [
        'relative', 'whitespace-nowrap', 'truncate',
        'block', 'w-full',
        'outline-none', 'ring-0'
      ]

      if (props.isDisabled || attrs.readonly) {
        css.push('bg-gray-200')
      } else {
        css.push('cursor-pointer')
      }

      switch (props.mode) {
        case 'default':
          css.push('rounded-lg', 'pl-5', 'pr-2.5', 'py-3',
            'font-normal', 'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          break
        case 'materialize':
          css.push('rounded-lg', 'pl-5', 'pr-2.5',
            'text-base', 'leading-[1.375rem]', 'text-text-primary',
            'border', 'border-gray-border')
          css.push('h-12.125')

          if (range.value.start) {
            css.push('pt-[1.375rem]', 'pb-1', 'font-semibold')
          }

          if (props.withLeftIcon) {
            css.push('!pl-10')
          }
          break
      }

      if (fieldErrors.value?.$errors?.length) {
        css.push('!border-red-400')
      } else {
        css.push('')
      }

      return css.join(' ')
    })

    // Preset
    const selectedPreset = ref(customOptionId)

    const updateDateRangeSelected = () => {
      if (selectedPreset.value !== customOptionId) {
        range.value = DATE_RANGE_PRESETS_OPTIONS[selectedPreset.value].value()
      }
    }

    const updateSelectedPreset = () => {
      for (const key of Object.keys(DATE_RANGE_PRESETS_OPTIONS)) {
        const date = DATE_RANGE_PRESETS_OPTIONS[key].value()
        if (key === customOptionId || equalDateRanges(date, range.value)) {
          selectedPreset.value = key
          break
        }
      }
    }

    // Watch
    watch(
      () => props.modelValue,
      () => {
        updateSelectedPreset()
      }
    )

    watch(
      () => selectedPreset.value,
      () => {
        updateDateRangeSelected()
      }
    )

    const fieldErrors = computed(() => useVuelidateError(props.errors))

    return {
      // Data
      range,
      maxDragDate,
      idValue,
      fieldErrors,

      DATE_RANGE_PRESETS_OPTIONS,
      customOptionId,
      selectedPreset,
      updateDateRangeSelected,

      // Misc
      t,
      format,
      startOfDay,
      multiselectClasses,
      inputClasses
    }
  }
})
</script>
<style scoped>
@tailwind base;

.vc-container {
  @apply border-0 mx-0;
}
</style>
