export default {
  rubicon: {
    siteid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'siteid'
    },
    zoneid: {
      bidderHumanName: 'Zone ID',
      bidderTagName: 'zoneid',
      key: 'zoneid'
    }
  },
  drbanner: {
    tagid: {
      bidderHumanName: 'Tag ID',
      bidderTagName: 'tagid',
      key: 'tagid'
    }
  },
  pubmatic: {
    tagid: {
      bidderHumanName: 'Ad Slot Name or Ad Tag ID',
      bidderTagName: 'adtagid',
      key: 'tagid'
    },
    siteid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'siteid'
    }
  },
  improvedigital: {
    siteid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'siteid'
    },
    placementid: {
      bidderHumanName: 'Placement ID',
      bidderTagName: 'placementid',
      key: 'placementid'
    }
  },
  onetag: {
    tagid: {
      bidderHumanName: 'Placement Name',
      bidderTagName: 'placementname',
      key: 'tagid'
    }
  },
  indexexchange: {
    tagid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'tagid'
    }
  },
  richaudience: {
    tagid: {
      bidderHumanName: 'Placement ID',
      bidderTagName: 'placementid',
      key: 'tagid'
    }
  },
  '33across': {
    tagid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'tagid'
    },
    productid: {
      bidderHumanName: 'Product ID',
      bidderTagName: 'productid',
      key: 'productid'
    }
  },
  appnexus: {
    tagid: {
      bidderHumanName: 'Placement ID',
      bidderTagName: 'placementid',
      key: 'tagid'
    },
    memberid: {
      bidderHumanName: 'Member ID',
      bidderTagName: 'memberid',
      key: 'memberid'
    },
    invcode: {
      bidderHumanName: 'Inventory Code',
      bidderTagName: 'invcode',
      key: 'invcode'
    }
  },
  smart: {
    tagid: {
      bidderHumanName: 'Tag ID',
      bidderTagName: 'tagid',
      key: 'tagid'
    },
    siteid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'siteid'
    },
    pageid: {
      bidderHumanName: 'Page ID',
      bidderTagName: 'pageid',
      key: 'pageid'
    },
    formatid: {
      bidderHumanName: 'Format ID',
      bidderTagName: 'formatid',
      key: 'formatid'
    }
  },
  sovrn: {
    tagid: {
      bidderHumanName: 'Ad Tag ID',
      bidderTagName: 'adtagid',
      key: 'tagid'
    }
  },
  yahoo: {
    siteid: {
      bidderHumanName: 'Site ID',
      bidderTagName: 'siteid',
      key: 'siteid'
    },
    placementid: {
      bidderHumanName: 'Placement ID',
      bidderTagName: 'placementid',
      key: 'placementid'
    }
  },
  adyoulike: {
    tagid: {
      bidderHumanName: 'Placement ID',
      bidderTagName: 'tagid',
      key: 'tagid'
    }
  },
  freewheel: {
    zoneid: {
      bidderHumanName: 'Zone ID',
      bidderTagName: 'zoneid',
      key: 'zoneid'
    }
  },
  openx: {
    adunitid: {
      bidderHumanName: 'Ad Unit ID',
      bidderTagName: 'adunitid',
      key: 'adunitid'
    }
  },
  triplelift: {
    inventorycode: {
      bidderHumanName: 'Inventory Code',
      bidderTagName: 'inventorycode',
      key: 'inventorycode'
    }
  },
  unruly: {
    tagid: {
      bidderHumanName: 'Tag ID',
      bidderTagName: 'tagid',
      key: 'tagid'
    }
  },
  eplanning: {
    tagid: {
      bidderHumanName: 'Tag ID',
      bidderTagName: 'tagid',
      key: 'tagid'
    }
  }
} as Record<string, Record<string, {
  bidderHumanName: string
  bidderTagName: string
  key: string
}>>
