import { ref, Ref, computed, watch, nextTick, ComputedRef } from 'vue'

interface Cluster {
  height: ComputedRef<number>
  translation: ComputedRef<string>
  cluster: ComputedRef<any[]>
  onScroll: (e: Event) => void
}

/**
 * Composable for better performance when rendering huge scrollable lists.
 * @param containerRef a ref to the container element. This element will be automatically resized (height). Its parent should be scrollable.
 * @param entries a ref to an array of entries
 */
export function clusterize (containerRef: Ref<HTMLElement | null>, entries: Ref<any[]>): Cluster {
  const scrollTop = ref(0)
  const clientHeight = ref(0)

  const rowHeight = computed(() => {
    if (containerRef.value === null) {
      return Infinity
    }
    return containerRef.value.children[0].clientHeight
  })

  const height = computed(() => {
    return rowHeight.value * (entries.value?.length || 0)
  })

  const start = computed(() => {
    return 2 * Math.floor((scrollTop.value / rowHeight.value) / 2)
  })

  const translation = computed(() => {
    return `translateY(${start.value * rowHeight.value}px)`
  })

  const cluster = computed(() => {
    return entries.value?.slice(start.value, start.value + Math.ceil(clientHeight.value / rowHeight.value) + 1)
  })

  watch(
    () => entries.value.length,
    () => {
      nextTick(() => {
        scrollTop.value = containerRef.value?.parentElement?.scrollTop || 0
        clientHeight.value = containerRef.value?.parentElement?.clientHeight || 0
      })
    }
  )

  watch(
    () => containerRef.value,
    () => {
      nextTick(() => {
        clientHeight.value = containerRef.value?.parentElement?.clientHeight || 0
        scrollTop.value = 0
      })
    }
  )

  watch(
    () => height.value,
    () => {
      if (containerRef.value !== null) {
        containerRef.value.style.height = `${height.value}px`
      }
    }
  )

  const onScroll = (e: Event): void => {
    const target = e.target! as Element
    scrollTop.value = target.scrollTop
    clientHeight.value = target.clientHeight
  }

  return {
    height,
    translation,
    cluster,
    onScroll
  }
}
