<template>
  <Teleport to="#header-slot">
    <DateRangePicker
      :model-value="dateRange"
      start-prop="from"
      end-prop="to"
      :max-date="endOfToday()"
      :max-days-range="maxDaysRange"
      :time="granularity === 'hour'"
      :no-comparison="noComparison"
      timezone="UTC"
      button-class="text-sm !min-h-7 md:!h-7 bg-white"
      reference-class="inline-block my-2 align-middle grow"
      @update:model-value="onDateRangeInput"
    />
    <button
      v-if="editable"
      type="button"
      class="inline-flex ml-1 align-middle bg-white border border-gray-300 rounded cursor-pointer h-7 w-7"
      :class="{'!ring-primary-500 !border-primary-500': !formCollapsed}"
      :title="t('dashboards.editorTitle')"
      @click="$emit('update:formCollapsed', !formCollapsed)"
    >
      <PencilSquareIcon class="w-4 h-4 m-auto" />
    </button>
  </Teleport>
  <Teleport to="#header">
    <Popper
      ref="popper"
      :transition-props="{
        'enterActiveClass': 'transition-opacity duration-150 ease-out',
        'enterFromClass': 'opacity-0',
        'enterToClass': 'opacity-100',
        'leaveActiveClass': 'transition-opacity duration-150 ease-in',
        'leaveFromClass': 'opacity-100',
        'leaveToClass': 'opacity-0'
      }"
      trigger="click-to-toggle"
      placement="bottom-start"
      :popper-props="{
        'class': 'p-4 rounded bg-white shadow-lg border-gray-100 ring-1 ring-black ring-opacity-5 max-w-full'
      }"
      :teleport-props="{ to: 'body' }"
      :reference-props="{
        'class': 'flex flex-row flex-wrap gap-1 bg-white border border-gray-300 rounded p-2 text-sm select-none cursor-pointer'
      }"
    >
      <template #reference>
        <AdjustmentsVerticalIcon
          class="w-5 h-5 text-blue-800"
          aria-hidden="true"
        />
        <span v-if="!hasFilters">
          {{ t('actions.addFilter', 2) }}
        </span>
        <div
          v-for="label in inputLabels"
          :key="label.dimension + label.val"
          :title="label.val"
          class="px-1 text-gray-900 bg-amber-300 rounded whitespace-nowrap flex flex-row items-center gap-0.5 overflow-hidden max-w-36"
          :class="{
            'line-through bg-gray-400': modelValue.filters[label.dimension]?.operator === 'notEquals'
          }"
        >
          <span class="overflow-hidden text-ellipsis">
            {{ label.val }}
          </span>
          <XMarkIcon
            class="inline-block w-3 h-3 cursor-pointer shrink-0"
            @click.stop="onRemoveClicked(label.dimension, label.index)"
          />
        </div>
        <span
          v-if="labelCountOverflow > 0"
          class="text-gray-600"
        >
          {{ t('dashboards.andMore', [labelCountOverflow]) }}
        </span>
        <span
          v-if="hasFilters"
          class="text-blue-500 cursor-pointer"
          @click.stop="clearAll()"
        >
          {{ t('actions.clearAll') }}
        </span>
      </template>

      <template #default="{ setVisible }">
        <div class="flex flex-col max-w-full gap-2 w-96">
          <div class="flex flex-row justify-between">
            <span class="my-1 text-base font-medium">
              {{ t('labels.filter', 2) }}
            </span>
            <button
              type="button"
              class="inline-block p-1 ml-2 align-middle rounded-full cursor-pointer hover:bg-gray-200"
              :title="t('actions.close')"
              @click="setVisible(false)"
            >
              <XMarkIcon class="w-6 h-6" />
            </button>
          </div>
          <Collapse
            v-for="dim in sortedDimensions"
            :key="dim.name"
            class="p-2 text-sm bg-gray-200"
          >
            <template #header>
              <div class="flex flex-row items-center justify-between">
                <p>
                  <span class="mr-1.5">{{ dim.label }}</span>
                  <span
                    class="px-1.5 py-0.5 rounded-sm font-mono lining-nums"
                    :class="{
                      'bg-blue-200 text-blue-700': (modelValue.filters[dim.name]?.values.length || 0) === 0,
                      'bg-red-200 text-red-600': (modelValue.filters[dim.name]?.values.length || 0) > 0
                    }"
                  >
                    {{ modelValue.filters[dim.key.name]?.values.length || 0 }}
                  </span>
                </p>
                <FormToggle
                  v-if="modelValue.filters[dim.key.name]"
                  :name="dim.key.name + '_operator'"
                  :label="modelValue.filters[dim.key.name].operator === 'equals' ? t('filters.operators.in') : t('filters.operators.notIn')"
                  :checked="modelValue.filters[dim.key.name].operator === 'equals'"
                  label-position="left"
                  @click.stop
                  @update:model-value="onOperatorToggle(dim.key.name, $event)"
                />
              </div>
            </template>

            <Multiselect
              class="flex-grow min-w-48"
              popover-panel-class="max-w-full"
              button-class="flex-wrap gap-1 p-1 pr-3 mt-1 text-sm leading-4 bg-white border border-gray-400"
              :compact-button="true"
              :values="dim.values"
              :value="modelValue.filters[dim.name]?.values || []"
              :can-clear="false"
              @input="onMultiselectInput(dim.name, $event)"
            >
              <template #label>
                <div
                  v-for="(val, i) in modelValue.filters[dim.name]?.values.slice(0, maxLabels)"
                  :key="val"
                  :title="val"
                  class="px-1 text-gray-900 bg-amber-300 rounded whitespace-nowrap flex flex-row items-center gap-0.5 overflow-hidden max-w-36"
                  :class="{
                    'line-through bg-gray-400': modelValue.filters[dim.name]?.operator === 'notEquals'
                  }"
                >
                  <span class="overflow-hidden text-ellipsis">
                    {{ val }}
                  </span>
                  <XMarkIcon
                    class="inline-block w-3 h-3 cursor-pointer shrink-0"
                    @click.stop="onRemoveClicked(dim.name, i)"
                  />
                </div>
                <span
                  v-if="modelValue.filters[dim.name]?.values.length > maxLabels"
                  class="text-gray-600"
                >
                  {{ t('dashboards.andMore', [modelValue.filters[dim.name].values.length - maxLabels]) }}
                </span>
                <span
                  v-if="(modelValue.filters[dim.name]?.values.length || 0) === 0"
                  class="text-gray-600"
                >
                  {{ t('actions.addFilter', 2) }}
                </span>
                <button
                  v-if="(modelValue.filters[dim.name]?.values.length || 0) > 0"
                  :title="t('actions.clearAll')"
                  :aria-label="t('actions.clearAll')"
                  class="absolute z-10 inline-block cursor-pointer right-1"
                  @click.stop="onClearClicked(dim.name)"
                >
                  <XMarkIcon
                    class="w-3 h-3"
                    aria-hidden="true"
                  />
                </button>
              </template>
            </Multiselect>
          </Collapse>
        </div>
      </template>
    </Popper>
  </Teleport>
</template>

<script lang="ts">
import { AdjustmentsVerticalIcon, XMarkIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'
import { endOfToday } from 'date-fns'
import sortBy from 'lodash/sortBy'
import { defineComponent, PropType, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { MultiselectValue } from '@/plugins/dashboard'
import { TimeGranularityName } from '@/plugins/dashboard/dimensions'
import { DateRange, FilterDimension, Filters, FilterValues } from '@/plugins/dashboard/source'

import Collapse from '@/components/Collapse/Collapse.vue'
import { multiselectTailwindClasses } from '@/components/Multiselect'
import Popper from '@/components/Tooltip/Popper.vue'

import DateRangePicker from '../DateRangePicker/DateRangePicker.vue'
import FormToggle from '../Form/FormToggle.vue'

import Multiselect from './Multiselect.vue'

export default defineComponent({
  components: {
    Popper,
    DateRangePicker,
    Collapse,
    AdjustmentsVerticalIcon,
    XMarkIcon,
    PencilSquareIcon,
    Multiselect,
    FormToggle
  },
  props: {
    modelValue: {
      type: Object as PropType<Filters>,
      required: true
    },
    formCollapsed: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    dimensions: {
      type: Object as PropType<FilterDimension[]>,
      default: () => {}
    },
    maxDaysRange: {
      type: Number,
      default: 62
    },
    maxLabels: {
      type: Number,
      default: 10
    },
    granularity: {
      type: String as PropType<TimeGranularityName>,
      required: true
    },
    noComparison: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'dimension-checked', 'update:formCollapsed'],
  setup (props, { emit }) {
    const { t } = useI18n()

    // Data
    const filters = ref({} as Record<string, FilterValues>)
    const dateRange = ref({
      dateRange: {} as DateRange,
      comparisonDateRange: undefined as DateRange | undefined
    })

    // Computed
    const sortedDimensions = computed(() => {
      return sortBy(props.dimensions
        .filter(d => d.enrichment === undefined || !d.enrichment.oneToOne)
        .map(d => {
          return {
            key: d,
            label: d.label,
            name: d.enrichment !== undefined ? (d.enrichment.oneToOne ? d.enrichment.name : d.name) : d.name,
            values: Object.entries(d.values).map(e => {
              if (d.enrichment !== undefined && !d.enrichment.oneToOne) {
                return { label: e[0], value: e[0] } as MultiselectValue
              }

              return { label: e[0], value: e[1][0] }
            })
          }
        }), [d => d.label.toLowerCase()])
    })

    const inputLabels = computed(() => {
      const labels: Array<{dimension: string, index: number, val: any}> = []
      sortedDimensions.value.forEach(dim => {
        const values = props.modelValue.filters[dim.name]?.values
        if (!values) {
          return
        }
        const maxLabels = labels.length + values.length >= props.maxLabels ? props.maxLabels - labels.length : props.maxLabels
        if (maxLabels > 0) {
          labels.push(...values.slice(0, maxLabels).map((v, i) => ({
            dimension: dim.name,
            index: i,
            val: v
          })))
        }
      })
      return labels
    })

    const labelCountOverflow = computed(() => {
      const count = sortedDimensions.value.reduce((prev, dim) => {
        const values = props.modelValue.filters[dim.name]?.values
        return values ? prev + values.length : prev
      }, 0)
      return count - props.maxLabels
    })

    const hasFilters = computed(() => {
      return Object.keys(props.modelValue.filters).length > 0
    })

    // Methods
    const onMultiselectInput = (dimension: string | number, values: string[]) => {
      const filters = props.modelValue
      const existingValues = filters.filters[dimension]
      if (!existingValues) {
        filters.filters[dimension] = {
          operator: 'equals',
          values
        }
      } else {
        existingValues.values = values
      }
      if (filters.filters[dimension].values.length === 0) {
        delete filters.filters[dimension]
      }
      emit('update:modelValue', filters)
      emit('dimension-checked', dimension as string)
    }

    const onOperatorToggle = (dimension: string, checked: boolean) => {
      const filters = props.modelValue
      const existingValues = filters.filters[dimension]
      existingValues.operator = checked ? 'equals' : 'notEquals'
      emit('update:modelValue', filters)
      emit('dimension-checked', dimension)
    }

    const onDateRangeInput = (newDateRange: {dateRange: any, comparisonDateRange?: any}) => {
      const filters = props.modelValue
      filters.dateRange = newDateRange.dateRange
      filters.comparisonDateRange.from = newDateRange.comparisonDateRange?.from
      filters.comparisonDateRange.to = newDateRange.comparisonDateRange?.to
      emit('update:modelValue', filters)
    }

    const updateDateRange = () => {
      dateRange.value.dateRange = {
        from: props.modelValue.dateRange.from,
        to: props.modelValue.dateRange.to
      }
      if (props.modelValue.comparisonDateRange.from || props.modelValue.comparisonDateRange.to) {
        dateRange.value.comparisonDateRange = {
          from: props.modelValue.comparisonDateRange.from!,
          to: props.modelValue.comparisonDateRange.to!
        }
      } else {
        dateRange.value.comparisonDateRange = undefined
      }
    }

    const clearAll = () => {
      const filters = props.modelValue
      const dimensions = Object.keys(filters.filters)
      dimensions.forEach(dimension => {
        delete filters.filters[dimension]
      })
      emit('update:modelValue', filters)
      emit('dimension-checked', dimensions)
    }

    const onRemoveClicked = (dimension: string, index: number) => {
      const values = [...props.modelValue.filters[dimension].values]
      values.splice(index, 1)
      onMultiselectInput(dimension, values)
    }

    const onClearClicked = (dimension: string) => {
      const filters = props.modelValue
      delete filters.filters[dimension]
      emit('update:modelValue', filters)
      emit('dimension-checked', dimension)
    }

    // Watch
    watch(
      () => props.modelValue,
      () => {
        filters.value = Object.assign({}, props.modelValue.filters)
        updateDateRange()
      },
      { deep: true, immediate: true }
    )

    const multiselectClasses = Object.assign({}, multiselectTailwindClasses)
    multiselectClasses.container += ' w-full'
    multiselectClasses.container = multiselectClasses.container.replace('mt-1', '')

    return {
      // Data
      filters,
      dateRange,

      // Computed
      sortedDimensions,
      inputLabels,
      hasFilters,
      labelCountOverflow,

      // Methods
      onMultiselectInput,
      onDateRangeInput,
      onRemoveClicked,
      onClearClicked,
      onOperatorToggle,
      clearAll,

      // Misc
      multiselectClasses,
      endOfToday,
      t
    }
  }
})
</script>
