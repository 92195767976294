<template>
  <Popper
    ref="popper"
    :transition-props="{
      'enterActiveClass': 'transition-opacity duration-150 ease-out',
      'enterFromClass': 'opacity-0',
      'enterToClass': 'opacity-100',
      'leaveActiveClass': 'transition-opacity duration-150 ease-in',
      'leaveFromClass': 'opacity-100',
      'leaveToClass': 'opacity-0'
    }"
    trigger="click-to-toggle"
    placement="bottom-start"
    :popper-props="{
      'class': 'p-4 rounded bg-white shadow-lg border-gray-100 ring-1 ring-black ring-opacity-5 max-w-full'
    }"
    :teleport-props="{ to: 'body' }"
    :reference-props="{
      'class': 'flex flex-row flex-wrap items-center  gap-1 bg-white rounded text-sm select-none cursor-pointer'
    }"
  >
    <template #reference>
      <div
        v-for="label in inputLabels"
        :key="label.dimension + label.val"
        :title="label.val"
        class="p-1 px-2 text-gray-900 rounded whitespace-nowrap flex flex-row items-center gap-0.5 overflow-hidden max-w-36"
        :class="{
          'line-through bg-gray-400': modelValue[label.dimension]?.operator === 'notEquals',
          [`text-${getComparisonGroupColor(groupName)}-600`]: true,
          [`bg-${getComparisonGroupColor(groupName)}-100`]: true
        }"
      >
        <span class="overflow-hidden text-ellipsis">
          {{ label.val }}
        </span>
        <XMarkIcon
          class="inline-block w-3 h-3 cursor-pointer shrink-0"
          @click.stop="onRemoveClicked(label.dimension, label.index)"
        />
      </div>
      <span
        v-if="labelCountOverflow > 0"
        class="text-gray-600"
      >
        {{ t('dashboards.andMore', [labelCountOverflow]) }}
      </span>

      <div class="flex items-center ml-2 text-gray-600">
        <PlusIcon class="w-4 h-4" />
        <span>
          {{ t('actions.addFilter', 2) }}
        </span>
      </div>
    </template>

    <template #default="{ setVisible }">
      <div class="flex flex-col max-w-full gap-2 w-96">
        <div class="flex flex-row justify-between">
          <span class="my-1 text-base font-medium">
            {{ t('labels.filter', 2) }}
          </span>
          <button
            type="button"
            class="inline-block p-1 ml-2 align-middle rounded-full cursor-pointer hover:bg-gray-200"
            :title="t('actions.close')"
            @click="setVisible(false)"
          >
            <XMarkIcon class="w-6 h-6" />
          </button>
        </div>
        <Collapse
          v-for="dim in sortedDimensions"
          :key="dim.name"
          class="p-2 text-sm bg-gray-200"
        >
          <template #header>
            <div class="flex flex-row items-center justify-between">
              <p>
                <span class="mr-1.5">{{ dim.label }}</span>
                <span
                  class="px-1.5 py-0.5 rounded-sm font-mono lining-nums"
                  :class="{
                    'bg-blue-200 text-blue-700': (modelValue[dim.name]?.values.length || 0) === 0,
                    'bg-red-200 text-red-600': (modelValue[dim.name]?.values.length || 0) > 0
                  }"
                >
                  {{ modelValue[dim.key.name]?.values.length || 0 }}
                </span>
              </p>
              <FormToggle
                v-if="modelValue[dim.key.name]"
                :name="dim.key.name + '_operator'"
                :label="modelValue[dim.key.name].operator === 'equals' ? t('filters.operators.in') : t('filters.operators.notIn')"
                :checked="modelValue[dim.key.name].operator === 'equals'"
                label-position="left"
                @click.stop
                @update:model-value="onOperatorToggle(dim.key.name, $event)"
              />
            </div>
          </template>

          <Multiselect
            class="flex-grow min-w-48"
            popover-panel-class="max-w-full"
            button-class="flex-wrap gap-1 p-1 pr-3 mt-1 text-sm leading-4 bg-white border border-gray-400"
            :compact-button="true"
            :values="dim.values"
            :value="modelValue[dim.name]?.values || []"
            :can-clear="false"
            @input="onMultiselectInput(dim.name, $event)"
          >
            <template #label>
              <div
                v-for="(val, i) in modelValue[dim.name]?.values.slice(0, maxLabels)"
                :key="val"
                :title="val"
                class="px-1 text-gray-900 bg-amber-300 rounded whitespace-nowrap flex flex-row items-center gap-0.5 overflow-hidden max-w-36"
                :class="{
                  'line-through bg-gray-400': modelValue[dim.name]?.operator === 'notEquals'
                }"
              >
                <span class="overflow-hidden text-ellipsis">
                  {{ val }}
                </span>
                <XMarkIcon
                  class="inline-block w-3 h-3 cursor-pointer shrink-0"
                  @click.stop="onRemoveClicked(dim.name, i)"
                />
              </div>
              <span
                v-if="modelValue[dim.name]?.values.length > maxLabels"
                class="text-gray-600"
              >
                {{ t('dashboards.andMore', [modelValue[dim.name].values.length - maxLabels]) }}
              </span>
              <span
                class="text-gray-600"
              >
                {{ t('actions.addFilter', 2) }}
              </span>
            </template>
          </Multiselect>
        </Collapse>
      </div>
    </template>
  </Popper>
</template>

<script lang="ts">
import { XMarkIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { cloneDeep } from 'lodash'
import sortBy from 'lodash/sortBy'
import { defineComponent, PropType, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { MultiselectValue } from '@/plugins/dashboard'
import { DateRange, FilterDimension, FilterValues } from '@/plugins/dashboard/source'

import { getComparisonGroupColor } from '@/models/comparisonGroup'

import Collapse from '@/components/Collapse/Collapse.vue'
import { multiselectTailwindClasses } from '@/components/Multiselect'
import Popper from '@/components/Tooltip/Popper.vue'

import FormToggle from '../Form/FormToggle.vue'

import Multiselect from './Multiselect.vue'

export default defineComponent({
  components: {
    Popper,
    Collapse,
    XMarkIcon,
    PlusIcon,
    Multiselect,
    FormToggle
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<string, FilterValues>>,
      required: true
    },
    groupName: {
      type: String,
      default: ''
    },
    dimensions: {
      type: Object as PropType<FilterDimension[]>,
      default: () => {}
    },
    maxLabels: {
      type: Number,
      default: 10
    }
  },
  emits: ['update:modelValue', 'dimension-checked'],
  setup (props, { emit }) {
    const { t } = useI18n()

    // Data
    const filters = ref({} as Record<string, FilterValues>)
    const dateRange = ref({
      dateRange: {} as DateRange,
      comparisonDateRange: undefined as DateRange | undefined
    })

    // Computed
    const sortedDimensions = computed(() => {
      return sortBy(props.dimensions
        .filter(d => d.enrichment === undefined || !d.enrichment.oneToOne)
        .map(d => {
          return {
            key: d,
            label: d.label,
            name: d.enrichment !== undefined ? (d.enrichment.oneToOne ? d.enrichment.name : d.name) : d.name,
            values: Object.entries(d.values).map(e => {
              if (d.enrichment !== undefined && !d.enrichment.oneToOne) {
                return { label: e[0], value: e[0] } as MultiselectValue
              }

              return { label: e[0], value: e[1][0] }
            })
          }
        }), [d => d.label.toLowerCase()])
    })

    const inputLabels = computed(() => {
      const labels: Array<{dimension: string, index: number, val: any}> = []
      sortedDimensions.value.forEach(dim => {
        const values = props.modelValue[dim.name]?.values
        if (!values) {
          return
        }
        const maxLabels = labels.length + values.length >= props.maxLabels ? props.maxLabels - labels.length : props.maxLabels
        if (maxLabels > 0) {
          labels.push(...values.slice(0, maxLabels).map((v, i) => ({
            dimension: dim.name,
            index: i,
            val: v
          })))
        }
      })
      return labels
    })

    const labelCountOverflow = computed(() => {
      const count = sortedDimensions.value.reduce((prev, dim) => {
        const values = props.modelValue[dim.name]?.values
        return values ? prev + values.length : prev
      }, 0)
      return count - props.maxLabels
    })

    const hasFilters = computed(() => {
      return Object.keys(props.modelValue.filters).length > 0
    })

    // Methods
    const onMultiselectInput = (dimension: string | number, values: string[]) => {
      const filters = cloneDeep(props.modelValue)
      const existingValues = filters[dimension]
      if (!existingValues) {
        filters[dimension] = {
          operator: 'equals',
          values
        }
      } else {
        existingValues.values = values
      }
      if (filters[dimension].values.length === 0) {
        delete filters[dimension]
      }
      emit('update:modelValue', filters)
      emit('dimension-checked', dimension as string)
    }

    const onOperatorToggle = (dimension: string, checked: boolean) => {
      const filters = props.modelValue
      const existingValues = filters[dimension]
      existingValues.operator = checked ? 'equals' : 'notEquals'
      emit('update:modelValue', filters)
      emit('dimension-checked', dimension)
    }

    const onRemoveClicked = (dimension: string, index: number) => {
      const values = [...props.modelValue[dimension].values]
      values.splice(index, 1)
      onMultiselectInput(dimension, values)
    }

    // Watch
    watch(
      () => props.modelValue,
      () => {
        filters.value = cloneDeep(props.modelValue)
      },
      { deep: true, immediate: true }
    )

    const multiselectClasses = Object.assign({}, multiselectTailwindClasses)
    multiselectClasses.container += ' w-full'
    multiselectClasses.container = multiselectClasses.container.replace('mt-1', '')

    return {
      // Data
      filters,
      dateRange,

      // Computed
      sortedDimensions,
      inputLabels,
      hasFilters,
      labelCountOverflow,

      // Methods
      onMultiselectInput,
      onRemoveClicked,
      onOperatorToggle,

      // Misc
      multiselectClasses,
      getComparisonGroupColor,
      t
    }
  }
})
</script>
