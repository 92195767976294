<template>
  <WidgetShell
    :loading="isLoading"
    :title="title"
    :definition="definition"
    :edit-mode="editMode"
    :enlarged="enlarged"
    :has-data="hasData"
    @json-editor-input="onJSONEditorInput"
    @remove-clicked="$emit('remove-clicked')"
    @export="onExport(title, $event)"
    @save-image="onSaveAsImageClicked(title)"
    @expand-clicked="onExpandClicked"
  >
    <template #header>
      <VueFormMultiSelect
        v-model="metric"
        mode="single"
        :close-on-select="true"
        :options="definition.metrics.map(m => ({ value: m.name, label: translateDBName(m.name.toString()) }))"
        :classes="multiselectTailwindClasses"
        :can-clear="false"
        :can-deselect="false"
      />
    </template>

    <v-chart
      v-if="!isLoading"
      ref="chart"
      class="flex-grow min-h-px"
      :init-options="initOptions"
      :option="chartOptions"
      :update-options="updateOptions"
      :theme="ECHARTS_THEME_NAME"
      autoresize
    />
  </WidgetShell>
</template>

<script lang="ts">
import VueFormMultiSelect from '@vueform/multiselect'
import { Grouping } from 'crossfilter2'
import { EChartsOption, PieSeriesOption } from 'echarts'
import { PieChart } from 'echarts/charts'
import { use } from 'echarts/core'
import { defineComponent, ref, computed, watch } from 'vue'

import { echartsTooltipFormatter, translateDBName, ValueFormatter, ValueFormatterOptions, WidgetDefinition } from '@/plugins/dashboard'

import { ECHARTS_THEME } from '../theme'

import ChartWidget from './ChartWidgetMixin'
import { setupWidget, WidgetSettings, multiselectTailwindClasses, selectMetric } from './Widget'
import WidgetShell from './WidgetShell.vue'

use(PieChart)

export default defineComponent({
  components: {
    WidgetShell,
    VueFormMultiSelect
  },
  mixins: [ChartWidget],
  emits: ['remove-clicked', 'definition-changed'],
  setup (props, { emit }) {
    const metric = ref(selectMetric(props.queryBuilder.widgetMetrics[props.definition.uid], props.definition))
    let cachedGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    let cachedComparisonGroup: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined
    let large = false
    const chartOptions = ref({})

    const updateOptions = computed(() => {
      // See: https://echarts.apache.org/en/api.html#echartsInstance.setOption
      return { replaceMerge: ['legend', 'grid', 'series'] }
    })

    const updateChart = () => {
      if (!cachedGroup || commonWidget.isWaitingForBothData.value) {
        return
      }
      const formatter = new ValueFormatter()
      const options: ValueFormatterOptions = {
        metric: metric.value,
        dimensionName: props.definition.dimensions[0].name,
        maxSegments: 10
      }
      const data = formatter.format(cachedGroup, options)
      const series: PieSeriesOption[] = [
        {
          name: translateDBName(metric.value),
          type: 'pie',
          radius: '80%',
          left: large ? 0 : undefined,
          right: large ? 220 : undefined,
          center: ['50%', '50%'],
          data: data.map((d, index) => {
            d.itemStyle = {
              color: ECHARTS_THEME.color[index % (ECHARTS_THEME.color.length - 1)]
            }
            return d
          }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: false,
            position: 'inside'
          }
        }
      ]
      const newOptions: EChartsOption = {
        grid: {
          containLabel: true,
          left: 'left',
          right: 0,
          top: '10%',
          bottom: '40'
        },
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          formatter: echartsTooltipFormatter(props.definition.metrics.find(m => m.name === metric.value)!.formatter)
        }
      }

      if (large) {
        newOptions.legend = {
          data: data.map(r => r.name),
          right: 0,
          top: 20,
          bottom: 20,
          type: 'scroll',
          orient: 'vertical',
          textStyle: {
            overflow: 'truncate',
            width: 180
          }
        }
      }

      if (cachedComparisonGroup) {
        const comparisonData = formatter.format(cachedComparisonGroup, options)
        series.push({
          name: translateDBName(metric.value),
          type: 'pie',
          radius: ['65%', '80%'],
          left: large ? 0 : undefined,
          right: large ? 220 : undefined,
          center: ['50%', '50%'],
          data: comparisonData.map(d => {
            d.itemStyle = {
              color: data.find(d2 => d2.name === d.name)?.itemStyle?.color
            }
            return d
          }),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: false,
            position: 'inside'
          }
        })
        series[0].radius = [0, '60%']
      }
      newOptions.series = series
      chartOptions.value = newOptions
    }

    const updateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedGroup = v
      updateChart()
    }
    const comparisonUpdateFunc = (v: ReadonlyArray<Grouping<any, Record<string, any>>> | undefined) => {
      cachedComparisonGroup = v
      updateChart()
    }

    const onJSONEditorInput = (newDefinition: WidgetDefinition) => {
      emit('definition-changed', newDefinition)
    }

    watch(
      () => metric.value,
      () => updateChart()
    )

    watch(
      () => metric.value,
      () => props.queryBuilder.setMetric(props.definition.uid, metric.value !== props.definition.metrics[0].name ? metric.value : undefined),
      { immediate: true }
    )

    watch(
      () => props.definition,
      () => {
        metric.value = props.definition.metrics[0].name
      }
    )

    const settings: WidgetSettings = {
      props,
      dimension: computed(() => props.definition.dimensions[0]),
      updateFunc,
      comparisonUpdateFunc
    }
    const commonWidget = setupWidget(settings)

    watch(
      () => commonWidget.enlarged.value,
      () => {
        large = commonWidget.enlarged.value
        setTimeout(() => {
          updateChart()
        }, 0)
      }
    )

    return {
      // Data
      chartOptions,
      metric,

      // Computed
      updateOptions,

      // Methods
      onJSONEditorInput,

      // Misc
      translateDBName,
      multiselectTailwindClasses,

      ...commonWidget
    }
  }
})
</script>
