<template>
  <template
    v-for="(columns, i) in dtColumns"
    :key="i"
  >
    <div>
      <div class="text-base font-semibold leading-5 capitalize text-text-primary">
        {{ tagTemplates.value[i]?.mediatypes.filter(mt => mediatypes.value.includes(mt)).map(m => t('mediatypes.'+m)).join(', ') }}
      </div>
      <Datatable
        v-if="columns.columns.length && rows[i] && rows[i].length"
        v-model:items="rows[i]"
        :columns="columns.columns"
        :is-editable="!readonly"
        :validation="datatableValidation[i]"
        class="rounded"
        :class="{
          'outline outline-1 outline-red-400': hasError[i]
        }"
      >
        <template #_validation="{ row }">
          <ExclamationCircleIcon
            v-if="datatableValidation[i].getRowStatus(row) === ValidationStatus.ERROR"
            class="self-center w-4 h-4 text-red-600"
            aria-hidden="true"
          />
          <CheckCircleIcon
            v-else
            class="self-center w-4 h-4 text-green-600"
            aria-hidden="true"
          />
        </template>
        <template
          v-for="(p, idx) in dtConfigTemplates[i]"
          :key="idx"
          #[`${p.localFieldName}`]="{ row }"
        >
          <TruncateComponent
            :value="row.data.tag[p.field.split('.')[1]]"
          />
        </template>

        <!-- TODO if one row has error at the row level, the message is not shown -->
        <template
          v-for="(p, idx) in dtConfigTemplates[i]"
          :key="idx"
          #[`editable-${p.localFieldName}`]="{ row }"
        >
          <FormInput
            v-model="row.data.tag[p.field.split('.')[1]]"
            mode="datatable"
            data-editable="true"
            wrapper-class="w-full"
            name="tagid"
            type="text"
            label=""
            :null-if-empty="true"
            :focus-on-mount="true"
            @blur="onBlur($event, i, row, p)"
            @update:modelValue="onTagColumnUpdated()"
          />
        </template>
      </Datatable>
    </div>
  </template>
</template>

<script lang="ts">
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import { ErrorObject } from '@vuelidate/core'
import Joi from 'joi'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import { computed, defineComponent, PropType, ref, watch, Ref, unref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { FormType } from '@/types/form'

import TruncateComponent from '@/plugins/datatable/Components/TruncateComponent.vue'
import { useDatatable, useDatatableColumns } from '@/plugins/datatable/datatable'
import { Row, Column, DatatableValidation, DatatableColumns, ValidationStatus } from '@/plugins/datatable/datatable.d'
import Datatable from '@/plugins/datatable/Datatable.vue'
import theme from '@/plugins/datatable/theme'
import { getRowColumnValue } from '@/plugins/datatable/utils'
import { VIRTUAL_COLUMN_VALIDATION } from '@/plugins/filters'
import { useVuelidateError } from '@/plugins/form'
import { FieldErrors } from '@/plugins/form/serverErrors'

import { MediatypeTagsTemplate } from '@/models/adapter'
import { Mediatype } from '@/models/enum/mediatype'
import { CreateTag, getHumanTagName } from '@/models/tag'

import { useDatatableJoi } from '@/utils/useJoi'

import FormInput from '@/components/Form/FormInput.vue'

export default defineComponent({
  components: {
    Datatable,
    FormInput,
    TruncateComponent,
    ExclamationCircleIcon,
    CheckCircleIcon
  },
  props: {
    modelValue: {
      type: Object as PropType<CreateTag[]>,
      required: true
    },
    errors: {
      type: [Array] as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: true
    },
    class: {
      type: String as PropType<string | undefined>,
      required: false,
      default: ''
    },
    tagTemplates: {
      type: Object as PropType<Ref<MediatypeTagsTemplate[]>>, // Object that contain tagtemplate schema including validation
      required: true
    },
    mediatypes: {
      type: Object as PropType<Ref<Mediatype[]>>,
      required: true
    },
    actionType: {
      type: String as PropType<FormType>,
      required: false,
      default: FormType.CREATE
    },
    validation: {
      type: Object as PropType<any>,
      required: true
    },
    externalResults: {
      type: Object as PropType<Record<string, FieldErrors>>,
      required: true
    },
    bidderCode: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    },
    repackagingName: {
      type: String as PropType<string | undefined>,
      default: () => undefined
    }
  },
  emits: ['update:modelValue'],
  setup (props) {
    const { t } = useI18n()

    // Special field name to be used as first column in datatable.
    // Will handle the name of the package rule
    const NAME_FIELD = 'packageRuleName'

    const displayWarning = computed(() => props.actionType === FormType.CREATE)

    const getPackages = (mediatypes: Mediatype[]) => computed(() => {
      const tags = props.modelValue.filter(tag => mediatypes.some(mt => tag.mediatypes.includes(mt))).map(tag => {
        const tagTemplate = props.tagTemplates.value.find(tt => tt.mediatypes.some(mt => tag.mediatypes.includes(mt)))
        if (tagTemplate) {
          Object.keys(tagTemplate.tags).forEach(key => {
            tag.tag[key] = tag.tag[key] ? tag.tag[key] : undefined
          })
        }
        return tag
      })

      return tags
    })

    const errorPath = (rowIndex: number, field: string) => ['tags', '$elements', rowIndex.toString(), '$fields', 'tag', '$fields', field]

    const onBlur = (ev: any, templateIndex: number, row: any, column: any) => {
      datatableValidation.value[templateIndex].validCell(row, column, getRowColumnValue(row.data, column))
      props.validation.tags.$touch()
    }

    const dtConfigColumns = ref<Column[][]>([])
    const dtColumns = ref<DatatableColumns[]>([])
    const rows = ref<Row[][]>([])
    const datatableValidation = ref<DatatableValidation[]>([])

    const getExternalErrorIndex = (dtIndex: number, rowIndex: number): number => {
      const cumulatedRows = sumBy(rows.value.slice(0, dtIndex), r => r.length)
      return rowIndex + cumulatedRows
    }

    // compute the columns for datatable
    const computeDtColumns = (): Column[][] => {
      return props.tagTemplates.value.map((tagTemplate, i) => {
        const dtIndex = i
        const expectedColumns = [...new Set(Object.keys(tagTemplate.tags))]

        const columnsConfig = expectedColumns.map(columnName => {
          const c: Column = {
            field: columnName === NAME_FIELD ? columnName : `tag.${columnName}`
          }

          const tag = tagTemplate.tags[columnName]
          const humanName = getHumanTagName(columnName, props.bidderCode)
          c.name = humanName
          c.sortable = false
          c.required = tag.mandatory
          c.width = 300
          c.validator = (val: any, row: Row) => {
            let schema = (tag.validation === 'number' ? Joi.number().integer() : Joi.string().regex(/^[\d\w\-_{}]+$/i)).label(humanName)
            if (tag.mandatory) {
              schema = schema.required()
            } else if (tag.validation === 'string') {
              schema = schema.allow('', null)
            }
            const rowIndex = getExternalErrorIndex(dtIndex, unref(row.index))
            return useDatatableJoi(schema, val, props.validation.tags, props.externalResults, errorPath(rowIndex, columnName))
          }

          return c
        })

        return [
          {
            field: VIRTUAL_COLUMN_VALIDATION,
            width: 30,
            name: '',
            frozen: true,
            sortable: false,
            rowCellClass: '!px-0',
            condition: () => !props.readonly
          },
          {
            field: NAME_FIELD,
            width: 300,
            name: props.repackagingName ? t('mappingRules.packageName', [props.repackagingName]) : '',
            frozen: true,
            locked: !props.readonly && !!(props.repackagingName),
            sortable: false
          },
          ...columnsConfig
        ]
      })
    }

    const dtConfigTemplates = computed<any[][]>(() =>
      dtColumns.value.map(columns =>
        columns.columns.filter((c: any) => c.field !== VIRTUAL_COLUMN_VALIDATION).map((c: any) => {
          c.localFieldName = c.field.replaceAll('.', '__')
          return c
        }).filter((d: any) => d.field !== NAME_FIELD)
      )
    )

    const revalidateCells = () => {
      datatableValidation.value.forEach((dt, i) => {
        rows.value[i].forEach(row => {
          dtConfigTemplates.value[i].forEach(column => {
            dt.validCell(row, column, getRowColumnValue(row.data, column))
          })
        })
      })
    }

    const updateDatatables = () => {
      rows.value = []
      datatableValidation.value = []
      dtColumns.value = []
      dtConfigColumns.value = computeDtColumns()
      dtConfigColumns.value.forEach((columns, i) => {
        const packages = getPackages(props.tagTemplates.value[i].mediatypes)
        const localColumns = useDatatableColumns(columns)
        const { rows: dtRows, validation: dtValidation } = useDatatable(packages, localColumns, undefined)

        dtColumns.value.push(localColumns)
        rows.value.push(orderBy(dtRows.value, ['data.packageRuleName']))
        datatableValidation.value.push(dtValidation)
      })
      revalidateCells()
    }

    // Used when we want to create.
    // the `props.modelValue` will be filled only we have selected
    // a seat and a repackaging, but the component is already rendered.
    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (Object.keys(newValue) !== Object.keys(oldValue)) {
          updateDatatables()
        }
      }
    )
    updateDatatables()

    const fieldErrors = computed(() => useVuelidateError(props.errors) || {})

    // TODO columnErrors are not used and server validation errors don't show on the view
    const columnErrors = reactive({} as Record<number, Record<number, Record<string, string[] | undefined>>>)

    const getColumnErrors = (index: number, column: string): string[] | undefined => {
      const el = props.externalResults.tags?.$elements?.[index]?.$fields?.tag?.$fields
      if (!el) {
        return undefined
      }

      return el[column]?.$errors
    }

    const updateColumnErrors = () => {
      for (const key in columnErrors) {
        delete columnErrors[key]
      }
      dtColumns.value.forEach((_, i) => {
        const cumulatedRows = sumBy(rows.value.slice(0, i), r => r.length)
        columnErrors[i] = {}
        rows.value[i].forEach((r: Row) => {
          const j = r.index
          columnErrors[i][j] = {}
          dtConfigTemplates.value[i].forEach(c => {
            columnErrors[i][j][c.name] = getColumnErrors(cumulatedRows + j, c.name) // TODO calculate correct index if there are multiple datatables
          })
        })
      })
    }

    watch(
      () => props.externalResults,
      () => updateColumnErrors(),
      { deep: true, immediate: true }
    )

    watch(
      () => props.externalResults,
      () => revalidateCells()
    )

    const onTagColumnUpdated = () => {
      props.validation.tags.$touch()
    }

    const hasError = computed(() => {
      return rows.value.map((r, i) => {
        if (fieldErrors.value?.$elements === undefined && props.externalResults.tags?.$elements === undefined) {
          return false
        }
        const offset = getExternalErrorIndex(i, 0)
        for (let j = offset; j < offset + r.length; j++) {
          if (fieldErrors.value?.$elements?.[j] !== undefined || props.externalResults.tags?.$elements?.[j] !== undefined) {
            return true
          }
        }
        return false
      })
    })

    return {
      t,
      rows,
      dtColumns,
      dtConfigTemplates,
      getRowColumnValue,
      datatableValidation,
      onBlur,
      displayWarning,
      fieldErrors,
      invalidCellClass: theme.cellNotValid,
      columnErrors,
      onTagColumnUpdated,
      hasError,
      ValidationStatus
    }
  }
})
</script>
