{
  "actions": {
    "abort": "Abort {0}",
    "add": "@.capitalize:app.add {0}",
    "addComparisonGroup": "Add a comparison group",
    "addFilter": "Add filter | Add filters",
    "apply": "Apply",
    "applyToAll": "Apply to all",
    "archive": "Archive",
    "back": "Back",
    "bypass": "Bypass",
    "cancel": "Cancel",
    "checkStatus": "Check status",
    "clear": "Clear",
    "clearAll": "Clear all",
    "clipboard": "Copy to clipboard",
    "clipboardFailure": "Failed to copy lines to clipboard: {0}",
    "clipboardSuccess": "Lines successfully copied to clipboard.",
    "close": "Close {0}",
    "collapse": "Collapse",
    "collapseSection": "Collapse section",
    "confirm": "Confirm",
    "copyAllLines": "Copy all lines",
    "copyDone": "Copy done",
    "copyMissingBidderLines": "Copy missing bidder lines",
    "create": "@.capitalize:app.create {0}",
    "create-new": "@.capitalize:app.create-new",
    "delete": "@.capitalize:app.delete {0}",
    "deselectAll": "Deselect all",
    "disable": "Disable",
    "discard": "Discard {0}",
    "dismiss": "Dismiss",
    "doLater": "Do it later",
    "download": "Download",
    "duplicate": "Duplicate {0}",
    "edit": "Edit {0}",
    "enable": "Enable",
    "expand": "Expand",
    "export": "Export",
    "exportAs": "Export as {0}",
    "exportStatusList": "Export status list",
    "finish": "Finish",
    "import": "Import",
    "importNewStatus": "Import new status",
    "invite": "Invite",
    "manage": "Manage",
    "more": "See more lines",
    "new": "@.capitalize:app.new {0}",
    "next": "Next",
    "open": "Open",
    "openMenu": "Open menu",
    "pause": "Pause {0}",
    "refresh": "Refresh",
    "remove": "Remove {0}",
    "removeLastComparisonGroup": "Remove last comparison group",
    "resend": "@.capitalize:app.resend",
    "reset": "Reset",
    "resume": "Resume {0}",
    "revoke": "Revoke {0}",
    "save": "Save",
    "saveAs": "Save as {0}",
    "saveStatusChanges": "Save status changes",
    "search": "Search",
    "seeAll": "See all",
    "selectAll": "Select all",
    "send": "Send",
    "stopEdit": "Stop editing",
    "update": "Update {0}",
    "updateFilter": "Update filter | Update filters"
  },
  "adapters": {
    "create": "@:actions.create an adapter",
    "createSuccess": "The adapter has been successfully created.",
    "edit": "{name}"
  },
  "adapterSeatConfigs": {
    "accountid": "accountid",
    "affiliateid": "affiliateid",
    "callerid": "callerid",
    "endpointprefix": "endpointprefix",
    "memberid": "memberid",
    "networkid": "networkid",
    "partner": "partner",
    "partnerid": "partnerid",
    "pubid": "pubid",
    "publishedid": "publishedid",
    "xapipassword": "xapipassword",
    "xapiusername": "xapiusername"
  },
  "adstxt": {
    "adagio": "Adagio",
    "bypassSection": {
      "subtitle": "Our engine will ignore the fact that we could not crawl the ads.txt file. Use with caution!",
      "title": "Force ads.txt check"
    },
    "certEmpty": "Cert empty",
    "certPresent": "Cert present",
    "direct": "Direct",
    "manager": "Intermediary",
    "manualCheck": "Ads.txt has been checked manually due to crawler limitation",
    "notDirect": "Not direct",
    "notFound": "Not found",
    "notPublisher": "Not publisher",
    "notReseller": "Not reseller",
    "ok": "OK",
    "primary": "Primary",
    "reseller": "Reseller",
    "resellerLinesInfo": "Those lines are based on the seller type and the opted-in seats. Adding the following ads.txt lines will allow traffic to be monetized once your websites domains are whitelisted by the bidder.",
    "secondary": "Secondary",
    "unknownRelationship": "Unknown relationship"
  },
  "algorithmParamsHints": {
    "bidability": {
      "query-intelligence": {
        "dry_run": "When activated, the bidability predicts outcomes without taking action.",
        "min_auc_roc": "Minimum AUC ROC value allowed for the model to predict (Min:0.5, Max:1)",
        "perc_revenue": "Percentage of revenue we want to keep. Used by bidability quality control module to fix probability threshold above which the bid is considered probable. (Min: 0, Max: 1)"
      }
    },
    "bidadjustment": {
      "manual-inference": {
        "cpm": "Allow to compensate errors that we have at the end of the month. If we receive a bid at 1, and we add a discrepancies at 0,05, we consider the bid was at 0,95 and Adagio keep the 0,05 to compensate. (Min: 0, Max: 0.1)",
        "ivt": "Allow to compensate invalid traffic that we have at the end of the month. If we receive a bid at 1, and we add a discrepancies at 0,05, we consider the bid was at 0,95 and Adagio keep the 0,05 to compensate. (Min: 0, Max: 0.1)"
      }
    },
    "dynamicfee": {
      "manual-inference": {
        "fee": "Negative offset applied to the Publisher fees as found in Publisher > Settings > Commission > Open commission. If publisher’s open commission is 0.15 and dynamic fees fee is 0.05, final applied fee will be 0.1. (Min: 0, Max:0.25)",
        "fee_application": "Apply the fee only on the first call or both first and others calls."
      }
    },
    "dynamicfloorprice": {
      "manual-inference": {
        "increase_factor": "Factor that increase the floor price of the 2nd call. (Min: 0.8, Max: 10)",
        "sampling": "Sampling of the bid requests which we apply Dynamic Floor Price. (Min: 0, Max: 1)"
      }
    },
    "shaping": {
      "manual-inference": {
        "sampling": "Force a sampling value. (Min: 0, Max: 1)"
      },
      "query-intelligence": {
        "offset": "Apply an offset to the sampling value. (Min: -1, Max: 1)"
      }
    },
    "usersync": {
      "manual-inference": {
        "bidders": "Define the bidder order that we will prioritize during the sync process. Bidders which are not selected will never be called for the usersync.",
        "sync_count": "Number of bidders sync to return in one AdResponse. (Min: 1, Max: 25, Default: 4)",
        "throttle_delay": "Skip the sync of a bidder that already got the opportunity to sync during a certain time. (Min: 1h, Max: 48h, Default: 6h)",
        "uid_max_age": "Delay after which we re-sync the cookies. (Min: 1day, Max: 60days, Default: 30 days)"
      },
      "query-intelligence": {
        "bidder_prioritized": "Allow to enforce bidder(s) before playing the algo based on RPB inside a group.",
        "groups_order": "Define the groups order that we will prioritized during the sync process.",
        "sync_count": "Number of bidders sync to return in one AdResponse. (Min: 1, Max: 25, Default: 4)",
        "throttle_delay": "Skip the sync of a bidder that already got the opportunity to sync during a certain time. (Min: 1h, Max: 48h, Default: 6h)",
        "uid_max_age": "Delay after which we re-sync the cookies. (Min: 1day, Max: 60days, Default: 30 days)"
      }
    }
  },
  "apiKeys": {
    "create": "@:actions.create an API Key",
    "createSuccess": "The API key has been successfully created!",
    "createTitle": "@:apiKeys.create",
    "delete": "@:actions.delete an API Key",
    "deleteConfirm": "Are you sure you want to delete the <strong>{0}</strong> API key?",
    "deleteError": "Unable to delete the API key {0}",
    "deleteSuccess": "The API has been sucessfully deleted",
    "deleteThe": "@:actions.delete the API Key {0}",
    "deleteTitle": "@:apiKeys.delete"
  },
  "app": {
    "abortedAt": "aborted at",
    "accountId": "account ID",
    "action": "action | actions",
    "adapter": "adapter | adapters",
    "adapterCode": "adapter code | adapter codes",
    "adapterName": "adapter",
    "add": "add",
    "add-named": "add {0}",
    "address": "address | adresses",
    "adhoc": "ad-hoc",
    "adjustment": "adjustment | adjustments",
    "adrequest": "ad request",
    "adstxt": "ads.txt",
    "adstxt11": "ads.txt 1.1",
    "adstxtLine": "ads.txt line | ads.txt lines",
    "adstxtLineType": "line type",
    "adunit": "ad unit",
    "advertiserDomain": "advertiser domain | advertiser domains",
    "alias": "alias",
    "amountInCents": "amount in cents | amounts in cents",
    "apiKey": "API key | API keys",
    "approval": "approval",
    "approve": "approve",
    "approved": "approved",
    "archive": "archive",
    "auctionStep": "auction step",
    "autoBillingOff": "auto-billing off",
    "autoMapping": "auto-mapping | auto-mappings",
    "awaiting": "awaiting",
    "bidAdjustement": "bid adjustment",
    "bidAllowed": "bid allowed | bids allowed",
    "bidder": "bidder | bidders",
    "bidderCategory": "bidder category",
    "bidderCode": "bidder code",
    "bidderGroup": "bidder group | bidder groups",
    "bidderId": "bidder ID",
    "bidderLine": "bidder line | bidder lines",
    "bidderLinesStatus": "bidder lines status",
    "bidderName": "bidder",
    "bidderOptinStatus": "bidder status",
    "bidderPrioritized": "bidder(s) prioritized",
    "bidType": "bid type",
    "billing": "billing | billings",
    "billingCountry": "billing country | billing countries",
    "billingCurrency": "billing currency | billing currencies",
    "billingEmailCommunication": "billing email communication | billing email communications",
    "billingPeriod": "billing period | billing periods",
    "blocked": "blocked",
    "blocklist": "blocklist | blocklists",
    "casesNumber": "number of cases",
    "category": "category | categories",
    "certAuthId": "certification authority ID",
    "city": "city | cities",
    "clear": "clear",
    "close": "close",
    "collapse": "collapse",
    "commission": "commission | commissions",
    "compareGroups": "compare groups",
    "competitionSampling": "competition sampling",
    "completed": "completed",
    "configuration": "configuration | configurations",
    "confirm": "confirm",
    "connect": "connect",
    "content": "content",
    "copy": "copy",
    "country": "country | countries",
    "countryCode": "country code",
    "create": "create",
    "create-new": "create a new {0}",
    "currency": "currency | currencies",
    "currencyRate": "currency rate | currency rates",
    "dashboard": "dashboard | dashboards",
    "dateRange": "date range",
    "dayOfExport": "day of export",
    "dealCommission": "deal commission",
    "dealCommissionFee": "Deal commission",
    "dealRPB": "deal RPB fee",
    "dealsCommission": "deals commission | deals commissions",
    "default": "default",
    "defaultView": "default View",
    "delete": "delete",
    "delete-named": "delete {0}",
    "demand": "demand | demands",
    "demandLibrary": "demand library",
    "description": "description | descriptions",
    "device": "device | devices",
    "dfpEnabled": "DFP enabled",
    "dfpIncreaseFactor": "DFP increase factor",
    "dfpSampling": "DFP sampling",
    "dimension": "dimension | dimensions",
    "disabled": "disabled",
    "discrepanciesBidAdjustment": "discrepancies",
    "draft": "draft",
    "dryRun": "dry run",
    "earning": "earning | earnings",
    "edit": "edit",
    "edit-named": "edit {0}",
    "element": "element | elements",
    "email": "email | emails",
    "enabled": "enabled",
    "endDate": "end date",
    "endpoint": "endpoint | endpoints",
    "enrichment": "enrichment | enrichments",
    "enrichments": "enrichments",
    "environment": "environment | environments",
    "error": "error | {count} errors",
    "expand": "expand",
    "export": "export | exports",
    "extract": "extract | extracts",
    "factor": "factor | factors",
    "fee": "fee | fees",
    "filter": "filter | filters",
    "filters": "filters",
    "firstname": "firstname | firstnames",
    "floor": "floor | floors",
    "floorRule": "floor rule | floor rules",
    "form": "form | forms",
    "formatter": "formatter",
    "general": "general | generals",
    "gross": "gross",
    "grossRevenue": "gross earnings",
    "group": "group | groups",
    "groupsOrder": "groups order",
    "home": "home",
    "inBlocklists": "In a blocklist | In blocklists",
    "increaseFactor": "Increase factor",
    "info": "info | infos",
    "information": "information | informations",
    "insight": "insight | insights",
    "instantReport": "instant report | instant reports",
    "inventory": "inventory | inventories",
    "invitation": "invitation | invitations",
    "invoice": "invoice | invoices",
    "ivtBidAdjustment": "ivt",
    "jobTitle": "job title | job titles",
    "keyword": "Split Keyword | Split Keywords",
    "language": "language | languages",
    "lastname": "lastname | lastnames",
    "lastStep": "last step",
    "lastUpdate": "last updated on {lastUpdate}",
    "line": "line | lines",
    "list": "list | lists",
    "location": "location | locations",
    "management": "management | managements",
    "manager": "manager | managers",
    "managerAccountId": "manager ID",
    "managerCertAuthId": "cert auth ID",
    "managerDomain": "manager domain",
    "managerLine": "intermediary line | intermediary lines",
    "managerProperty": "manager property | manager properties",
    "mapping": "mapping | mappings",
    "mappingRule": "mapping rule | mapping rules",
    "max": "max.",
    "medias": "medias | medias",
    "mediatype": "mediatype | mediatypes",
    "metric": "metric | metrics",
    "metric-n": "Metric {0}",
    "min": "min.",
    "minAUCROC": "minimum performance",
    "month": "month | months",
    "name": "name",
    "net": "net",
    "netEarning": "net earning | net earnings",
    "netFloorPrice": "Net floor (USD) | Net floors (USD)",
    "new": "new",
    "nextStep": "next step",
    "no-data": "no data",
    "noRevenue": "no revenue",
    "onlyForMe": "Only for me",
    "open": "open",
    "openCommission": "open commission | open commissions",
    "openCommissionFee": "Open commission",
    "openRPB": "Open RPB fee",
    "optimization": "optimization | optimizations",
    "optimRule": "optimization | optimizations",
    "optimRuleCase": "optimization rule case | optimization rule cases",
    "optin": "optin | optins",
    "or": "or",
    "organizationId": "organization id",
    "organizationName": "organization name",
    "owner": "owner | owners",
    "ownerAccountId": "owner account ID",
    "ownerCertAuthId": "owner cert auth ID",
    "ownerDomain": "owner domain",
    "ownerProperty": "owner property | owner properties",
    "package": "package | packages",
    "pagetype": "pagetype | pagetypes",
    "paid": "paid",
    "param": "param | params",
    "parameter": "parameter | parameters",
    "password": "password | passwords",
    "pausedAt": "paused at",
    "payment": "payment",
    "paymentETA": "estimated payment date",
    "paymentScheduled": "payment scheduled",
    "pbsCommissionFee": "PBS commission",
    "percRevenue": "keep revenue",
    "period": "period | periods",
    "permission": "permission | permissions",
    "placement": "placement | placements",
    "preset": "preset | presets",
    "preview": "preview",
    "previousStep": "previous step",
    "priority": "priority | priorities",
    "processingInvoice": "processing invoice",
    "property": "property | properties",
    "publisher": "publisher | publishers",
    "publisherAccountId": "publisher account ID",
    "publisherGroup": "publisher group | publisher groups",
    "publisherName": "publisher",
    "publisherOptinStatus": "publisher status",
    "recipient": "no recipients | {0} recipient | {0} recipients",
    "reducer": "reducer | reducers",
    "refresh": "refresh | refreshes",
    "refreshRule": "refresh rule | refresh rules",
    "region": "region | regions",
    "reject": "reject",
    "rejected": "rejected",
    "relationship": "relationship",
    "remove": "remove",
    "repackaging": "repackaging | repackagings",
    "report": "report | reports",
    "resend": "resend",
    "revert": "revert changes",
    "role": "role | roles",
    "row": "row | rows",
    "rule": "rule | rules",
    "save": "save",
    "save-as": "save as {0}",
    "scheduled": "scheduled",
    "scheduledReport": "scheduled report | scheduled reports",
    "search": "search",
    "seat": "seat | seats",
    "seatName": "seat",
    "seatsBoosterRule": "seats booster | seats boosters",
    "seatType": "seat type",
    "segment": "segment | segments",
    "sellersjson": "sellers.json",
    "sellersjsonDomain": "sellers.json domain | sellers.json domains",
    "sellersjsonSellerType": "sellers.json seller type",
    "sellertype": "sellertype | sellertypes",
    "sellerType": "seller type | seller types",
    "separator": "separator | separators",
    "serviceGroup": "service group | service groups",
    "setting": "setting | settings",
    "shaping": "shaping | shapings",
    "shapingType": "shaping type",
    "size": "size | sizes",
    "sort": "sort | sorts",
    "source": "source | sources",
    "splitRule": "split rule | split rules",
    "splitRuleCase": "split rule case | split rule cases",
    "splitRuleCaseSeat": "split rule case seat | split rule case seats",
    "startDate": "start date",
    "startedAt": "started at",
    "status": "status",
    "store": "store | stores",
    "subcategory": "subcategory | subcategories",
    "submissionDate": "submission date",
    "success": "success",
    "supplyChain": "supply chain",
    "supplyChainObjectParams": "supply chain object params",
    "syncCount": "sync count",
    "table": "table",
    "tag": "tag | tags",
    "tagTemplate": "tag template | tag templates",
    "targetable": "targetable | targetables",
    "targeting": "targeting | targetings",
    "tax": "tax | taxes",
    "taxedRevenue": "taxed earnings",
    "template": "template | templates",
    "throttleDelay": "throttle delay",
    "timezone": "timezone | timezones",
    "title": "title",
    "token": "token | tokens",
    "tokenUrl": "token URL",
    "trafficShapingType": "traffic shaping | traffic shapings",
    "trafficShapingValue": "traffic shaping value | traffic shaping values",
    "trafficShare": "traffic share",
    "troubleshooting": "troubleshooting | troubleshootings",
    "type": "type | types",
    "UID": "UID",
    "uidMaxAge": "UID max age",
    "unwhitelisted": "unwhitelisted",
    "updatedAt": "updated at",
    "user": "user | users",
    "usersync": "user sync",
    "validate": "validate",
    "validatedInvoice": "invoice validated",
    "variable": "variable | variables",
    "variant": "variant",
    "version": "version | versions",
    "view": "view | views",
    "warning": "warning | {count} warnings",
    "website": "website | websites",
    "weight": "weight | weights",
    "weightType": "weight type | weight types",
    "whitelist": "whitelist | whitelists",
    "whitelisting": "whitelisting",
    "widget": "widget | widgets",
    "winningAdvertiser": "winning advertiser | winning advertisers",
    "winningAdvertiserDomain": "winning advertiser domain | winning advertiser domains",
    "wiringOrder": "wiring order",
    "zipCode": "zip code | zip codes"
  },
  "auth": {
    "backApplication": "Back to the application",
    "backInvitation": "Back to the invitation",
    "forgotPwd": "Forgot password?",
    "logIn": "Log in",
    "logOut": "Log out",
    "logoutError": "An error occurred while logging out.",
    "rememberMe": "Remember me",
    "signIn": "'Sign in",
    "signOut": "'Sign out"
  },
  "bidders": {
    "create": "New Bidder",
    "createSuccess": "The bidder has been successfully created.",
    "edit": "{name}",
    "editSuccess": "The bidder has been successfully saved."
  },
  "bidType": {
    "gross": "Gross",
    "net": "Net"
  },
  "blocklists": {
    "create": "New Blocklist",
    "createSuccess": "The blocklist has been created successfully.",
    "edit": "{name}",
    "editSuccess": "The blocklist has been updated successfully.",
    "errorDomains": "You have to remove or fix invalid domains before submitting the form.",
    "limitInfo": "The number of domains in a blocklist is limited to 5000.",
    "newDomains": "New domains",
    "newDomainsPlaceholder": "Domains to block, one per line.",
    "noDomains": "No domain."
  },
  "calendar": {
    "friday": "Friday",
    "last7Days": "Last 7 days - Daily",
    "monday": "Monday",
    "pastDay": "Past day - Daily",
    "pastMonth": "Past month - Monthly",
    "pastWeek": "Past week - Weekly",
    "rollingMonth": "Rolling month - Monthly",
    "rollingWeek": "Rolling week - Weekly",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "thursday": "Thursday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday"
  },
  "cube": {
    "schemas": {
      "AdagioReportingV1": "Reporting v1",
      "PsmDailyKpis2": "Daily KPIs",
      "PublishersDailySeatKpisEnriched": "KPIs by Seat",
      "PublishersHourlyKpis": "Inventory KPIs"
    }
  },
  "customerView": {
    "accessAdminView": "Access admin view",
    "accessCustomerView": "Access customer view",
    "adminView": "View admin",
    "customerView": "View customer"
  },
  "dashboards": {
    "accessibilityNotice": "This dashboard will only be accessible to {0} \"{1}\".",
    "accessibilityNoticePublic": "This dashboard will be accessible to all {0}.",
    "addEnrichment": "Add enrichment",
    "addForeignDimension": "Add foreign dimension",
    "addMetricError": "Invalid metric definition: {0}",
    "andMore": "and {0} more...",
    "comparisonGroupFiltersSelection": "Select the params of each comparison group",
    "conditionDimension": "Condition dimension",
    "conditionDimensionSeparator": "Condition dimension separator",
    "conditionValueDimension": "Condition value dimension",
    "copy": "{0} (Copy)",
    "create": "Create a dashboard",
    "created": "Dashboard created!",
    "createDashboard": "Create a dashboard",
    "createdEnrichments": "Created enrichments.",
    "createSourceError": "@:message.createError source: {0}",
    "createSuccess": "The dashboard has been created successfully.",
    "dashboardCreationError": "Could not create dashboard: {0}",
    "dataLimit": "Data limit reached and truncated. Metrics may be incorrect.",
    "defaultWidgetTitle": "[{ vizType }] { metrics } by { dimensionName }",
    "deleteSource": "Delete a source",
    "deleteSourceNotice": "Deleting this source will remove all associated stores and widgets.",
    "deleteStore": "Delete a store",
    "deleteStoreNotice": "Deleting this store will remove all associated widgets.",
    "disableComparison": "Disable comparison",
    "dontSort": "Don't sort",
    "duplicateDashboard": "Duplicate this dashboard",
    "duplicateError": "Failed to duplicate store: {0}",
    "editorTitle": "Dashboard editor",
    "editSuccess": "The dashboard has been successfully updated.",
    "editWidgetTitle": "@:actions.edit Widget",
    "effectAfterRefresh": "Only takes effect after a refresh",
    "getTableDefinitionError": "Could not get table definition for source {0}: {1}",
    "globalSumDimensions": "Sum dimensions",
    "hideComparison": "Hide comparison",
    "json": "Dashboard JSON",
    "jsonClipboardFailure": "Failed to copy JSON to clipboard: {0}",
    "jsonClipboardSuccess": "Dashboard JSON copied to clipboard.",
    "jsonValidationError": "Invalid widget definition: {0}",
    "loadError": "@:messages.loadError dashboard: {0}",
    "loading": "Loading dashboard...",
    "loadTableDefinitionError": "Could not get table definition for source {0}: {1}",
    "nothingToShow": "Nothing to show",
    "onlySelectedRows": "Only selected rows",
    "overrideSumDimensions": "Override sum dimensions",
    "refreshError": "Could not refresh dashboard data.",
    "refreshFiltersError": "@:messages.refreshError filters.",
    "removeSource": "Remove (removes all associated stores and widgets)",
    "removeStore": "Remove (removes all associated widgets)",
    "requiredFilters": "Please select between {min} and {max} {dimension} filters.",
    "requiresDimension": " (requires \"{0}\")",
    "saveError": "@:messages.saveError dashboard: {0}",
    "saveSuccess": "Dashboard saved!",
    "selectCompositeNotice": "You can only select one row at a time in widgets using a composite dimension.",
    "shiftSelectionDisabled": "Shift-selection is disabled for widgets using a composite dimension.",
    "showAllLines": "Show all lines",
    "showComparison": "Show comparison",
    "showOnlySelectedLines": "Show only selected lines",
    "sortDefault": "Sort by default on",
    "sortDefaultOrder": "Sort order",
    "types": {
      "bidders": "Bidders",
      "groupBidders": "Bidders groups",
      "groupPublishers": "Publishers groups",
      "groups": "Groups",
      "publishers": "Publishers"
    },
    "viewWidget": "Click to view widget",
    "widgetCreated": "Widget created.\nThe widget has been added to the bottom of the dashboard.",
    "widgetInfoTitle": "Widget information"
  },
  "datatable": {
    "bulk": {
      "deleteSelected": "Delete selection"
    },
    "csv": {
      "fileTooLargeMessage": "Please make sure your file doesn't exceed {0} KiB in size.",
      "fileTooLargeTitle": "File too large",
      "headerIssueMessage": "It seems your file doesn't contain all expected columns. Please export the website list to obtain a template of expected columns",
      "headerIssueMessageMissingColumns": "It seems your file doesn't contain all expected columns.\nMissing columns: {0}",
      "headerIssueMessageUnexpectedColumns": "It seems your file contains unexpected columns: {0}",
      "headerIssueTitle": "CSV Header issue",
      "invalidFileMessage": "We couldn't decode the CSV you provided, please make sure that your CSV is valid (you can use online CSV linters for that purpose)",
      "invalidFileTitle": "Invalid CSV",
      "tooManyLinesMessage": "Please make sure your file contains at most {0} lines.",
      "tooManyLinesTitle": "Too many lines",
      "unknownErrorMessage": "An unknown error has occurred, please contact us.",
      "unknownErrorTitle": "Unknown Error"
    },
    "getStarted": "Get started by creating a new item.",
    "newLine": "Add New",
    "noItems": "There are no items to display.",
    "pagination": {
      "rowsDisplayed": "Showing {from}-{to} of {total} items",
      "rowsPerPage": "Rows per page: {0}"
    }
  },
  "dates": {
    "aCenturyAgo": "A century ago",
    "aMilleniumAgo": "A millenium ago",
    "aMinuteAgo": "A minute ago",
    "aMonthAgo": "A month ago",
    "anHourAgo": "An hour ago",
    "aSecondAgo": "A second ago",
    "aWeekAgo": "A week ago",
    "aYearAgo": "A year ago",
    "centuriesAgo": "{0} centuries ago",
    "comparedWith": "compared with",
    "current-month": "Current month",
    "current-quarter": "Current quarter",
    "current-week": "Current week",
    "day": "Day",
    "day-before": "Day before",
    "daysAgo": "{0} days ago",
    "defaultComparisonPeriod": "Default comparison period",
    "defaultPeriod": "Default period",
    "hour": "Hour",
    "hoursAgo": "{0} hours ago",
    "inACentury": "In a century",
    "inAMillenium": "In a millenium",
    "inAMinute": "In a minute",
    "inAMonth": "In a month",
    "inAnHour": "In an hour",
    "inASecond": "In a second",
    "inAWeek": "In a week",
    "inAYear": "In a year",
    "inCenturies": "In {0} centuries",
    "inDays": "In {0} days",
    "inHours": "In {0} hours",
    "inMillenia": "In {0} millenia",
    "inMinutes": "In {0} minutes",
    "inMonths": "In {0} months",
    "inSeconds": "In {0} seconds",
    "inWeeks": "In {0} weeks",
    "inYears": "In {0} years",
    "justNow": "Just now",
    "last-days": "Last {0} days",
    "milleniaAgo": "{0} millenia ago",
    "minutesAgo": "{0} minutes ago",
    "month": "Month",
    "month-before": "Month before",
    "monthsAgo": "{0} months ago",
    "never": "Never",
    "noComparison": "No comparison",
    "past-day": "Past day",
    "past-month": "Past month",
    "past-quarter": "Past quarter",
    "past-week": "Past week",
    "past-weeks": "Past {0} weeks",
    "past-year": "Past year",
    "quarter": "Quarter",
    "quarter-before": "Quarter before",
    "same-before": "Same period before",
    "secondsAgo": "{0} seconds ago",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "week": "Week",
    "week-before": "Week before",
    "weeksAgo": "{0} weeks ago",
    "year": "Year",
    "year-before": "Year before",
    "yearsAgo": "{0} years ago",
    "yesterday": "Yesterday"
  },
  "demandLibrary": {
    "alreadyConnected": "Already {0} publishers connected",
    "countConnected": "You and {0} publishers connected",
    "errorAccept": "Unable to connect {name}, try again later or contact us.",
    "errorDecline": "Unable to reject {name}, try again later or contact us.",
    "message": "The demand library is our catalog of bidders who can be added at any time",
    "modal": {
      "body": {
        "main": "{strong_0} your domains as soon as the bidder whitelists them, {strong_1}",
        "strong_0": "To quickly monetize",
        "strong_1": "copy and paste the following lines into your ads.txt."
      },
      "form": {
        "placeholder": "Fill in one or more emails to assign this task to someone else",
        "title": "Assign this task to"
      },
      "missing": {
        "link": "Seats page.",
        "main": "Find these lines on the {link}"
      },
      "notifications": {
        "copyDoneMessage": "Your ads.txt will be crawled in 12 hours. Follow the status in the Website tab and update your ads.txt if necessary.",
        "copyDoneTitle": "Thank you, your connection is in progress!",
        "laterMessage": "This connection will start monetizing once your websites have a valid supply chain configuration.",
        "laterTitle": "Remember to add the ads.txt lines",
        "sendMessage": "Once the necessary lines are added to your ads.txt, the monetization will start as soon as the bidder whitelists your domains.",
        "sendTitle": "The task has been assigned and your connection is pending"
      },
      "title": "Finalize your seat connection {name}"
    },
    "publishersOnly": "Publishers",
    "rejectedByBidder": "Rejected by bidder",
    "successAccept": "{name} has been connected with success.",
    "successDecline": "{name} has been rejected with success.",
    "websitesWhitelisted": "{0} of your websites whitelisted",
    "websitesWhitelistedRatio": "~ {0}% inventory whitelisted",
    "whitelistingAuto": "Whitelisting auto"
  },
  "directSeatCreation": {
    "adapterSelection": {
      "description": "Pick a bidder to move to the next step",
      "title": "Choose the bidder of this seat ?"
    },
    "discard": {
      "message": "You’ll lose all the information that you’ve filled in.",
      "title": "Do you want to discard the seat creation?"
    },
    "seatCommissions": {
      "description": "These fields are mandatory and are used for reporting purposes to estimates the share of revenue captured by the different stakeholders on this seat.",
      "title": "How much is the commission?"
    },
    "seatConfigurations": {
      "description": "Following field(s) are mandatory and are used to call the bidder’s services using the proper means of identification they have provided.",
      "title": "What are the IDs that were provided by {bidderName}?"
    },
    "seatName": {
      "description": "This field is mandatory and is used to distinguish this seat from others.",
      "title": "What name should we give to this seat?"
    }
  },
  "filters": {
    "addMore": "Add more filters",
    "cancel": "Click on cancel to return filters",
    "clearAll": "Clear all filters",
    "conditions": {
      "and": "And",
      "or": "Or",
      "where": "Where"
    },
    "confirmRemove": "Click on apply to confirm the removal of ({0}) filters.",
    "getStarted": "Get started by adding a new filter.",
    "noFilters": "No filters",
    "operators": {
      "arrayAny": "any",
      "between": "between",
      "contains": "contains",
      "endsWith": "end with",
      "equals": "equals",
      "excludes": "excludes",
      "greaterThan": "greater than",
      "greaterThanEquals": "greater than equals",
      "in": "in",
      "isFalse": "is false",
      "isNull": "is null",
      "isTrue": "is true",
      "lowerThan": "lower than",
      "lowerThanEquals": "lower than equals",
      "notEquals": "not equals",
      "notIn": "not in",
      "notNull": "not null",
      "startsWith": "starts with"
    },
    "searchPanel": {
      "placeholder": "Type to search",
      "submitSearch": "Submit search"
    },
    "selectDate": "Select a date",
    "selectValue": "Select a value",
    "value": "Value"
  },
  "floorRules": {
    "create": "@:actions.new Floor",
    "createSuccess": "The floor rule has been successfully created.",
    "edit": "{name}",
    "editConfirmModalText": "Disabling floor rules impacts your monetisation. You're responsible to ensure {strongText01} is active otherwise your inventory {strongText02}.",
    "editConfirmModalTextStrong01": "at least one such rule",
    "editConfirmModalTextStrong02": "won't be monetised",
    "editConfirmModalTitle": "Monetization at risk",
    "editSuccess": "The floor rule has been updated successfully."
  },
  "groups": {
    "create": "@:actions.create a group",
    "createSuccess": "The group has been successfully created.",
    "edit": "{name}"
  },
  "invitations": {
    "accepted": "Accepted",
    "confirmDelete": "Are you sure you want to delete the {email} invitation?",
    "copy": "@.capitalize:app.copy invitation link",
    "copyError": "Unable to copy the invitation link",
    "copySuccess": "The invitation link has been successfully copied to your clipboard",
    "copyTitle": "Link copied",
    "create": "@:actions.create an invitation",
    "createError": "@:messages.createError de l'invitation",
    "createErrorLocked": "@:messages.createError invitation. This email belongs to an existing user or an invitation has already been sent.",
    "createSuccess": "We've successfully sent an invitation to your new user",
    "delete": "@:actions.delete an invitation",
    "deleted": "Deleted",
    "deleteError": "Unable to delete the invitation",
    "deleteSuccess": "The invitation has been sucessfully deleted",
    "errorExpired": "Your invitation has expired, please request another one.",
    "errorNotFound": "Your invitation is not found, please request another one.",
    "errorUsed": "The invitation token you are using has already been used.",
    "expired": "Expired",
    "havePending": "You have a pending invitation",
    "pending": "Pending",
    "resend": "@:actions.resend an invitation",
    "resendError": "Unable to sent a new invitation",
    "resendSuccess": "We've successfully sent a new invitation"
  },
  "invites": {
    "backInvitation": "@:auth.backInvitation",
    "billing": "and has enabled billing communications",
    "createAccount": "@:actions.create an account",
    "createAccountSubtitle": "On {0}",
    "createAccountTitle": "@:actions.create your account",
    "join": "Join {0}",
    "joinAs": "Join {toJoin} as {email}",
    "joinWithAccount": "Join with an existing account",
    "joinWithInvitation": "Join with an invitation",
    "signInWithAccount": "Sign in with another account",
    "summary": "{firstname} {lastname} ({email}) has invited you to join the {name} {context} organization (#{id}) as {role} {billing}"
  },
  "invoiceRequest": {
    "addAdjustment": "Add adjustment",
    "adjustmentConfirmationMessage": {
      "main": "Your adjustment will apply to this invoice. If any invoice has been uploaded, it will be kept but may not be relevant anymore. {strong}",
      "strong": "You need to manually re-send the IR for all adjusted billing periods if it has already been sent."
    },
    "adjustmentDeletionMessage": {
      "main": "This adjustment will be entirely removed from the invoice. If any invoice has already been uploaded it will be kept but may not be relevant anymore. {strong}",
      "strong": "You’re responsible to manually resend an invoice request if relevant."
    },
    "adjustmentMessage": "Adjusting the billing elements will notify publishers of an update of their invoice request and this invoice will return to “awaiting invoice”. It needs to be smaller than the invoice’s total as to not make the total < 0.",
    "awaiting": "Awaiting invoice",
    "billingDisabled": "Billing disabled",
    "confirmAdjustment": "Confirm adjustment \"{name}\"",
    "createFailed": "Failed to create adjustment.",
    "createSuccess": "The adjustment has been successfully created.",
    "deleteFailed": "Failed to delete adjustment.",
    "deleteSuccess": "The adjustment has been successfully deleted.",
    "draft": "Draft",
    "editAdjustment": "Edit adjustment",
    "editFailed": "Failed to edit adjustment.",
    "editSuccess": "The adjustment has been successfully updated.",
    "error": "Error",
    "errorBankingOrder": "Error banking order",
    "errorNoRecipient": "Error no recipient",
    "errorPaymentReceival": "Error payment receival",
    "export": {
      "button": "Export",
      "error": "Failed to generate the export"
    },
    "noRevenue": "No revenue",
    "paid": "Paid",
    "paymentScheduled": "Payment scheduled",
    "processing": "Processing invoice",
    "removeAdjustment": "Remove adjustment \"{name}\"",
    "validated": "Invoice validated"
  },
  "invoices": {
    "actions": {
      "approve": "@.capitalize:app.approve invoice",
      "reject": "@.capitalize:app.reject invoice",
      "resendInvoiceEmail": "Re-send IR Email",
      "resendInvoiceEmailSubtitle": "This will resend an up to date Invoice request email to all billing recipients. Are you sure?",
      "resolveError": "Error was resolved",
      "setAsStatus": "Set as \"{ status }\"",
      "signalError": "Signal an error with { error }"
    },
    "alert": {
      "awaiting": "You’ll find below all billing elements, please do upload the corresponding invoice to the manager",
      "draft": "(Service Only) This invoice is hidden to publishers and only relevant for service accounts to check it’s content. Invoice will be finalised at the end of the billing period on the 3rd of the month following the billing period.",
      "errBankingOrder": "Error: Our banking partner notified us that the wiring transfer failed",
      "errNoRecipient": "Error: No user with billing permission has been found in your account",
      "error": "We’ve experienced an issue with this invoice, our team will come back to you shortly by email.",
      "errPaymentReceival": "Error: It seems that our banking partner found issues with your banking details",
      "noAutoBilling": "(Service Only) This automatic billing has been disabled for that organisation, please contact the tech team if this needs to be updated. You need to manually handle billing for that publisher.  ",
      "noRevenue": "Your account hasn’t generated any earning during this billing period",
      "paid": "This payment for this invoice has been sent, it usually takes up to 5 business days to show up in your account",
      "paymentScheduled": "The estimated payment date has been updated and the wire transfer has been transmitted to our banking partner",
      "processing": "(Service Only) Publishers see this step as “Payment scheduled”, this status is only relevant for service accounts",
      "validated": "(Service Only) Publishers see this step as “Payment scheduled”, this status is only relevant for service accounts. Please update the state once the wiring order has been sent to our banking partners"
    },
    "bulk": {
      "confirmText": "Only invoices marked as \"{ requiredStatus }\" can be set as { status } given agreed upon invoices lifecycle. You will only perform this action for { length } out of { total } invoices ({ percent } %)",
      "confirmTitle": "Only a subset of rows can be set as \"{ status }\"",
      "elligibleRow": "{0} row elligible | {0} rows elligible",
      "setAsStatus": "Set as \"{ status }\" ({ rows })"
    },
    "datatable": {
      "btnExport": "Export invoice request",
      "footerTotal": "Total",
      "revenuesSubtitle": "These details are computed in UTC (Paris) with conversion rate {currencyDetail}",
      "revenuesTitle": "Invoicing details"
    },
    "edit": "Invoice for {period}",
    "fetchTitle": "Fetching Invoice",
    "lastUpdate": "@.capitalize:app.lastUpdate",
    "placeholderETA": "Set on invoice upload",
    "resendInvoiceEmail": "Sending email",
    "resendInvoiceEmailError": "An unexpected error has occured",
    "resendInvoiceEmailSuccess": "The email has been successfully sent",
    "status": "@.capitalize:app.status",
    "updateStatus": "Update status",
    "updateStatusError": "An error occured during status change",
    "uploader": {
      "addYourInvoice": "Add your invoice",
      "dropFile": "Drop your file",
      "emptyDropZone": "Drop your .pdf invoice file (<1MB) <br>or click here to select from your computer",
      "fileMetadata": "({size}, uploaded on {uploadedAt})",
      "fileTypeError": "File type application/pdf expected",
      "fileUploaded": "The file has been successfully uploaded",
      "processing": "Processing",
      "tapToDownload": "Tap to download the invoice",
      "uploadError": "An error happend during the invoice upload: {error}",
      "uploadErrorTooLarge": "Please upload a pdf of your invoice that is smaller than 1MB",
      "uploadErrorTooLargeTitle": "File too large",
      "uploadInProgress": "Upload in progress..."
    }
  },
  "labels": {
    "abortedAt": "@.capitalize:app.abortedAt",
    "accountid": "accountid",
    "accountId": "@.capitalize:app.accountId",
    "action": "@.capitalize:app.action",
    "actionRequired": "Action required",
    "active": "Active",
    "adagio": "Adagio",
    "adapter": "@.capitalize:app.adapter",
    "adapterCode": "@.capitalize:app.adapterCode",
    "adapterId": "Adapter ID",
    "adapterName": "@.capitalize:app.adapterName",
    "address": "@.capitalize:app.address",
    "addSort": "Add a sort",
    "adhoc": "@.capitalize:app.adhoc",
    "adjustment": "@.capitalize:app.adjustement",
    "adjustmentAmountsHint": "Enter the amount in cents. It will be added to the net earnings.",
    "adrequest": "@.capitalize:app.adrequest",
    "adstxt": "@.capitalize:app.adstxt",
    "adstxt11": "@.capitalize:app.adstxt11",
    "adstxtFirstCheck": "Ads.txt Line First Check",
    "adstxtLastCheck": "Ads.txt Line Last Check",
    "adstxtLine": "@.capitalize:app.adstxtLine",
    "adstxtLinesTemplate": "Ads.txt line template",
    "adstxtLineType": "@.capitalize:app.adstxtLineType",
    "adstxtOptionsFetchBypass": "Monetize even if ads.txt could not be found",
    "adstxtOptionsForceHttps": "Ads.txt Force Https",
    "adstxtOptionsUseSubdomain": "Ads.txt Subdomain",
    "adstxtStatus": "Ads.txt Status",
    "adstxtStatusCheck": "Ads.txt Line Status",
    "adstxtStatusErrorCode": "Ads.txt Error Code",
    "adstxtStatusErrorMessage": "Ads.txt Error Message",
    "adstxtStatusLastDateCheck": "Ads.txt Last Crawl",
    "adstxtStatusLastDateOk": "Ads.txt Last Ok",
    "adstxtStatusWarnings": "Ads.txt Warnings",
    "adunit": "@.capitalize:app.adunit",
    "adunitcode": "AdUnit Code | AdUnit Codes",
    "advertiserDomain": "@.capitalize:app.advertiserDomain",
    "aggregatedStatus": "Aggregated Status",
    "algorithm": "Algorithm",
    "alias": "@.capitalize:app.alias",
    "all": "All",
    "allowAutomaticSeatBooster": "Allow automatic seat booster",
    "allSharedSeats": "All shared seats",
    "amountInCurrency": "Amount in {0} (≥ {1})",
    "apiKey": "@.capitalize:app.apiKey",
    "applyToAllInColumn": "Click on this button to fill all the cells from the same column with this value.",
    "approval": "@.capitalize:app.approval",
    "approved": "@.capitalize:app.approved",
    "appSettings": "Application settings",
    "archived": "Archived",
    "asc": "Ascending",
    "auctionStep": "@.capitalize:app.auctionStep",
    "autoBillingOff": "@.capitalize:app.autoBillingOff",
    "autoMapping": "@.capitalize:app.autoMapping",
    "autoWhitelistedAt": "Auto-whitelisted at",
    "autoWhitelisting": "Auto-whitelisting",
    "awaiting": "@.capitalize:app.awaiting",
    "backPreviousStep": "Back to the previous step",
    "baseDimension": "Base dimension | Base dimensions",
    "bidability": "Bidability",
    "bidAdjustement": "@.capitalize:app.bidAdjustement",
    "bidadjustment": "Bid adjustement",
    "bidAllowed": "@.capitalize:app.bidAllowed",
    "bidder": "@.capitalize:app.bidder",
    "bidder_prioritized": "@:labels.bidderPrioritized",
    "bidderCategory": "@.capitalize:app.bidderCategory",
    "bidderCode": "@.capitalize:app.bidderCode",
    "bidderGroup": "@.capitalize:app.bidderGroup",
    "bidderId": "@.capitalize:app.bidderId",
    "bidderLine": "@.capitalize:app.bidderLine",
    "bidderLinesStatus": "@.capitalize:app.bidderLinesStatus",
    "bidderName": "@.capitalize:app.bidderName",
    "bidderOptinStatus": "@.capitalize:app.bidderOptinStatus",
    "bidderPrioritized": "@.capitalize:app.bidderPrioritized",
    "bidders": "Bidders",
    "bidType": "@.capitalize:app.bidType",
    "billing": "@.capitalize:app.billing",
    "billingContactEmail": "Billing Contact Email",
    "billingContactName": "Billing Contact Name",
    "billingCountry": "@.capitalize:app.billingCountry",
    "billingCurrency": "@.capitalize:app.billingCurrency",
    "billingEmailCommunication": "@.capitalize:app.billingEmailCommunication",
    "billingPeriod": "@.capitalize:app.billingPeriod",
    "blacklist": "Blackist",
    "blocked": "@.capitalize:app.blocked",
    "blocklist": "@.capitalize:app.blocklist",
    "boostStatus": "Boost status",
    "bulkEdit": "Bulk edit",
    "bypassAdstxt": "Bypass ads.txt",
    "bypassed": "Bypassed",
    "casesNumber": "@.capitalize:app.casesNumber",
    "category": "@.capitalize:app.category",
    "certAuthId": "@.capitalize:app.certAuthId",
    "checkRule": "Check rule",
    "city": "@.capitalize:app.city",
    "comingSoon": "Coming soon",
    "commission": "@.capitalize:app.commission",
    "compareGroups": "@.capitalize:app.compareGroups",
    "comparison": "Comparison",
    "comparison-period": "Comparison period",
    "comparisonDisabled": "Comparison disabled",
    "comparisonPeriodOverride": "Comparison period override",
    "competitionSampling": "@.capitalize:app.competitionSampling",
    "configuration": "@.capitalize:app.configuration",
    "confirmation": "Confirmation",
    "confirmPassword": "Confirm Password",
    "connect": "@.capitalize:app.connect",
    "connected": "Connected",
    "contactSupport": "Contact support",
    "content": "@.capitalize:app.content",
    "copy": "@.capitalize:app.copy",
    "country": "@.capitalize:app.country",
    "countryCode": "@.capitalize:app.countryCode",
    "cpm": "CPM",
    "createdAt": "Created at",
    "creativeAttributes": "Creative Attributes",
    "currency": "@.capitalize:app.currency",
    "currencyRate": "@.capitalize:app.currencyRate",
    "custom": "Custom",
    "dashboard": "@.capitalize:app.dashboard",
    "dashboardType": "Dashboard type",
    "dataDimensions": "Data dimensions",
    "date-range": "Date range",
    "dateRange": "@.capitalize:app.dateRange",
    "dayOfExport": "@.capitalize:app.dayOfExport",
    "dealCommission": "@.capitalize:app.dealCommission",
    "dealCommissionFee": "@.capitalize:app.dealCommissionFee",
    "dealRPB": "@.capitalize:app.dealRPB",
    "dealsCommission": "@.capitalize:app.dealsCommission",
    "declaredMonthlyMillionsAdrequests": "Declared monthly ad-requests (Millions)",
    "declaredMonthlyRevenueTarget": "Declared monthly revenue target",
    "declaredNumberOfWebsites": "Declared number of websites",
    "default": "@.capitalize:app.default",
    "demand": "@.capitalize:app.demand",
    "demandLibrary": "@.capitalize:app.demandLibrary",
    "desc": "Descending",
    "description": "@.capitalize:app.description",
    "desktopMode": "@.capitalize:states.desktopMode",
    "device": "@.capitalize:app.device",
    "dfp": "DFP",
    "dfpEnabled": "@.capitalize:app.dfpEnabled",
    "dfpIncreaseFactor": "@.capitalize:app.dfpIncreaseFactor",
    "dfpSampling": "@.capitalize:app.dfpSampling",
    "dimension": "@.capitalize:app.dimension",
    "direct": "Direct",
    "directLink": "Direct link",
    "disabled": "@.capitalize:app.disabled",
    "disconnected": "Disconnected",
    "discrepanciesBidAdjustment": "@.capitalize:app.discrepanciesBidAdjustment",
    "divid": "Div ID | Div IDs",
    "domain": "Domain | Domains",
    "draft": "@.capitalize:app.draft",
    "dry_run": "@:labels.dryRun",
    "dryRun": "@.capitalize:app.dryRun",
    "durationPerGroup": "Days per group",
    "dynamicfee": "Dynamic fee",
    "dynamicfloorprice": "Dynamic floor price",
    "dynamicFloorPrices": "Dynamic floor prices",
    "earning": "@.capitalize:app.earning",
    "edit": "@.capitalize:app.edit",
    "element": "@.capitalize:app.element",
    "email": "@.capitalize:app.email",
    "enabled": "@.capitalize:app.enabled",
    "endDate": "@.capitalize:app.endDate",
    "endpoint": "@.capitalize:app.endpoint",
    "enrichment": "@.capitalize:app.enrichment",
    "enrichmentTable": "Enrichment table | Enrichment tables",
    "environment": "@.capitalize:app.environment",
    "error": "@.capitalize:app.error",
    "errorRetry": "An error has occurred. Please try again.",
    "estimatedCompletedAt": "Estimated completed at",
    "expireAt": "Expire at",
    "export": "@.capitalize:app.export",
    "extraActions": "Additionnal actions",
    "extract": "@.capitalize:app.extract",
    "factor": "@.capitalize:app.factor",
    "false": "False",
    "fee": "@.capitalize:app.fee",
    "fee_application": "@:labels.feeApplication",
    "feeApplication": "Fee application",
    "filter": "@.capitalize:app.filter",
    "finished": "Finished",
    "first": "First",
    "firstBidrequestAt": "First bidrequest at",
    "firstBidresponseAt": "First bidresponse at",
    "firstCheck": "First check",
    "firstname": "@.capitalize:app.firstname",
    "fixRecommended": "Fix recommended",
    "fixRecommended11": "Fix recommended for Ads.txt 1.1",
    "floor": "@.capitalize:app.floor",
    "floorRule": "Floor | Floors",
    "foreignDimension": "Foreign dimension | Foreign dimensions",
    "foreignKey": "Foreign key | Foreign keys",
    "form": "@.capitalize:app.form",
    "formatter": "@.capitalize:app.formatter",
    "formula": "Formula",
    "general": "@.capitalize:app.general",
    "generalInfo": "General info",
    "generalInformation": "General information",
    "generalSettings": "General settings",
    "goBackHome": "Go back home",
    "gpid": "GPID | GPIDs",
    "granularity": "Granularity",
    "gross": "@.capitalize:app.gross",
    "grossRevenue": "@.capitalize:app.grossRevenue",
    "group": "@.capitalize:app.group",
    "groups_order": "@:labels.groupsOrder",
    "groupsOrder": "@.capitalize:app.groupsOrder",
    "groupType": "Group type",
    "hideAll": "Hide all",
    "home": "@.capitalize:app.home",
    "iabContentCategories": "IAB Categories",
    "id": "ID",
    "inactive": "Inactive",
    "inactiveReason": "Inactive reason",
    "inBlocklists": "@.capitalize:app.inBlocklists",
    "increase_factor": "@:labels.increaseFactor",
    "increaseFactor": "@.capitalize:app.increaseFactor",
    "info": "@.capitalize:app.info",
    "information": "Information",
    "insight": "@.capitalize:app.insight",
    "instantReport": "@.capitalize:app.instantReport",
    "invalidDomains": "Invalid domains",
    "inventory": "@.capitalize:app.inventory",
    "inventoryMediatypes": "Mediatypes",
    "inventoryRegions": "Main audience's regions",
    "invitation": "@.capitalize:app.invitation",
    "invoice": "@.capitalize:app.invoice",
    "invoiceCurrency": "Invoice Currency",
    "isActive": "Active",
    "isForGroup": "For Group",
    "isInactive": "Inactive",
    "isOwnedAndOperated": "Owned and Operated",
    "isPublic": "Public",
    "isService": "Service",
    "ivt": "IVT",
    "ivtBidAdjustment": "@.upper:app.ivtBidAdjustment",
    "jobTitle": "@.capitalize:app.jobTitle",
    "keyword": "@.capitalize:app.keyword",
    "language": "@.capitalize:app.language",
    "lastCheck": "Last check",
    "lastname": "@.capitalize:app.lastname",
    "lastStep": "@.capitalize:app.lastStep",
    "line": "@.capitalize:app.line",
    "lineAccountId": "Line Account ID",
    "lineCertificateId": "Line Certificate ID",
    "lineDomain": "Line Domain",
    "lineId": "Line ID",
    "lineRelationship": "Line Relationship",
    "lineType": "Line Type",
    "list": "@.capitalize:app.list",
    "liveAt": "Live At",
    "loading": "Loading...",
    "location": "@.capitalize:app.location",
    "mainInfos": "Main infos",
    "mainRegions": "Main regions (>20% inventory)",
    "management": "@.capitalize:app.management",
    "manager": "@.capitalize:app.manager",
    "managerAccountId": "@.capitalize:app.managerAccountId",
    "managerCertAuthId": "@.capitalize:app.managerCertAuthId",
    "managerDomain": "@.capitalize:app.managerDomain",
    "managerLine": "@.capitalize:app.managerLine",
    "managerProperty": "@.capitalize:app.managerProperty",
    "managerStatus": "Manager 1.1",
    "manualCheck": "Manual check",
    "mappingRule": "Mapping | Mappings",
    "max": "@.capitalize:app.max",
    "maxDaysRange": "Max. days range",
    "maxDuplication": "Max. duplication",
    "maxRefresh": "Max. refresh",
    "medias": "@.capitalize:app.medias",
    "mediatype": "@.capitalize:app.mediatype",
    "metric": "@.capitalize:app.metric",
    "min": "@.capitalize:app.min",
    "min_auc_roc": "@:labels.minAUCROC",
    "minAUCROC": "@.capitalize:app.minAUCROC",
    "mobileMode": "@.capitalize:states.mobileMode",
    "month": "@.capitalize:app.month",
    "month-before": "Month before",
    "monthlyAdcalls": "Monthly adcalls",
    "monthlyMillionsAdRequests": "AdRequests (Millions/Month)",
    "name": "@.capitalize:app.name",
    "net": "@.capitalize:app.net",
    "netEarning": "@.capitalize:app.netEarning",
    "netFloorPrice": "@.capitalize:app.netFloorPrice",
    "new": "New",
    "next": "Next",
    "nextStep": "@.capitalize:app.nextStep",
    "no": "No",
    "noGDPR": "No GDPR",
    "none": "None",
    "noOverride": "No override",
    "noRevenue": "@.capitalize:app.noRevenue",
    "notApplicable": "N/A",
    "notConnected": "Not connected",
    "notCrawled": "Not crawled",
    "offset": "Sampling offset",
    "ongoing": "Ongoing",
    "openCommission": "@.capitalize:app.openCommission",
    "openCommissionFee": "@.capitalize:app.openCommissionFee",
    "openRPB": "@.capitalize:app.openRPB",
    "optimization": "@.capitalize:app.optimization",
    "optimizationType": "Optimization type",
    "optimRule": "@.capitalize:app.optimRule",
    "optimRuleCase": "Case | Cases",
    "optimRuleObjective": "Objective of this rule",
    "optin": "@.capitalize:app.optin",
    "organizationId": "@.capitalize:app.organizationId",
    "organizationName": "@.capitalize:app.organizationName",
    "other": "Other",
    "otherDashboards": "Other dashboards…",
    "owner": "@.capitalize:app.owner",
    "ownerAccountId": "@.capitalize:app.ownerAccountId",
    "ownerCertAuthId": "@.capitalize:app.ownerCertAuthId",
    "ownerDomain": "@.capitalize:app.ownerDomain",
    "ownerProperty": "@.capitalize:app.ownerProperty",
    "ownerStatus": "Owner 1.1",
    "package": "@.capitalize:app.package",
    "pageNotFound": "Page not found",
    "pageNotFoundNotice": "Please check the URL in the address bar and try again.",
    "pagetype": "@.capitalize:app.pagetype",
    "paid": "@.capitalize:app.paid",
    "parallelization": "Parallelization",
    "param": "@.capitalize:app.param",
    "parameter": "@.capitalize:app.parameter",
    "password": "@.capitalize:app.password",
    "pausedAt": "@.capitalize:app.pausedAt",
    "pauseDuration": "Pause duration",
    "payment": "@.capitalize:app.payment",
    "paymentETA": "@.capitalize:app.paymentETA",
    "paymentScheduled": "@.capitalize:app.paymentScheduled",
    "pbsCommissionFee": "@.capitalize:app.pbsCommissionFee",
    "pending": "Pending",
    "perc_revenue": "@:labels.percRevenue",
    "percRevenue": "@.capitalize:app.percRevenue",
    "period": "@.capitalize:app.period",
    "periodOverride": "Period override",
    "permission": "@.capitalize:app.permission",
    "phone": "Phone",
    "placement": "@.capitalize:app.placement",
    "presentation": "Presentation",
    "preset": "@.capitalize:app.preset",
    "preview": "@.capitalize:app.preview",
    "previous": "Previous",
    "previousStep": "@.capitalize:app.previousStep",
    "priority": "@.capitalize:app.priority",
    "priorityHint": "Used when two rules have the same targeting and need to determine priority. P2 has higher priority than P1. If this doesn't resolve the tie, the rules' ID will be used to prioritize.",
    "processedDimensions": "Processed dimensions",
    "processingInvoice": "@.capitalize:app.processingInvoice",
    "property": "@.capitalize:app.property",
    "public": "Public",
    "publisher": "@.capitalize:app.publisher",
    "publisherAccountId": "@.capitalize:app.publisherAccountId",
    "publisherGroup": "@.capitalize:app.publisherGroup",
    "publisherId": "@.capitalize:app.publisher ID",
    "publisherName": "@.capitalize:app.publisherName",
    "publisherOptinStatus": "@.capitalize:app.publisherOptinStatus",
    "publisherRelationship": "Publisher Relationship",
    "publisherSellerType": "Publisher Seller Type",
    "publisherStatus": "Publisher Status",
    "ready": "Ready",
    "recipient": "@.capitalize:app.recipient",
    "reducer": "@.capitalize:app.reducer",
    "refresh": "@.capitalize:app.refresh",
    "refreshDimensions": "Refresh dimensions",
    "refreshRule": "@.capitalize:app.refreshRule",
    "region": "@.capitalize:app.region",
    "reject": "@.capitalize:app.reject",
    "rejected": "@.capitalize:app.rejected",
    "relationship": "@.capitalize:app.relationship",
    "repackaging": "@.capitalize:app.repackaging",
    "repackagingName": "Repackaging",
    "report": "@.capitalize:app.report",
    "requestedApproval": "Requested approval",
    "required": "This field is required.",
    "requiredBuyerUID": "Required Buyer UID",
    "requiredFilters": "Required filters",
    "resellerLines": "Reseller lines",
    "role": "@.capitalize:app.role",
    "row": "@.capitalize:app.row",
    "rule": "@.capitalize:app.rule",
    "salesUser": "Sales User",
    "sampling": "Sampling",
    "scheduled": "@.capitalize:app.scheduled",
    "scheduledReport": "@.capitalize:app.scheduledReport",
    "scheduling": "Scheduling",
    "scoAdagioLine": "SCO Adagio Line",
    "scoErrorCode": "SCO Code",
    "scoErrorMessage": "SCO Message",
    "scoForceHttps": "SCO Force HTTPS",
    "scoLastDateAdagioLineOk": "SCO Last Adagio Line OK",
    "scoLastDateAdstxtOk": "SCO Last OK",
    "scoLastDateCheck": "SCO Last Check",
    "scoLastDatePublisherAccountLineOk": "SCO Last Account Line OK",
    "scoPublisherAccountIdLine": "SCO Account ID Line",
    "scoPublisherAccountIds": "SCO Account IDs",
    "scoSellerEntry": "SCO Seller ID Entry",
    "scoSellerIds": "SCO Seller IDs",
    "scoUseSubdomain": "SCO Subdomain",
    "scoWarnings": "SCO Warnings",
    "seat": "@.capitalize:app.seat",
    "seatConfigurationTemplate": "Seat configuration template",
    "seatId": "Seat ID",
    "seatName": "@.capitalize:app.seatName",
    "seatsBoosterRule": "@.capitalize:app.seatsBoosterRule",
    "seatType": "@.capitalize:app.seatType",
    "segment": "@.capitalize:app.segment of UserIDs",
    "selectedRows": "{count} @:app.row selected",
    "selectEmptyValue": "-- select --",
    "sellerDomain": "Seller domain",
    "sellersjson": "@.capitalize:app.sellersjson",
    "sellersjsonDomain": "@.capitalize:app.sellersjsonDomain",
    "sellersjsonFirstCheck": "Sellers.json First Check",
    "sellersjsonLastCheck": "Sellers.json Last Check",
    "sellersjsonManager": "Manager sellers.json",
    "sellersjsonOwner": "Owner sellers.json",
    "sellersjsonSellerType": "@.capitalize:app.sellersjsonSellerType",
    "sellersjsonStatus": "Sellers.json Status",
    "sellersjsonStatusCheck": "Sellers.json Entry Status",
    "sellersjsonStatusErrorCode": "Sellers.json Error Code",
    "sellersjsonStatusErrorMessage": "Sellers.json Error Message",
    "sellersjsonStatusLastDateCheck": "Sellers.json Last Crawl",
    "sellersjsonStatusLastDateOk": "Sellers.json Last Ok",
    "sellertype": "@.capitalize:app.sellertype",
    "sellerType": "@.capitalize:app.sellerType",
    "sendAutoBillingInvoiceEmails": "Automatically Send Invoice Requests",
    "separator": "@.capitalize:app.separator",
    "serviceGroup": "@.capitalize:app.serviceGroup",
    "serviceOnly": "Service only",
    "setting": "@.capitalize:app.setting",
    "shaping": "Shaping",
    "shapingRule": "@.capitalize:app.shaping",
    "shapingType": "@.capitalize:app.shapingType",
    "shared": "Shared",
    "showAll": "Show all",
    "siteid": "SiteId",
    "size": "@.capitalize:app.size",
    "slug": "Slug",
    "sort": "@.capitalize:app.sort",
    "source": "@.capitalize:app.source",
    "splitRule": "Split | Splits",
    "splitRuleCase": "Case | Cases",
    "splitRuleCaseSeat": "@.capitalize:app.seat",
    "splitRuleObjective": "Objective of this rule",
    "startDate": "@.capitalize:app.startDate",
    "startedAt": "@.capitalize:app.startedAt",
    "state": "State",
    "status": "@.capitalize:app.status",
    "status10": "Status 1.0",
    "status11": "Status 1.1",
    "statusPriority": "Status priority",
    "statusUpdatedAt": "Status updated at",
    "store": "@.capitalize:app.store",
    "subcategory": "@.capitalize:app.subcategory",
    "subjectToVAT": "Subject to V.A.T.",
    "submissionDate": "@.capitalize:app.submissionDate",
    "success": "@.capitalize:app.success",
    "successManagerUser": "Success Manager User",
    "supplyChain": "@.capitalize:app.supplyChain",
    "supplyChainObjectParams": "@.capitalize:app.supplyChainObjectParams",
    "supplyChainValidation": "Supply chain validation",
    "supportedEidsProviders": "Supported EIDS providers",
    "sync_count": "@:labels.syncCount",
    "syncCount": "@.capitalize:app.syncCount",
    "table": "@.capitalize:app.table",
    "tag": "@.capitalize:app.tag",
    "tagid": "TagId",
    "tagTemplate": "@.capitalize:app.tagTemplate",
    "targetable": "@.capitalize:app.targetable",
    "targeting": "@.capitalize:app.targeting",
    "tax": "@.capitalize:app.tax",
    "taxedRevenue": "@.capitalize:app.taxedRevenue",
    "template": "@.capitalize:app.template",
    "throttle_delay": "@:labels.throttleDelay",
    "throttleDelay": "@.capitalize:app.throttleDelay",
    "timeToRefresh": "Refresh time",
    "timezone": "@.capitalize:app.timezone",
    "title": "@.capitalize:app.title",
    "token": "@.capitalize:app.token",
    "tokenUrl": "@.capitalize:app.tokenUrl",
    "trafficShapingType": "@.capitalize:app.trafficShapingType",
    "trafficShapingValue": "@.capitalize:app.trafficShapingValue",
    "trafficShare": "@.capitalize:app.trafficShare",
    "troubleshooting": "@.capitalize:app.troubleshooting",
    "true": "True",
    "type": "@.capitalize:app.type",
    "uid": "@:app.UID",
    "uid_max_age": "@:labels.uidMaxAge",
    "uidMaxAge": "@.capitalize:app.uidMaxAge",
    "unknown": "@.capitalize:app.unknown",
    "unknownError": "An unknown error has occurred.",
    "unwhitelisted": "@.capitalize:app.unwhitelisted",
    "updatedAt": "@.capitalize:app.updatedAt",
    "user": "@.capitalize:app.user",
    "usersync": "@.capitalize:app.usersync",
    "valid": "Valid",
    "validatedInvoice": "@.capitalize:app.validatedInvoice",
    "validation": "Validation",
    "validationError": "Validation error",
    "validDomains": "Valid domains",
    "value": "Value",
    "variable": "@.capitalize:app.variable",
    "variant": "@.capitalize:app.variant {0}",
    "vat": "V.A.T identifier",
    "vendorListId": "Vendor list ID",
    "verifiedAt": "Verified at",
    "version": "@.capitalize:app.version",
    "visualizationType": "Visualization type",
    "waitingApproval": "Waiting approval",
    "warning": "@.capitalize:app.warning",
    "website": "@.capitalize:app.website",
    "websiteDomain": "Website domain",
    "websiteErrorCount": "Errors",
    "websiteId": "Website ID",
    "websiteName": "Website name",
    "websiteStatus": "Website 1.1",
    "websiteWarningCount": "Warnings",
    "weight": "@.capitalize:app.weight",
    "weightType": "@.capitalize:app.weightType",
    "whitelist": "@.capitalize:app.whitelist",
    "whitelisted": "Whitelisted",
    "whitelisting": "@.capitalize:app.whitelisting",
    "whitelistStatus": "Whitelist status",
    "widget": "@.capitalize:app.widget",
    "winningAdvertiser": "@.capitalize:app.winningAdvertiser",
    "winningAdvertiserDomain": "@.capitalize:app.winningAdvertiserDomain",
    "yes": "Yes",
    "zipCode": "@.capitalize:app.zipCode",
    "zoneid": "ZoneId"
  },
  "mappingRules": {
    "adapterVersion": "Adapter version",
    "apiKey": "@.capitalize:app.apiKey",
    "bidder": "@.capitalize:app.bidder",
    "blocklist": "@.capitalize:app.blocklist",
    "create": "Create mapping(s) | Create a mapping rule | Create {count} mapping(s)",
    "created": "New mappings created",
    "createMono": "Create mono mappings | Create {count} mono mappings",
    "createSuccess": "The mapping rule has been successfully created. | {count} new mappings have been added to your organisation.",
    "creativeAttributes": "Creative Attributes",
    "dashboard": "@.capitalize:app.dashboard",
    "disableTargetingConfirm": {
      "main": "You will lose the targeting parameters that you have already filled in, {strong} and not through automatic mapping detection.",
      "strong": "this mapping will only be usable in a split case"
    },
    "discard": "Discard mapping(s)",
    "discardMappingRules": {
      "main": "You will {strong} that you have already filled in. Are you sure?",
      "strong": "lose the mapping rules"
    },
    "discardTags": {
      "main": "You will {strong} that you have already filled in. Are you sure?",
      "strong": "lose the package tags"
    },
    "downloadBulkTemplate": "Download template",
    "edit": "{name}",
    "editSuccess": "The mapping rule has been successfully updated.",
    "floor": "@.capitalize:app.floor",
    "general": "@.capitalize:app.general",
    "inventory": "@.capitalize:app.inventory",
    "mappingIDs": "Mapping IDs",
    "mappingRule": "@.capitalize:app.mappingRule",
    "packageName": "{0} @.capitalize:app.package",
    "seat": "@.capitalize:app.seat",
    "tagCombinationUnique": "({0}) must be unique for each mapping",
    "templateHeader": "You can import multiple mappings at once using our template CSV file",
    "templateLearnMore": "Learn more about the expected values of this file",
    "templateSubText": "Use the template to be more efficient. {learnMore}",
    "uploadMultiple": "Upload multiple mapping lines with .csv",
    "year-before": "Year before"
  },
  "mediatypes": {
    "ban": "Banner",
    "nat": "Native",
    "vidin": "Video Instream",
    "vidout": "Video Outstream"
  },
  "messages": {
    "addError": "Unable to add",
    "addErrorWithMsg": "@:messages.addError: {0}",
    "changesSaved": "Changes saved.",
    "confirmationInstructions": "Please type \"{prompt}\" to confirm.",
    "confirmationNotice": "Are you sure?",
    "createError": "Unable to create",
    "createErrorWithMsg": "@:messages.createError: {0}",
    "createSuccessGeneric": "Resource has been successfully created",
    "deleteConfirm": "Are you sure you want to delete this {0}?",
    "deleteError": "Unable to delete resource",
    "deleteErrorWithMsg": "Unable to delete resource: {0}",
    "deleteManyConfirm": {
      "main": "This action cannot be reverted, are you sure you want to {strong} these {count} lines?",
      "strong": "delete permanently"
    },
    "deleteSuccess": "Resource has been deleted",
    "discardConfirm": "Do you want to discard the updated settings?",
    "disconnected": "You have been disconnected. Please log in again.",
    "emptyResponse": "empty response from server",
    "fetchError": "Unable to fetch",
    "fetchErrorWithMsg": "@:{'messages.fetchError'}: {0}",
    "formCloseConfirm": {
      "main": "You'll {strong} that you've filled in.",
      "strong": "lose all the information",
      "title": "Do you want to discard?"
    },
    "genericError": "An error as occured",
    "genericErrorWithMsg": "@:messages.genericError: {0}",
    "genericSuccess": "All done!",
    "loadError": "Unable to load",
    "loadErrorWithMsg": "@:messages.loadError: {0}",
    "noData": "No data",
    "noDataFound": "Sorry no data was found for this line",
    "noRecords": "No Records Found",
    "permissionError": "You don't have access to this location.",
    "refreshError": "Unable to refresh",
    "refreshErrorWithMsg": "@:messages.refreshError: {0}",
    "resultCount": "No result | One result | {0} results",
    "saveError": "Unable to save",
    "saveErrorWithMsg": "@:messages.saveError: {0}",
    "tooManyRequests": "Too many requests",
    "tooManyRequestsMessage": "You are rate limited, please wait a few moments before retrying.",
    "updateConfirm": {
      "main": "After confirmation, updates will seamlessly {strong} and {strong2} if needed.",
      "strong": "integrate new additions",
      "strong2": "adjust existing settings",
      "title": "Ready to confirm your changes?"
    },
    "updateError": "Unable to update",
    "validationErrors": "There are one or more validation errors."
  },
  "metrics": {
    "adcalls": "Ad Calls",
    "adCalls": "Ad Calls",
    "adgAdsrv_FeesCpmCount": "ADG Adsrv Fees CPM Count",
    "adgAdsrv_FeesCpmSum": "ADG Adsrv Fees CPM Sum",
    "adgAdsrv_Imp": "ADG Adsrv Imp",
    "adgAdsrv_ImpCpmCount": "ADG Adsrv Imp CPM Count",
    "adgAdsrv_ImpCpmSum": "ADG Adsrv Imp CPM Sum",
    "adgAdsrv_SspFeesCpmCount": "ADG Adsrv SSP Fees CPM Count",
    "adgAdsrv_SspFeesCpmSum": "ADG Adsrv SSP Fees CPM Sum",
    "adgBidNetCpmSum": "ADG Bid Net CPM Sum",
    "adgBids": "ADG Bids",
    "adgExposureSum": "ADG Exposure Sum",
    "adgImpressions": "ADG Impressions",
    "adgNetRevenue": "ADG Net Revenue",
    "adgPub_FeesCpmCount": "ADG Pub Fees CPM Count",
    "adgPub_FeesCpmSum": "ADG Pub Fees CPM Sum",
    "adgPub_Imp": "ADG Pub Imp",
    "adgPub_ImpCpmCount": "ADG Pub Imp CPM Count",
    "adgPub_ImpCpmSum": "ADG Pub Imp CPM Sum",
    "adgPub_SspFeesCpmCount": "ADG Pub SSP Fees CPM Count",
    "adgPub_SspFeesCpmSum": "ADG Pub SSP Fees CPM Sum",
    "adresponseMediatype": "Ad Response Mediatype",
    "adresponses": "Ad Responses",
    "adResponses": "Ad Responses",
    "adresponsesCPM": "Ad Responses CPM",
    "adresponsesNetCpmSum": "Ad Responses Net CPM Sum",
    "adresponSize": "Ad Response Size",
    "asleepWebsitesRate": "Asleep Websites Rate",
    "auctions": "Auctions",
    "bidder": "@.capitalize:app.bidder",
    "bidGrossCPM": "Bid Gross CPM",
    "bidGrossPrice": "Bid Gross Price",
    "bidMediatype": "Bid Mediatype",
    "bidNetCPM": "Bid Net CPM",
    "bidRate": "Bid Rate",
    "bidrequestimpFound": "Bid Request Imp Found",
    "bids": "Bids",
    "bidsAboveTimeBound": "Bids Above Time Bound",
    "bidsBelowTimeBound": "Bids Below Time Bound",
    "category": "@.capitalize:app.category",
    "comparisonGroup": "Comparison groups",
    "contentPagetype": "Content Pagetype",
    "countRecipients": "Recipient count",
    "country": "Country",
    "cpm": "CPM",
    "createdWebsites": "Created Websites",
    "csmError": "CSM Error",
    "declaredAdrequests": "Declared Ad Requests",
    "declaredRevenueTarget": "Declared Revenue Target",
    "declaredWebsites": "Declared Websites",
    "device": "Device",
    "environment": "@.capitalize:app.environment",
    "expectedNetRevenue": "Expected Net Revenue",
    "expectedRpb": "Expected RPB",
    "exposureSum": "Exposure Sum",
    "fillRate": "Fill Rate",
    "grossCPM": "Gross CPM",
    "grossRevenue": "Gross Revenue",
    "impressions": "Impressions",
    "incrementality": "Incrementality",
    "incrementalRevenue": "Incremental Revenue",
    "integratedAdrequests": "Integrated Ad Requests",
    "integratedNetRevenue": "Integrated Net Revenue",
    "integrationRate": "Integration Rate",
    "inventoryMediatype": "Mediatype",
    "inventoryPlacement": "Placement",
    "inventoryPublisherId": "Publisher ID",
    "inventoryPublisherName": "Publisher Name",
    "inventoryPublisherSellersJsonReason": "Publisher Sellers.json Reason",
    "inventoryPublisherSellerType": "Publisher Seller Type",
    "inventoryWebsiteAdsTxtReason": "Website Ads Txt Reason",
    "inventoryWebsiteConfiguredDomain": "Website Configured Domain",
    "inventoryWebsiteDomain": "Website Domain",
    "inventoryWebsiteName": "Website Name",
    "inventoryWebsiteStatus": "Website Status",
    "liveWebsites": "Live Websites",
    "measurables": "Measurables",
    "measured": "Measured",
    "mediatype": "Mediatype",
    "mediatypeAdcalls": "Mediatype Ad Calls",
    "mediatypes": "Mediatypes",
    "meta": "Meta",
    "missingNetRevenue": "Missing Net Revenue",
    "moneytizationRate": "Moneytization Rate",
    "netCPM": "Net CPM",
    "netRevenue": "Net Revenue",
    "notViewed": "Not Viewed",
    "orgaLiveAt": "Publisher live at",
    "organizationId": "Organization ID",
    "pagetype": "@.capitalize:app.pagetype",
    "pbjsVersion": "pbjs Version",
    "placement": "@.capitalize:app.placement",
    "potentialNetRevenue": "Potential Net Revenue",
    "potentialRevenueForTimeBound": "Potential Revenue For Time Bound",
    "potentialWebsitesRate": "Potential Websites Rate",
    "prebidAnalytics": "Prebid Analytics",
    "predictionPassed": "Prediction Passed",
    "publisher": "@.capitalize:app.publisher",
    "pubReq": "Pub Req",
    "pubReq_Eligible": "Pub Req Eligible",
    "pubResp": "Pub Resp",
    "pubResp_CpmCount": "Pub Resp CPM Count",
    "pubResp_CpmSum": "Pub Resp CPM Sum",
    "reportingReqFound": "Reporting Request Found",
    "reportingRespFound": "Reporting Response Found",
    "reseller": "Reseller",
    "resellerRevenueShare": "Reseller revenue share",
    "rpb": "RPB",
    "ruleMatched": "Rule Matched",
    "salesUserId": "Sales User ID",
    "salesUserName": "Sales User Name",
    "seatId": "Seat ID",
    "seatType": "Seat Type",
    "site": "Site",
    "splitRuleCaseId": "Split Rule Case ID",
    "splitRuleId": "Split Rule ID",
    "sspReq": "SSP Req",
    "sspReq_FloorCpmCount": "SSP Req Floor CPM Count",
    "sspReq_FloorCpmSum": "SSP Req Floor CPM Sum",
    "sspReqDfp": "SSP Req DFP",
    "sspReqDfp_FloorCpmCount": "SSP Req DFP Floor CPM Count",
    "sspReqDfp_FloorCpmSum": "SSP Req DFP Floor CPM Sum",
    "sspResp": "SSP Resp",
    "sspResp_Compliant": "SSP Resp Compliant",
    "sspResp_CompliantCpmCount": "SSP Resp Compliant CPM Count",
    "sspResp_CompliantCpmSum": "SSP Resp Compliant CPM Sum",
    "sspResp_CpmCount": "SSP Resp CpmCount",
    "sspResp_CpmSum": "SSP Resp CpmSum",
    "sspRespDfp": "SSP Resp DFP",
    "sspRespDfp_CpmCount": "SSP Resp DFP CPM Count",
    "sspRespDfp_CpmSum": "SSP Resp DFP CPM Sum",
    "subcategory": "@.capitalize:app.subcategory",
    "successManagerUserId": "Success Manager User ID",
    "successManagerUserName": "Success Manager User Name",
    "timeBound": "Time Bound",
    "timeInView": "Time In View",
    "timeInViewSum": "Time In View Sum",
    "timeoutRate": "Timeout Rate",
    "userDevice": "User Device",
    "userEnvironment": "User Environment",
    "validAdCalls": "Valid Ad Calls",
    "viewables": "Viewables",
    "viewed": "Viewed",
    "vRate": "VRate",
    "websiteLiveAt": "Website live at",
    "windowStart": "Time",
    "winRate": "Win Rate",
    "wins": "Wins"
  },
  "notifications": {
    "errorDefaultTitle": "Error!",
    "filteredWarning": "{action}\nYou might need to remove filters to see it.",
    "infoDefaultTitle": "Information",
    "successDefaultTitle": "Success!",
    "warningDefaultTitle": "Warning!"
  },
  "optimRules": {
    "addCase": "Add a case",
    "addOptimization": "@:actions.add an optimization",
    "allSeatsHint": "By enabling, all shared seats will be added automatically.",
    "auctionStepHint": "Depending on the selection, the optimization will be called at a different moment in the supply path. Targeting and optimizations available would be different.",
    "caseDescription": "A case is a specific optimization setup (either different algorithm or different algorithm’s parameters). Add a case by clicking on “Add a case” button.",
    "clickOnAddOptimizationToConfigure": "Click on \"Add an optimization\" to configure the case.",
    "confirmRemoveCase": "Are you sure you want to remove this case?",
    "confirmRemoveMediatypeDetails": {
      "main": "You’ll lose {strong} that you’ve filled in.",
      "strong": "all the cases"
    },
    "confirmRemoveMediatypeTitle": "Are you sure you want to remove this mediatype?",
    "confirmUpdateUserID": "The optimization test you are modifying is based on user ID. Any update will change the segment selection of the test. Can you confirm that you want to continue?",
    "createSuccess": "The optimization has been successfully created.",
    "datatableMessage": "Optimizations are used to test different setup on shared seats through a specific targeting",
    "defaultNotEditableCallout": "This rule is the default for this optimization. It cannot be edited.",
    "duplicateCaseSuccess": "The case has been duplicated.",
    "duplicateConfirmText": "Are you sure you want to duplicate this optim rule? This will create a copy with a different name. The copy will be disabled by default.",
    "duplicateError": "The optim rule could not be duplicated.",
    "duplicateSuccess": "The optim rule has been duplicated successfully.",
    "edit": "{name}",
    "editSuccess": "The split rule has been successfully saved.",
    "new": "New optimization rule",
    "noOptimizationTemplateSelected": "No optimization template has been selected.",
    "optimizationsHint": "Adjust optimization parameters to find the best setup.",
    "priorityHint": "Used when two optimizations follow the same targeting to pick the one that applies, not that if this doesn’t allow to tie break between optimizations an id will be used to prioritise.",
    "splitRuleLink": "Check this split rule",
    "types": {
      "dataScience": "Data science",
      "global": "Global",
      "publisher": "@.capitalize:app.publisher"
    },
    "unsupportedMediatype": "{0} not supported",
    "unsupportedMediatypeFallback": "The engine will fall back to \"Auto\" for unsupported mediatypes.",
    "weightTypes": {
      "random": "Random",
      "userId": "User ID"
    }
  },
  "optins": {
    "edit": "{name}",
    "editSuccess": "The optin has been successfully updated."
  },
  "panels": {
    "close": "Close panel"
  },
  "passwords": {
    "backSignIn": "Back to Sign In",
    "confirmPassword": "Confirm new password",
    "copyError": "Unable to copy the reset password link",
    "copySuccess": "The reset password link has been successfully copied to your clipboard",
    "copyTitle": "Link copied",
    "currentPassword": "Current password",
    "forgotPasswordMessage": "Enter the email address associated with your account and we'll send you a link to reset your password.",
    "forgotPasswordSuccess": "A link to reset your password you sent to your mailbox.",
    "forgotPasswordTitle": "Forgot Password",
    "newPassword": "New password",
    "resetCopy": "@.capitalize:app.copy reset password link",
    "resetOtherUserPassword": "Reset password",
    "resetOtherUserPasswordError": "An error occurred when sending the password reset email to the user.",
    "resetOtherUserPasswordMessage": "Are you sure you want to reset this user's password? This will send a password reset email to {0}.",
    "resetOtherUserPasswordSentMessage": "An email to reset their password has been sent to the user.",
    "resetOtherUserPasswordSentTitle": "Email sent to {0}.",
    "resetOtherUserPasswordTitle": "Reset {0} {1}'s password",
    "resetPassword": "Reset your password",
    "resetPasswordLinkInvalid": "The link to reset your password is invalid or not found.",
    "resetPasswordMessage": "Change the password you forgot to a new password, you will be redirected to your account.",
    "resetPasswordTitle": "Reset your password",
    "resetPasswordTitleSuccess": "Your password has been updated with success.",
    "updatePassword": "Update your password"
  },
  "placeholders": {
    "nameUnique": "Unique name",
    "table": "Table name",
    "titleAutoFilled": "Leave empty for auto-title"
  },
  "profile": {
    "noPermissions": "Your account is not associated with any organization."
  },
  "publisherRelationship": {
    "managed": "Managed",
    "owned": "Owned and operated"
  },
  "publishers": {
    "adstxtLinesCopyText": "Copy the ads.txt lines",
    "create": "New Publisher",
    "createSuccess": "The publisher has been successfully created.",
    "edit": "{name}",
    "editSuccess": "The publisher has been successfully saved.",
    "modalConfirmCountryCodeMessage": {
      "main": "Changing your billing country will have an impact on your future (starting at the next billing period) invoices {strong} as per regulations.",
      "strong": "as we apply taxes only to companies that are located in France"
    },
    "modalConfirmCountryCodeTitle": "Please confirm",
    "modalConfirmDisableAutoInvoice": {
      "main": "By performing this action, {strong} (no emails, nor access to the corresponding views in the manager).",
      "strong": "you’ll exclude this publisher from the Auto Invoice Request Feature",
      "title": "Disable Auto Invoice"
    },
    "modalConfirmEnableAutoInvoice": {
      "main": "By enabling this setting, the publisher will receive an invoice request every month and be able to drop the corresponding invoice directly in the manager (Settings > Invoices).",
      "title": "Automatically Send Invoice Requests"
    },
    "optinsAdstxts": {
      "message1": "Ads.txt lines are based on the seller type and the opted-in seats",
      "message2": "Adding the following lines to the websites combined with the whitelisting from bidders will allow traffic to be monetized",
      "message3": "Relationship with websites is required to determine adagio as a DIRECT or RESELLER intermediary",
      "title": "Important notes"
    },
    "toggleCardAutoInvoice": {
      "main": "If you disable this setting, the publisher won’t be receiving monthly invoice requests emails and won’t have access to Settings > Invoices",
      "title": "Automatically Send Invoice Requests"
    }
  },
  "reasons": {
    "archived": "Archived",
    "notCompliant": "Not compliant",
    "owner": "Owner",
    "rejected": "Rejected"
  },
  "refreshRules": {
    "create": "@:actions.new Refresh",
    "createSuccess": "The refresh rule has been successfully created.",
    "edit": "{name}",
    "editSuccess": "The refresh rule has been updated successfully."
  },
  "regions": {
    "africa": "Africa",
    "asia": "Asia",
    "europe": "Europe",
    "northAmerica": "North America",
    "southAmerica": "South America"
  },
  "reports": {
    "create": "Create a report",
    "createError": "Could not create report: {0}",
    "createSuccess": "The report has been successfully created.",
    "deprecated": {
      "paragraph": "This report has been imported from the previous version of the manager, they won't be supported in the future and you'll need to create new reports to be able to update their content.",
      "title": "Deprecated report"
    },
    "dimensionsHelpText": "Dimensions marked deprecated will only contain empty values",
    "download": "Download report",
    "downloadInProgress": "Download is in progress",
    "edit": "{name}",
    "editSuccess": "The report has been successfully edited.",
    "table": "Report"
  },
  "roles": {
    "accountManager": "Account manager",
    "accountManagerDescription": "Allowed to perform actions on account management related resources",
    "admin": "Admin",
    "adminDescription": "Allowed to perform actions on organization management related resources",
    "adops": "Adops",
    "adopsDescription": "Allowed to perform actions on ad-operations related resources",
    "billing": "@.capitalize:app.billingEmailCommunication",
    "billingDescription": "Receives the monthly invoice requests from the platform by email",
    "user": "User",
    "userDescription": "Allowed to perform read on related resources",
    "viewer": "Viewer",
    "viewerDescription": "Allowed to perform read on related resources"
  },
  "seats": {
    "bidderOptin": "Bidder opt-in",
    "bidderOptinSuccess": "Bidder opt-in successfully updated",
    "bidTypeInputLabel": "What is the bid type?",
    "commissionSettingsDescription": "Find your bid type and commissions.",
    "configurationHeader": "What are the IDs?",
    "create": "Create a seat",
    "createDirect": "Create a direct seat",
    "createShared": "Create a shared seat",
    "createSuccess": "The seat has been successfully created.",
    "dfpDescription": "Through historical data, the engine can determine the most appropriate floor price in order to maximise bid prices.",
    "dfpEligible": "Eligible traffic percentage",
    "dfpEligibleDescription": "Indicate the eligible traffic percentage where the dynamic floor price can be applied.",
    "dynamicFeeDescription": "Allow the engine to artificially reduce the fees collected in order to increase the bid probability of winning",
    "dynamicFeeEnabledDescription": "Please insert below the minimal fee that can be applied post dynamic reduction.",
    "edit": "{name}",
    "editSuccess": "The seat has been successfully updated.",
    "generalSettingsDescription": "Find your seat name and IDs.",
    "increaseFactorDescription": "Indicate here the increased factor to apply to the second call of Dynamic Floor Price.",
    "invalidStringRegex": "This field has an invalid format, only alphanumeric characters, underscore and dash are allowed.",
    "maximumFeeReduction": "Maximal fee reduction",
    "maximumFeeReductionDescription": "Indicate here the maximum number of points of the commission that you’re willing to give up in order to ensure a winning bid. ",
    "modalConfirmDisableIsAutoWhitelistable": {
      "main": "By disabling this setting, new websites {strong}. The whitelisting status must be updated manually in order to monetize.",
      "strong": "will NOT be “approved” by default anymore",
      "title": "Disable auto-whitelisting"
    },
    "modalConfirmEnableIsAutoWhitelistable": {
      "main": "By enabling this setting, all websites {strong}.",
      "strong": "with \"pending\" whitelisting status will be updated to \"approved\"",
      "title": "Enable auto-whitelisting"
    },
    "nameInputLabel": "What name should we give to this seat?",
    "publisherOptin": "Publisher opt-in",
    "publisherOptinSuccess": "Publisher opt-in successfully updated",
    "rulesSubtitle": "Consult the existing split rules set to this seat.",
    "rulesTitle": "Split rules settings that contains this seat",
    "toggleCardIsAutoWhitelistable": {
      "main": "Toggle this setting to automatically update the whitelisting status of websites from \"pending\" to \"approved\" for this publisher. All new websites will be \"approved\" by default. You will still be able to update the whitelisting status of each website manually.",
      "title": "Auto-whitelisting"
    },
    "trafficShapingDescription": "Allow the engine to send only the most profitable adrequests segments to bidders in order to push for a given profitability level.",
    "whitelistingSubtitleDirect": "Insert and update the whitelisted websites on the platform based on the information sent by the bidder. You’ll be able to update those anytime.",
    "whitelistingSubtitleShared": "Follow-up the whitelisting status of all your website domains for this bidder and adapt your ads.txt lines accordingly.",
    "whitelistingTitle": "@.capitalize:app.whitelisting"
  },
  "seatsBoosterRules": {
    "abortConfirm": "Are you sure you want to abort the {name} seats booster?",
    "aborted": "Aborted",
    "abortError": "An error occurred when aborting the seats booster.",
    "abortSuccess": "The seats booster has been aborted with success.",
    "competitionSamplingHint": "Traffic allocated to compete with other seats in the traffic share.",
    "completed": "Completed",
    "createSuccess": "The seats booster has been successfully created.",
    "dashboardWidgetTooltip": "The data displayed in this widget are not filtered according to the dashboard's date picker.",
    "durationPerGroupHint": "If parallelization is 2 and duration is 5 days, then 2 seats will be boosted in parallel during 5 days.",
    "editSuccess": "The seats booster has been successfully saved.",
    "parallelizationHint": "If the Traffic share is 10% and parallelization is 2, then each seat takes 5% of traffic share.",
    "paused": "Paused",
    "pauseError": "An error occurred when pausing the seats booster.",
    "pauseSuccess": "The seats booster has been paused with success.",
    "planned": "Planned",
    "resumeError": "An error occurred when resuming the seats booster.",
    "resumeSuccess": "The seats booster has been resumed with success.",
    "running": "Running",
    "targetedWebsites": "Targeted websites",
    "templateSelector": "Pre-fill for {newSites} or a {simpleBoost}.",
    "templateSelectorNewSites": "new sites",
    "templateSelectorSimpleBoost": "simple boost",
    "trafficShareHint": "A Traffic share of 10% means that 10% of the traffic will be dedicated to Seats Boosting.",
    "unknown": "Unknown"
  },
  "sellerTypes": {
    "both": "Both",
    "intermediary": "Intermediary",
    "publisher": "Publisher"
  },
  "shapingRules": {
    "charts": {
      "description": "Always take the higher value in cases where there are 2 percentiles for the same Revenue Per Billion",
      "lastGraphUpdate": "Last graph update:",
      "loadFail": "An error occurred while attempting to load the data.",
      "noDataPlaceholder": "The graph will be available within two hours",
      "preTitle": "Correlation chart",
      "refreshInfos": "Refresh every 2 hours",
      "title": "Between Percentile & Gross Revenue Per Billion BidRequests",
      "updatedRuleWarning": {
        "content": "Graph data might be inacurrate until the next refresh (every two hours).",
        "title": "A shaping rule has been updated"
      },
      "xAxisTitle": "Percentile\nof revenue",
      "yAxisTitle": "Revenue\nper billion"
    },
    "create": "Create a shaping",
    "createSuccess": "The shaping has been successfully created.",
    "disabledInfo": {
      "content": "All the traffic is sent to the bidder (percentile = 100%)",
      "title": "No shaping applied"
    },
    "edit": "{name}",
    "editSuccess": "The shaping has been successfully updated.",
    "lastUpdate": "(last updated on {lastUpdate})",
    "optimizations": {
      "percentile": "Percentile of revenue",
      "rpb": "Revenue per billion target (RPB)"
    },
    "percentile100Info": {
      "content": "When the target of 100% is set, all the traffic is sent to the bidder. It corresponds to disable the traffic shaping.",
      "title": "All the traffic is sent"
    },
    "seatsTab": "Shaping rules settings that contains this seat",
    "seatsTabSubtitle": "Consult the existing shaping rules set to this seat.",
    "show": "{name}"
  },
  "sorts": {
    "addMore": "Add more sorts",
    "cancel": "Click on cancel to return sorts",
    "clearAll": "Clear all sorts",
    "confirmRemove": "Click on apply to confirm the removal of ({0}) sorts.",
    "getStarted": "Get started by adding a new sort.",
    "noSorts": "No sorts"
  },
  "splitRuleCases": {
    "addItem": "@:actions.add a case",
    "addOptimization": "@:actions.add an optimization",
    "addVariant": "@:actions.add variant",
    "clickOnAddOptimizationToConfigure": "Click on \"Add an optimization\" to configure the case.",
    "defaultMappingHint": "By default, the mapping defined in the mapping page will be applied.",
    "noOptimizationTemplateSelected": "No optimization template has been selected.",
    "optimizationsHint": "Adjust optimization for selected seats to find the best setup."
  },
  "splitRules": {
    "allSeatsHint": "By enabling, all new opted-in shared seats will be added automatically",
    "caseDescription": "A case is a specific optimization setup (either different algorithm or different algorithm's parameters) and can be set at the seat level. Add a case by clicking on \"Add a case\" button.",
    "casesCollection": "Cases",
    "caseSeatsCollection": "Seats configuration",
    "confirmRemoveCase": "Are you sure you want to remove this case?",
    "confirmRemoveMediatypeDetails": {
      "main": "You’ll lose {strong} that you’ve filled in.",
      "strong": "all the cases"
    },
    "confirmRemoveMediatypeTitle": "Are you sure you want to remove this mediatype?",
    "confirmRemoveSharedSeatMessage": "By removing this shared seat you'll disable the \"All Shared Seats\" option, and newly added shared seats will not be added to the split automatically.",
    "confirmRemoveSharedSeatTitle": "Disable auto adding of new shared seats?",
    "create": "Create a split rule",
    "createSuccess": "The split rule has been successfully created.",
    "duplicateCaseSuccess": "The case has been duplicated.",
    "duplicateConfirmText": "Are you sure you want to duplicate this split rule? This will create a copy with a different name. The copy will be disabled by default.",
    "duplicateError": "The split rule could not be duplicated.",
    "duplicateSuccess": "The split rule has been duplicated with success.",
    "edit": "{name}",
    "editSuccess": "The split rule has been successfully saved.",
    "formSectionSubtitle": "The description of the section",
    "indexSubtitle": "Splits are used to test different optimizations on publisher's seats through a specific targeting",
    "numberOfCases": "Number of cases",
    "priorityHint": "Used when two splits follow the same targeting to pick the one that applies, not that if this doesn't allow to tie break between splits an id will be used to prioritise.",
    "seatsConfig": "Seats configuration",
    "unsupportedMediatype": "{0} not supported",
    "unsupportedMediatypeFallback": "The engine will fall back to \"Auto\" for unsupported mediatypes."
  },
  "states": {
    "activated": "Activated",
    "darkMode": "dark mode",
    "deleted": "Deleted",
    "desktopMode": "desktop mode",
    "lightMode": "standard mode",
    "mobileMode": "mobile mode"
  },
  "status": {
    "billingDisabled": "Billing disabled",
    "live": "Live",
    "pending": "Pending"
  },
  "supplyChain": {
    "adagio": {
      "description": "Here is an extract of the content of our own sellers.json corresponding to this website"
    },
    "adstxtCrawlingError": "We experienced an error while crawling your ads.txt file which prevents validation of all lines shown below and therefore monetisation:",
    "adstxtCrawlingNotReachable": "We experienced an error while crawling your ads.txt file.",
    "adstxtCrawlingStatus": "Ads.txt crawling status",
    "adstxtCrawlingSuccess": "Your ads.txt file was successfully crawled.",
    "adstxtCrawlingWarning": "We successfully crawled your ads.txt file but you need to fix some warning to ensure optimal behaviour:",
    "adstxtLineField": "Symbols # and , are not allowed in an ads.txt line field",
    "adstxtLinesCopiedNotification": "The ads.txt lines have been copied to the clipboard.",
    "adstxtLinesStatus": "Ads.txt lines status",
    "adstxtLineStatus": {
      "CERT_EMPTY": "Certificate not found",
      "CERT_PRESENT": "Unexpected certificate found needs removing",
      "DOMAIN_MISMATCH": "Domain mismatch",
      "MISSING_MANAGER_ACCOUNT_ID": "Missing configured sellers.json account ID in settings",
      "NOT_CRAWLED": "Not crawled yet",
      "NOT_DIRECT": "Wrong relationship found, expected DIRECT",
      "NOT_FOUND": "Missing line",
      "NOT_RESELLER": "Wrong relationship found, expected RESELLER",
      "UNKNOWN_RELATIONSHIP": "Unknown relationship found"
    },
    "adstxtNotYetCrawled": "Your ads.txt file hasn't yet been crawled",
    "adstxtPublisherAccountIdUnique": "You can't have duplicated publisher account id in your manager lines",
    "adstxtTitleAdagio": "Adagio",
    "adstxtTitleAdstxt11Lines": "Owner & manager info",
    "adstxtTitleManager": "Intermediary Lines",
    "adstxtTitlePartners": "Partners lines",
    "adstxtTitlePendingBidder": "Pending Bidder Whitelisting",
    "allLinesOk": "All lines are valid",
    "bypassedMessage1": "Note that we've bypassed the Ads.txt check using an uploaded Ads.txt file. This will be removed as soon as we can access the Ads.txt.",
    "bypassedMessage2": "All potential issues shown below correspond to the uploaded file and not the crawled version.",
    "bypassedMessage3": "Contact your customer success manager to update the file used.",
    "bypassModalMessage": "If you have access to the ads.txt for this website, please paste its content below:",
    "copyAllLines": "@:actions.copyAllLines",
    "copyLineIssues": "Copy lines with issues",
    "copyMissingBidderLines": "@:actions.copyMissingBidderLines",
    "crawlInProgress": "Crawling...",
    "rawManagerTitle": "Manager info",
    "rawOwnerTitle": "Owner info",
    "refreshError": "An error happened during the refresh",
    "refreshInfo": "Crawl of the file has been successfully triggered. This action may take up to a few minutes.\nReload the page to get the latest status",
    "sellersjsonCrawlingError": "An error occurred when validating your Sellers.json:",
    "sellersjsonCrawlingStatus": "Sellers.json crawling status",
    "sellersjsonCrawlingStatusManager": "Manager sellers.json crawling status",
    "sellersjsonCrawlingStatusOwner": "Owner sellers.json crawling status",
    "sellersjsonCrawlingSuccess": "Your sellers.json file was successfully crawled.",
    "sellersjsonCrawlingWarning": "We successfully crawled your sellers.json but you need to fix some warning to ensure optimal behaviour:",
    "sellersjsonEntriesStatus": "Lines status",
    "sellersjsonEntryStatus": {
      "MISSING_MANAGER_ACCOUNT_ID": "Missing configured sellers.json account ID in settings",
      "NOT_CRAWLED": "Not crawled yet (sellers.json)",
      "NOT_FOUND": "Missing entry (sellers.json)",
      "NOT_PUBLISHER": "Wrong relationship found, expected PUBLISHER (sellers.json)"
    },
    "sellersjsonLinesCopiedNotification": "The sellers.json lines have been copied to the clipboard.",
    "sellersjsonNotYetCrawled": "Your sellers.json file hasn't yet been crawled",
    "showAllLines": "Show all lines",
    "showLineIssues": "Filter only issues",
    "supplyChainObjectCopiedNotification": "The supply chain object params have been copied to the clipboard.",
    "supplyChainObjectParams": {
      "description": "Find here the recommended supply chain object parameters that should be set in your requests"
    }
  },
  "targetings": {
    "addItem": "@:actions.add targeting",
    "blacklist": "Not in",
    "deprecated": {
      "main": "This targeting is {strong}. You will not be able to use it on new resources and removing it is permanent.",
      "strong": "deprecated"
    },
    "fetchError": "Unable to fetch targetings",
    "keywords": {
      "direct_seat": "Direct seat"
    },
    "segmentsTooltip": "Define a segment where the optimization will be applied. It allows to not have impacts with other ongoing optimizations.",
    "selector": {
      "subtitle": "Used targetings"
    },
    "toggleInfo": "When enabled you can fill in a targeting to be used in “Auto” mode",
    "valueDoesntExist": "At least one of the values doesn't exist.",
    "whitelist": "In"
  },
  "targetingSelect": {
    "any": "Any",
    "clearAll": "Clear all",
    "noItems": "No items",
    "selectOptions": "Select options",
    "selectResults": "Select results"
  },
  "trafficShaping": {
    "auto": "Auto",
    "disable": "Disabled",
    "grpb": "RPB (Gross)",
    "percentile": "Percentile",
    "percentileBreakpoint": "Percentile breakpoint",
    "percentileBreakpointDescription": "Indicate the percentile of revenue for each media type.\nThis information will be used to filter out requests.",
    "rpb": "RPB",
    "rpbFee": "RPB fee",
    "sampling": "Sampling"
  },
  "users": {
    "createInvitation": "Invite a user",
    "edit": "Edit {name}'s roles",
    "editSuccess": "{name}'s roles have been updated with success",
    "revoke": "Revoke a user",
    "revokeConfirm": "Are you sure you want to revoke access from <strong>{name}</strong>?",
    "revokeError": "Unable to revoke access from {name}",
    "revokeSuccess": "Access successfully revoked"
  },
  "validation": {
    "maxLength": "This field must have at most {0} elements.",
    "minLength": "This field must have a least {0} elements."
  },
  "views": {
    "create": "Create a view",
    "createSuccess": "The view has been created successfully.",
    "delete": "Are you sure you want to delete {name} view ?",
    "edit": "{name}",
    "editSuccess": "The view has been successfully updated.",
    "noContext": "Without context",
    "private": "Private",
    "public": "Public",
    "validationName": "The name is required and must be between 2 and 100 characters.",
    "withContext": "With context"
  },
  "webisteStatus": {
    "bypassed": "Bypassed",
    "error": "Error",
    "inactive": "Inactive",
    "pending": "Pending",
    "valid": "Valid",
    "warning": "Warning"
  },
  "websites": {
    "adstxt11": {
      "message": "The standard supply chain configuration is being upgraded to better protect your advertising inventory. Please update your Ads.txt and sellers.json as soon as possible to avoid any monetization loss since we expect some partners to start enforcing these new rules soon.",
      "title": "Our partners will soon enforce Ads.txt 1.1"
    },
    "archive": "@.capitalize:actions.archive website",
    "archiveError": "Unable to archive website",
    "archiveSuccess": "The website has been archived successfully.",
    "bulk": "Websites import",
    "contradictoryDuplicatesMessage": "We've found {0} ({1}%) duplicated lines with contradictory values. These lines have been skipped. You can download the recap of skipped websites.",
    "contradictoryDuplicatesTitle": "Contradictory duplicated lines skipped",
    "create": "New website",
    "createBulk": "Import websites",
    "createBulkError": "At least one site is not valid.",
    "createBulkSuccess": "All submitted sites have been created.",
    "createSuccess": "Your website has been successfully created!",
    "createSuccessMessage": "Around 30 minutes, we will be able to crawl your Ads.txt and .json files to detect potential errors",
    "deleted": "This website has been deleted.",
    "disable": "@.capitalize:actions.disable website",
    "disableError": "Unable to disable website",
    "disableSuccess": "The website has been disabled successfully.",
    "downloadBulkTemplate": "Download template",
    "downloadContradictoryLines": "Download contradictory lines",
    "downloadExistingLines": "Download existing websites lines",
    "edit": "{name}",
    "editSuccess": "The website has been successfully updated.",
    "existingFetchError": "Could not check for already existing websites.",
    "identicalDuplicatesMessage": "We've found {0} ({1}%) identical lines and have only kept a single version of each duplicate.",
    "identicalDuplicatesTitle": "Identical duplicates removed",
    "import": "Import websites",
    "inactive": "The website is inactive.",
    "isOwnedAndOperatedOverrided": "Relationship declared through isOwnedAndOperated field has been changed to managed based on non-empty manager id field",
    "managerAccountIdCleared": "Manager id has been cleared to match the relationship (owned) declared through isOwnedAndOperated",
    "managerAccountIdEmpty": "Manager id is empty but should contain a value based on the seller type (intermediary) declared or the relationship (managed) declared through isOwnedAndOperated field",
    "managerCertAuthIdCleared": "Manager Cert Auth ID has been cleared to match the relationship (owned) declared through isOwnedAndOperated",
    "managerLinesLimit": "You can only add up to 5 different account IDs",
    "modal": {
      "body": {
        "main": "{strong_0} into your ads.txt. Hence, your website domain will start monetizing as soon as the bidder whitelists it.",
        "strong_0": "Copy and paste the following missing lines"
      },
      "form": {
        "placeholder": "Fill in one or more emails to assign this task to someone else",
        "title": "Assign this task to"
      },
      "notifications": {
        "copyDoneMessage": "Your ads.txt will be crawled in 12 hours. Come back to follow up the status.",
        "copyDoneTitle": "Thank you for adding the bidders lines!",
        "laterMessage": "Your website will enlarge its monetization capacity once it has a valid supply chain configuration.",
        "laterTitle": "Remember to add the missing lines asap",
        "sendMessage": "Once the missing lines are added to your ads.txt, your website will be ready to be monetized with all bidders connections as soon it is whitelisted by them.",
        "sendTitle": "The task has been assigned and your bidder lines status is ongoing"
      },
      "title": "Add the missing bidders lines"
    },
    "ownerAccountIdCleared": "Owner id has been cleared to match the owner domain declaration",
    "ownerAccountIdEmpty": "Owner id is empty but should contain a value based on the owner domain delcaration",
    "ownerCertAuthIdCleared": "Owner Cert Auth ID has been cleared to match the owner domain declaration",
    "ownerDomainIsRequired": "Owner domain is required.",
    "ownerDomainShouldBeSubdomain": "Owner domain is not consistent with the publisher domain (settings) and the website domain declared - should be a equal or subdomain",
    "ownerDomainShouldntBeSubdomain": "Owner domain is not consistent with the publisher domain (settings) and the website domain declared - should be different",
    "prebidParamsCopyText": "Copy the params",
    "prebidParamsSectionDescription": "Use the params below in the Prebid.js bidder config",
    "prebidParamsSectionTitle": "Prebid.js",
    "sellersLineTitle": "{domain}/sellers.json",
    "skipExisting": "Existing websites skipped",
    "skipExistingMessage": "We've found {0} ({1}%) lines corresponding to existing websites that were skipped. You can download the recap of skipped websites.",
    "stepper": {
      "creationCompletion": "Creation completion",
      "subtitle": "This field is mandatory | The fields are mandatory",
      "subtitleActive": "Your website will be active as long as the crawler does not detect any blocking error",
      "subtitleSummary": "In approximately 30 min you'll have access on the platform to the validation status of this file once the different configuration have been crawled and analysed.",
      "titleActive": "Do you want to activate this website?",
      "titleDomain": "What is the website domain?",
      "titleManager": "Manager settings",
      "titleOwner": "Owner settings",
      "titleRelationship": "What is your publisher relationship?",
      "titleSummary": "Here are your sellers.json and ads.txt files"
    },
    "supplyChainValidationError": "Failed to generate the supply chain validation report",
    "supplyChainValidationReport": "Supply chain validation report",
    "warningIsActiveInvalidValue": "As no valid value was provided, we've set it by default to active.",
    "warningPublisherRelationship": "We've fixed the relationship to correspond to your Publisher settings.",
    "warningSellersJson": "We've cleared this field to make it correspond to the \" @:labels.isOwnedAndOperated \" column.",
    "whitelistingCopyBidderLines": "Copy missing bidder lines",
    "whitelistingSubtitle": "Follow-up the whitelisting status of your domain for each bidder at any time and adapt your ads.txt lines accordingly.",
    "whitelistingTitle": "@.capitalize:app.whitelisting"
  },
  "whitelists": {
    "bulk": "Whitelist import",
    "contradictoryDuplicatesMessage": "We've found {0} ({1}%) duplicated lines with contradictory values. These lines have been skipped. You can download the recap of skipped lines.",
    "contradictoryDuplicatesTitle": "Contradictory duplicated lines skipped",
    "createBulk": "Import whitelist",
    "createBulkError": "At least one line is not valid.",
    "createBulkSuccess": "All submitted items have been updated.",
    "downloadContradictoryLines": "Download contradictory lines",
    "downloadNonExistingLines": "Download non-existing websites lines",
    "edit": "{name}",
    "editSuccess": "The whitelist has been successfully updated.",
    "export": "Export",
    "exportDirectSeats": "Export Direct Seats Inventory",
    "identicalDuplicatesMessage": "We've found {0} ({1}%) identical lines and have only kept a single version of each duplicate.",
    "identicalDuplicatesTitle": "Identical duplicates removed",
    "import": "Import whitelist",
    "nonExistingFetchError": "Could not check already for non-existing websites.",
    "skipNonExisting": "Non-existing websites skipped",
    "skipNonExistingMessage": "We've found {0} ({1}%) lines corresponding to non-existing or archived websites that were skipped. You can download the recap of skipped websites.",
    "statusNotAllowed": "The status value is not allowed.",
    "unexpectedSeatId": {
      "message": "The expected seat ID is: {expectedId}",
      "title": "At least 1 row contains an unexpected seat ID. The file has not been imported."
    },
    "updateSubmissionDate": "Do you want to update the last submission date ?",
    "websiteCreatedAt": "Website created at",
    "whitelistStatusPreviouslyBlocked": "The website was previously blocked and will be approved from now on",
    "whitelistStatusPreviouslyWhitelisted": "The website was previously approved and will be blocked from now on"
  },
  "winningAdvertisers": {
    "lastSevenDays": "Last 7 days",
    "technicalLimitations": "Note that this table only shows domains seen at least 6 hours ago over past 7 days from the 8 february 2023."
  }
}
