<template>
  <form @submit="onSubmit">
    <p class="mb-2 font-medium">
      {{ t('actions.create-new', [t('labels.store')]) }}
    </p>
    <div class="grid w-full mb-2 text-sm grid-cols-vertical-form place-items-center-stretch">
      <div class="p-1 font-medium">
        {{ t('labels.name') }}
      </div>
      <div class="p-1">
        <input
          v-model="name"
          :placeholder="t('placeholders.nameUnique')"
          class="min-w-36 h-7 px-2.5 py-0.5 border border-gray-400 text-gray-700 focus:ring-primary-500 focus:border-primary-500 rounded focus:outline-none w-full"
        >
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.granularity') }}
      </div>
      <div class="p-1">
        <VueFormMultiselect
          v-model="granularity"
          mode="single"
          :close-on-select="true"
          :options="granularityMultiselectOptions"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.periodOverride') }}
      </div>
      <div class="p-1">
        <DefaultPeriodPicker
          v-model="periodOverride"
          button-classes="!h-7 mt-0 min-w-36"
          :none-label="t('labels.noOverride')"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.comparisonPeriodOverride') }}
      </div>
      <div class="p-1">
        <VueFormMultiselect
          :model-value="comparisonPeriodOverride || comparisonOverrideNone"
          mode="single"
          :close-on-select="true"
          :options="comparisonOverrideOptions"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="false"
          :can-deselect="false"
          @input="comparisonPeriodOverride = $event !== comparisonOverrideNone ? $event : undefined"
        />
      </div>
      <div class="p-1 font-medium">
        {{ t('dashboards.disableComparison') }}
      </div>
      <div class="flex items-center p-1">
        <input
          v-model="disableComparison"
          type="checkbox"
          class="w-4 h-4 mr-1 border-gray-400 rounded cursor-pointer text-primary-600 focus:ring-primary-500"
        >
        <span
          class="cursor-pointer select-none"
          @click="disableComparison = !disableComparison"
        >
          {{ disableComparison ? t('labels.yes') : t('labels.no') }}
        </span>
      </div>
      <div class="p-1 font-medium">
        {{ t('labels.source') }}
      </div>
      <div class="p-1">
        <VueFormMultiselect
          v-model="selectedSource"
          mode="single"
          :close-on-select="true"
          :options="sources.map(s => { return { value: s.uid, label: s.name } })"
          :classes="multiselectTailwindClassesCompact"
          :can-clear="true"
          :can-deselect="true"
        />
      </div>
      <template v-if="definition">
        <div class="p-1 font-medium">
          {{ t('labels.dataDimensions') }}
        </div>
        <div class="p-1">
          <Multiselect
            v-model="selectedDimensions"
            class="flex-grow"
            :values="dataDimensionOptions"
            :label="t('labels.dataDimensions')"
            :max="5"
            popover-panel-class="max-w-full"
            button-class="!h-7 !text-sm whitespace-nowrap min-w-36"
          />
        </div>
        <div class="p-1 font-medium">
          {{ t('labels.refreshDimensions') }}
        </div>
        <div class="p-1">
          <Multiselect
            v-model="selectedRefreshDimensions"
            class="flex-grow"
            :values="definition.dimensions.filter(d => !(d instanceof DateDimension) && !(d instanceof DateRollupDimension) && !d.enrichment && !definition!.hidden.includes(d.name)).map(d => d.name)"
            :label="t('labels.refreshDimensions')"
            button-class="!h-7 !text-sm whitespace-nowrap min-w-36"
          />
        </div>
        <div class="p-1 font-medium">
          {{ t('labels.metric', 2) }}
        </div>
        <div class="p-1">
          <Multiselect
            v-model="selectedMetrics"
            class="flex-grow"
            :values="Object.keys(definition.metrics)"
            :label="t('labels.metric', 2)"
            popover-panel-class="max-w-full"
            button-class="!h-7 !text-sm whitespace-nowrap min-w-36"
          />
        </div>
        <div class="col-span-2 p-1 font-medium">
          <hr class="my-1">
        </div>
        <div class="col-span-2 p-1 font-medium">
          {{ t('labels.requiredFilters') }}
        </div>
        <div class="col-span-2">
          <RequiredFilterForm
            :definition="definition"
            @submit="onRequiredFilterSubmit"
          />
        </div>
        <div
          v-if="createdRequiredFilters.length > 0"
          class="col-span-2 p-1"
        >
          <span
            v-for="(f, i) in createdRequiredFilters"
            :key="i"
            class="py-0.5 px-1 bg-gray-300 m-0.5 rounded text-sm break-words cursor-grab select-none inline-block"
          >
            {{ `${f.dimension} (${f.min} ≤ n ≤ ${f.max})` }}
            <button
              type="button"
              :title="t('actions.remove')"
              class="ml-1"
              @click="removeRequiredFilter(i)"
            >❌</button>
          </span>
        </div>
      </template>
    </div>
    <div class="flex flex-row px-1">
      <button
        type="submit"
        class="px-3 py-1 text-sm text-white bg-green-500 rounded disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
        :disabled="!validate"
      >
        {{ t('actions.create', [t('app.store')]) }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import VueFormMultiselect from '@vueform/multiselect'
import { v1 as uuidv1 } from 'uuid'
import { PropType, WatchStopHandle, computed, defineComponent, ref, unref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { RequiredFilter, Store, StoreFormSubmitEvent, granularityMultiselectOptions } from '@/plugins/dashboard'
import { Constructor, DataDefinition } from '@/plugins/dashboard/definition'
import { DateDimension, DateRollupDimension, TimeGranularityName } from '@/plugins/dashboard/dimensions'
import { Source } from '@/plugins/dashboard/source'

import { useNotificationsStore } from '@/store/notifications.store'

import { COMPARISON_DATE_RANGE_PRESETS, ComparisonRangePreset, DateRange, DateRangePreset } from '@/components/DateRangePicker/dateRange'
import { PRESET_TRANSLATIONS } from '@/components/DateRangePicker/translations'
import { multiselectTailwindClassesCompact } from '@/components/Multiselect'

import Multiselect from '../Multiselect.vue'

import DefaultPeriodPicker from './DefaultPeriodPicker.vue'
import RequiredFilterForm from './RequiredFilterForm.vue'

export default defineComponent({
  components: {
    VueFormMultiselect,
    Multiselect,
    DefaultPeriodPicker,
    RequiredFilterForm
  },
  props: {
    sources: {
      type: Array as PropType<Source[]>,
      required: true
    },
    stores: {
      type: Array as PropType<Store[]>,
      required: true
    }
  },
  emits: ['submit'],
  setup (props, { emit }) {
    const { t } = useI18n()
    let stopWatchEnrichment: WatchStopHandle | undefined
    const comparisonOverrideNone = 'none'

    const notificationsStore = useNotificationsStore()

    // Data
    const definition = ref(undefined as DataDefinition | undefined)
    const selectedDimensions = ref([] as string[])
    const selectedTimeDimensions = ref([] as string[])
    const selectedRefreshDimensions = ref([] as string[])
    const selectedMetrics = ref([] as string[])
    const selectedSource = ref('')
    const name = ref('')
    const granularity = ref('day' as TimeGranularityName)
    const disableComparison = ref(false)
    const periodOverride = ref(undefined as DateRangePreset | DateRange | undefined)
    const comparisonPeriodOverride = ref(undefined as ComparisonRangePreset | undefined)
    const createdRequiredFilters = ref([] as RequiredFilter[])

    // Computed
    const validate = computed((): boolean => {
      return name.value.length > 0 &&
        props.sources.find(s => s.uid === selectedSource.value) !== undefined &&
        selectedDimensions.value.length > 0 && selectedMetrics.value.length > 0
    })

    const comparisonOverrideOptions = computed(() => {
      const options = Object.keys(COMPARISON_DATE_RANGE_PRESETS).map(p => {
        return {
          value: p,
          label: PRESET_TRANSLATIONS[p],
          disabled: false
        }
      })
      return [
        {
          value: comparisonOverrideNone,
          label: t('labels.noOverride'),
          disabled: false
        },
        ...options
      ]
    })

    const dataDimensionOptions = computed(() => {
      if (definition.value === undefined) {
        return []
      }
      const source = props.sources.find(s => s.uid === selectedSource.value)!
      return definition.value.dimensions
        .filter(d => !definition.value!.hidden.includes(d.name))
        .map(d => {
          let label = d.name
          if (d.enrichment) {
            const dependency = source.findDependency(d.name)
            if (dependency !== undefined) {
              label += t('dashboards.requiresDimension', [dependency])
            }
          }
          return {
            label,
            value: d.name
          }
        })
    })

    // Watch
    watch(
      () => props.sources.length,
      () => {
        if (!props.sources.some(s => s.uid === selectedSource.value)) {
          selectedSource.value = ''
          definition.value = undefined
        }
      }
    )

    watch(
      () => selectedSource.value,
      () => {
        const source = props.sources.find(s => s.uid === selectedSource.value)
        if (stopWatchEnrichment !== undefined) {
          stopWatchEnrichment()
          stopWatchEnrichment = undefined
        }
        if (source) {
          stopWatchEnrichment = watch(
            () => source.enrichments.length,
            () => {
              source.getTableDefinition()
                .then(def => {
                  definition.value = def
                })
                .catch((err: any) => {
                  notificationsStore.add({
                    title: t('labels.error'),
                    message: t('dashboards.getTableDefinitionError', [source.uid, err.toString()]),
                    type: 'error'
                  })
                  console.error(err.toString())
                })
            },
            { immediate: true }
          )
          if (name.value === '') {
            name.value = source.uid
          }
        } else {
          definition.value = undefined
        }
      }
    )

    watch(
      () => definition.value,
      () => {
        if (definition.value) {
          selectedDimensions.value = []
          selectedRefreshDimensions.value = []
          selectedTimeDimensions.value = []
          definition.value.dimensions
            .filter(d => d instanceof DateDimension || d instanceof DateRollupDimension)
            .forEach(d => selectedTimeDimensions.value.push(d.name))

          selectedMetrics.value = []
          // selectedMetrics.value.push(...Object.keys(definition.value.metrics))
        }
      }
    )

    // Methods
    const onCheckboxInput = (model: string[], value: string) => {
      const i = model.indexOf(value)
      if (i !== -1) {
        model.splice(i, 1)
      } else {
        model.push(value)
      }
    }

    const removeRequiredFilter = (i: number) => {
      if (createdRequiredFilters.value[i] !== undefined) {
        createdRequiredFilters.value.splice(i, 1)
      }
    }

    const onRequiredFilterSubmit = (newRequiredFilter: RequiredFilter) => {
      createdRequiredFilters.value.push(newRequiredFilter)
    }

    const onSubmit = ($event: Event) => {
      $event.preventDefault()
      if (definition.value === undefined) {
        return
      }

      const metrics: Record<string, Constructor> = {}

      selectedMetrics.value.forEach(m => {
        metrics[m] = definition.value!.metrics[m]
      })

      const event = {
        uid: uuidv1(),
        name: unref(name),
        source: props.sources.find(s => s.uid === selectedSource.value)!,
        definition: {
          dimensions: definition.value.dimensions.filter(d => selectedDimensions.value.includes(d.name)),
          metrics,
          hidden: [],
          oneToOne: []
        } as DataDefinition,
        requiredFilters: [...createdRequiredFilters.value],
        refreshDimensions: [...selectedRefreshDimensions.value],
        timeDimensions: [...selectedTimeDimensions.value],
        granularity: granularity.value,
        disableComparison: disableComparison.value,
        periodOverride: periodOverride.value,
        comparisonPeriodOverride: comparisonPeriodOverride.value
      } as StoreFormSubmitEvent

      createdRequiredFilters.value = []
      name.value = ''
      emit('submit', event)
    }

    return {
      // Data
      definition,
      selectedDimensions,
      selectedTimeDimensions,
      selectedRefreshDimensions,
      selectedMetrics,
      selectedSource,
      createdRequiredFilters,
      name,
      disableComparison,
      periodOverride,
      comparisonPeriodOverride,
      granularity,

      // Computed
      validate,
      comparisonOverrideOptions,
      dataDimensionOptions,

      // Methods
      onCheckboxInput,
      removeRequiredFilter,
      onRequiredFilterSubmit,
      onSubmit,

      // Misc
      comparisonOverrideNone,
      multiselectTailwindClassesCompact,
      granularityMultiselectOptions,
      DateDimension,
      DateRollupDimension,
      t
    }
  }
})
</script>
