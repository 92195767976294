<template>
  <div>
    <span
      v-if="dateFormated && dateTimeFormated"
      :title="dateTimeFormated"
      class="w-full px-2 py-1 text-sm border-transparent border-1"
    >
      {{ dateFormated }}
      <span
        v-if="withDistance && humanReadable"
        class="text-xs italic text-gray-500"
      >
        ({{ humanReadable }})
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { format, formatDistance } from 'date-fns'
import { PropType, computed, defineComponent } from 'vue'

import { useSettingsStore } from '@/store/settings.store'

import { fromNow } from '@/utils/dates'

export default defineComponent({
  props: {
    value: {
      type: [String, null] as PropType<string | null>,
      required: true
    },
    withDistance: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup (props) {
    const settingsStore = useSettingsStore()

    const locale = computed(() => settingsStore.locale)

    const dateParsed = (v: string): number => {
      const dateParsed = Date.parse(v)
      return dateParsed
    }

    const dateFormated = computed(() => {
      if (!props.value || props.value === '0001-01-01T00:00:00Z') {
        return null
      }
      const date = dateParsed(props.value)
      return fromNow(new Date(date))
    })

    const dateTimeFormated = computed(() => {
      if (!props.value || props.value === '0001-01-01T00:00:00Z') {
        return null
      }
      const date = dateParsed(props.value)
      return format(date, 'yyyy-MM-dd HH:mm:ss', { locale: locale.value })
    })

    const humanReadable = computed(() => {
      if (!props.value || props.value === '0001-01-01T00:00:00Z') {
        return null
      }
      const date = dateParsed(props.value)
      return formatDistance(
        date,
        new Date(),
        {
          addSuffix: true,
          locale: locale.value
        }
      )
    })

    return {
      dateFormated,
      dateTimeFormated,
      humanReadable
    }
  }
})
</script>
