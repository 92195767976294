<template>
  <ContentIndex
    v-model:columns="columns.columns.value"
    v-model:filters="filters"
    :title="t('labels.report', 2)"
    :available-filters="columns.filterableColumns"
    :can-bulk-update="canBulkUpdate"
    :selected-rows="selectedRows"
  >
    <template #rightAction>
      <AppLink
        :to="createLink"
        size="sm"
        :hide-if-missing-permission="true"
      >
        {{ t('reports.create') }}
      </AppLink>
    </template>
    <Datatable
      v-model:items="rows"
      v-model:sorted-columns="filters.sorts.value"
      v-model:pagination="paginate"
      v-model:selected-rows="selectedRows"
      :columns="columns.columns.value"
      :is-loading="loading"
      :row-selected="rowSelected"
      :select-with="canBulkUpdate ? 'checkbox' : 'none'"
      :on-row-click-redirect-to="onRowClick"
      :has-error="!!error"
      @refresh="refresh()"
    >
      <template #name="{value}">
        <TruncateComponent :value="value" />
      </template>
      <template #dimensions="{ value }">
        <ArrayComponent :value="value || []" />
      </template>

      <template #metrics="{ value }">
        <ArrayComponent :value="value || []" />
      </template>

      <template #createdAt="{ value }">
        <DateComponent :value="value" />
      </template>

      <template #updatedAt="{ value }">
        <DateComponent :value="value" />
      </template>

      <template #isActive="{ row}">
        <ToggleComponent
          v-model="row.data.isActive"
          name="isActive"
          :is-disabled="true"
        />
      </template>
    </Datatable>

    <router-view
      :update-datatable="updateReports"
      :after-close="afterClose"
    />
  </ContentIndex>

  <BulkEdit
    v-if="isRouteIndex && canBulkUpdate"
    :columns="columns.columns.value"
    :api="updateReportBulk"
    :selected-rows="selectedRows"
    @success="onBulkSuccess"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'
import { useRouter } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { defaultPagination, PaginateWithoutRecords } from '@/types/paginate'

import { useContext } from '@/plugins/context'
import BulkEdit from '@/plugins/datatable/BulkEdit.vue'
import ArrayComponent from '@/plugins/datatable/Components/ArrayComponent.vue'
import DateComponent from '@/plugins/datatable/Components/DateComponent.vue'
import ToggleComponent from '@/plugins/datatable/Components/ToggleComponent.vue'
import TruncateComponent from '@/plugins/datatable/Components/TruncateComponent.vue'
import { useDatatable, useDatatableColumns } from '@/plugins/datatable/datatable'
import { FieldType, Row } from '@/plugins/datatable/datatable.d'
import Datatable from '@/plugins/datatable/Datatable.vue'
import { extractResponse } from '@/plugins/datatable/utils'
import { ServerFilters } from '@/plugins/filters/filters'
import { hasRole, useRoutePermissions } from '@/plugins/permissions'

import { useContextStore } from '@/store/context.store'

import { Report } from '@/models/report'

import { fetchReports, fetchReportsForSelectFilters, updateReportBulk } from '@/services/reports'

import AppLink from '@/components/Buttons/AppLink.vue'
import ContentIndex from '@/components/Content/Index.vue'

export default defineComponent({
  components: {
    BulkEdit,
    Datatable,
    ArrayComponent,
    ToggleComponent,
    DateComponent,
    TruncateComponent,
    ContentIndex,
    AppLink
  },
  setup () {
    const router = useRouter()
    const { t } = useI18n()

    const contextStore = useContextStore()

    const { currentRoute } = useRouter()
    const { roles } = useRoutePermissions(currentRoute.value)
    const canBulkUpdate = hasRole(roles, rolesGroup.user)
    const isRouteIndex = computed(() => currentRoute.value.name?.toString().split('.').includes('index'))

    const paginate = ref<PaginateWithoutRecords>(defaultPagination())
    const reports = ref<Report[]>([])
    const selectedRows = ref<Row[]>([])

    const { run, loading, error } = useRequest(fetchReports, {
      manual: true,
      onSuccess: (response) => {
        if (response) {
          if (response.data) {
            const { records, pagination } = extractResponse(response.data)
            reports.value = records
            paginate.value = pagination
          }
        }
      }
    })

    const columns = useDatatableColumns([
      {
        field: 'id',
        name: t('labels.id'),
        width: 80,
        isNumber: true,
        frozen: true,
        sortable: true,
        filterable: true,
        filterableType: FieldType.NUMBER,
        filterableAsyncOptions: fetchReportsForSelectFilters
      },
      {
        field: 'name',
        name: t('labels.name'),
        width: 250,
        sortable: true,
        filterable: true,
        filterableType: FieldType.STRING,
        filterableAsyncOptions: fetchReportsForSelectFilters,
        renderingType: 'truncate'
      },
      {
        field: 'period',
        name: t('labels.period'),
        width: 200,
        sortable: true,
        filterable: true,
        filterableType: FieldType.STRING,
        filterableAsyncOptions: fetchReportsForSelectFilters
      },
      {
        field: 'dayOfExport',
        name: t('labels.dayOfExport'),
        width: 100,
        isNumber: true,
        isVisible: false,
        sortable: true,
        filterable: true,
        filterableType: FieldType.NUMBER,
        filterableAsyncOptions: fetchReportsForSelectFilters
      },
      {
        field: 'timezone',
        name: t('labels.timezone'),
        width: 120,
        isVisible: false,
        sortable: true,
        filterable: true,
        filterableType: FieldType.STRING,
        filterableAsyncOptions: fetchReportsForSelectFilters
      },
      {
        field: 'dimensions',
        name: t('labels.dimension', 2),
        width: 250,
        filterable: true,
        filterableType: FieldType.ARRAY,
        renderingType: 'array'
      },
      {
        field: 'metrics',
        name: t('labels.metric', 2),
        width: 250,
        filterable: true,
        filterableType: FieldType.ARRAY,
        renderingType: 'array'
      },
      {
        field: 'createdAt',
        name: t('labels.createdAt'),
        width: 150,
        isVisible: false,
        sortable: true,
        filterable: true,
        filterableType: FieldType.DATE,
        renderingType: 'date'
      },
      {
        field: 'updatedAt',
        name: t('labels.updatedAt'),
        width: 150,
        sortable: true,
        filterable: true,
        filterableType: FieldType.DATE,
        renderingType: 'date'
      },
      {
        field: 'isActive',
        name: t('labels.isActive'),
        sortable: true,
        width: 120,
        locked: true,
        filterable: true,
        filterableType: FieldType.BOOLEAN,
        isBulkEditable: true,
        editableType: FieldType.BOOLEAN,
        renderingType: 'boolean'
      }
    ])

    const filters = new ServerFilters(run, columns, 'reports', paginate, selectedRows)
    filters.setDefaultSort()

    const updateReports = (queryParams?: Object) => {
      return run(queryParams)
    }

    const { rows } = useDatatable(reports, columns, filters, {
      sorts: 'server',
      selectedRows,
      onQueryUpdated: (queryParams) => {
        run(queryParams)
      }
    })

    const onBulkSuccess = () => {
      filters.resetPage()
      filters.debouncedUpdater()
    }

    const rowSelected = ref<string | undefined>(undefined)

    const onRowClick = (row: Row) => {
      const editReportRoute = useContext('reports.edit', { reportId: row.data.id })

      router.push(editReportRoute.value)

      rowSelected.value = row.id
    }

    const afterClose = () => {
      const selfRoute = useContext('reports.index', {})

      rowSelected.value = undefined

      router.push(selfRoute.value)
    }

    const createLink = useContext('reports.create', {})

    watch(
      () => contextStore.contextId,
      v => { if (v) { run(currentRoute.value.query) } },
      { flush: 'post' }
    )

    const refresh = () => {
      return run(currentRoute.value.query)
    }

    return {
      paginate,
      rows,
      filters,
      rowSelected,
      selectedRows,
      onRowClick,
      onBulkSuccess,
      canBulkUpdate,
      isRouteIndex,
      loading,
      columns,
      createLink,
      updateReports,
      updateReportBulk,
      afterClose,
      t,
      error,
      refresh
    }
  }
})
</script>
