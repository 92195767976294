import { Component, ComputedRef, Ref } from 'vue'

import { FormSelectOption, FormSelectOptionWithId } from '@/types/form'

import { ConfirmOptions } from '@/composables/useConfirm'

import FormMultiselectComponent from '@/components/Form/FormMultiselect.vue'

import { DefaultProps, FormRenderer } from './'

/**
 * @todo Handle `options` as async fetch function (see TargetingItem)
 */
interface Props extends DefaultProps {
  options?: FormSelectOption[] | FormSelectOptionWithId[] | ComputedRef<FormSelectOption[] | FormSelectOptionWithId[]> | Ref<FormSelectOption[] | FormSelectOptionWithId[]> | ((query?: string) => Promise<void | FormSelectOption[] | FormSelectOptionWithId[] | undefined>)
  mode?: 'tags' | 'single' | 'multiple'
  compact?: boolean
  canClear?: boolean
  onChange?: (value: any) => void
  onSelect?: (selectedId: any, selectedObject: any) => void
  onClear?: () => void
  store?: () => Promise<any>

  confirm?: (newVal: number | string | null | string[] | any[] | FormSelectOptionWithId) => boolean
  confirmOptions?: ConfirmOptions

  attr?: any // These will be bound on Multiselect component
}

export class FormMultiselect implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    const attrs = { ...this.defaultProps.attr }
    const cleanProps = { ...this.defaultProps }
    delete cleanProps.attr

    return { ...cleanProps, ...attrs }
  }

  component (): Component {
    return FormMultiselectComponent
  }
}
