<template>
  <Content
    v-model:columns="columns.columns.value"
    v-model:filters="filters"
    :title="t('labels.dashboard', 2)"
  >
    <template #rightAction>
      <app-link
        :to="createLink"
        size="sm"
        :hide-if-missing-permission="true"
      >
        {{ t('actions.new', [t('app.dashboard')]) }}
      </app-link>
    </template>
    <DataTable
      v-model:items="rows"
      v-model:sorted-columns="filters.sorts.value"
      v-model:pagination="paginate"
      :columns="columns.columns.value"
      :is-loading="loading"
      :on-row-click-redirect-to="onRowClick"
    >
      <template #name="{ value }">
        <TruncateComponent :value="value" />
      </template>
      <template #slug="{ value }">
        <TruncateComponent :value="value" />
      </template>

      <template #dashboardType="{ value }">
        <EnumComponent :value="value" />
      </template>

      <template #createdAt="{ value }">
        <DateComponent :value="value" />
      </template>

      <template #updatedAt="{ value }">
        <DateComponent :value="value" />
      </template>

      <template #isPublic="{ row }">
        <ToggleComponent
          v-model="row.data.isPublic"
          name="isPublic"
          :is-disabled="true"
        />
      </template>

      <template #isActive="{ row }">
        <ToggleComponent
          v-model="row.data.isActive"
          name="isActive"
          :is-disabled="true"
        />
      </template>
    </DataTable>
  </Content>

  <router-view :after-close="afterClose" />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'
import { useRouter } from 'vue-router'

import { dashboardTypes } from '@/static/dashboard'
import { rolesGroup } from '@/static/roles'

import { defaultPagination, PaginateWithoutRecords } from '@/types/paginate'

import { useContext } from '@/plugins/context'
import DateComponent from '@/plugins/datatable/Components/DateComponent.vue'
import EnumComponent from '@/plugins/datatable/Components/EnumComponent.vue'
import ToggleComponent from '@/plugins/datatable/Components/ToggleComponent.vue'
import TruncateComponent from '@/plugins/datatable/Components/TruncateComponent.vue'
import { useDatatable, useDatatableColumns } from '@/plugins/datatable/datatable'
import { Column, FieldType, Row } from '@/plugins/datatable/datatable.d'
import DataTable from '@/plugins/datatable/Datatable.vue'
import { extractResponse } from '@/plugins/datatable/utils'
import { ServerFilters } from '@/plugins/filters/filters'
import { hasRole, useRoutePermissions } from '@/plugins/permissions'

import { useContextStore } from '@/store/context.store'

import Content from '@/components/Content/Index.vue'

import { DashboardNavigation } from '../../../models/dashboard'
import { fetchDashboards } from '../../../services/dashboards'

export default defineComponent({
  components: {
    DataTable,
    Content,
    EnumComponent,
    ToggleComponent,
    DateComponent,
    TruncateComponent
  },
  setup () {
    const { t } = useI18n()
    const router = useRouter()

    const contextStore = useContextStore()

    const { currentRoute } = useRouter()

    const { roles } = useRoutePermissions(currentRoute.value)
    const hasAdagioRole = hasRole(roles, rolesGroup.adagio)

    const paginate = ref<PaginateWithoutRecords>(defaultPagination())
    const dashboards = ref<DashboardNavigation[]>([])

    const { run, loading } = useRequest(fetchDashboards, {
      manual: true,
      onSuccess: (response) => {
        if (response) {
          if (response.data) {
            const { records, pagination } = extractResponse(response.data)
            dashboards.value = records
            paginate.value = pagination
          }
        }
      }
    })

    const datatableColumns: Column[] = [
      {
        field: 'id',
        name: t('labels.id'),
        width: 80,
        isNumber: true,
        frozen: true,
        sortable: true,
        filterable: true,
        filterableType: FieldType.NUMBER
      },
      {
        field: 'name',
        name: t('labels.name'),
        width: 400,
        sortable: true,
        filterable: true,
        filterableType: FieldType.STRING
      },
      {
        field: 'slug',
        width: 400,
        name: t('labels.slug'),
        isVisible: false,
        sortable: true,
        filterable: true,
        filterableType: FieldType.STRING
      },
      {
        field: 'isPublic',
        name: t('labels.isPublic'),
        width: 120,
        locked: true,
        sortable: true,
        filterable: true,
        filterableType: FieldType.BOOLEAN
      }
    ]

    if (hasAdagioRole) {
      datatableColumns.splice(datatableColumns.length, 0,
        {
          field: 'isForGroup',
          name: t('labels.isForGroup'),
          width: 120,
          isVisible: false,
          sortable: true,
          filterable: true,
          filterableType: FieldType.BOOLEAN
        },
        {
          field: 'dashboardType',
          name: t('labels.dashboardType'),
          width: 160,
          sortable: true,
          filterable: true,
          filterableType: FieldType.STRING_LIST,
          filterableOptions: () => dashboardTypes
        },
        {
          field: 'createdAt',
          name: t('labels.createdAt'),
          width: 150,
          isVisible: false,
          sortable: true,
          filterable: true,
          filterableType: FieldType.DATE
        },
        {
          field: 'updatedAt',
          name: t('labels.updatedAt'),
          width: 150,
          sortable: true,
          filterable: true,
          filterableType: FieldType.DATE
        },
        {
          field: 'isActive',
          name: t('labels.status'),
          width: 120,
          locked: true,
          isVisible: false,
          sortable: true,
          filterable: true,
          filterableType: FieldType.BOOLEAN
        })
    }

    const columns = useDatatableColumns(datatableColumns)

    const filters = new ServerFilters(run, columns, 'publishers', paginate)
    filters.setDefaultSort()

    const { rows } = useDatatable(dashboards, columns, filters, {
      sorts: 'server',
      onQueryUpdated: (queryParams) => {
        run(queryParams)
      }
    })

    const onRowClick = async (row: Row) => {
      if (contextStore.hasContext) {
        const route = useContext('dashboards.show', { dashboardId: row.data.id.toString() })
        router.push(route.value)
      }
    }

    const afterClose = () => {
      const selfRoute = useContext('dashboards.index', {})
      router.push(selfRoute.value)
    }

    const createLink = useContext('dashboards.create', {})

    watch(
      () => contextStore.contextId,
      v => { if (v) { run() } },
      { flush: 'post' }
    )

    return {
      filters,
      paginate,
      loading,
      rows,
      onRowClick,
      afterClose,
      dashboards,
      columns,
      createLink,
      t
    }
  }
})
</script>
