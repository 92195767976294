import { useLocalStorage } from '@vueuse/core'
import { enUS } from 'date-fns/locale'
import numbro from 'numbro'
import { defineStore } from 'pinia'
import { computed } from 'vue'

import currencies from '@/static/currencies.json'
import languages from '@/static/languages.json'
import timezones from '@/static/timezones'

import { FormSelectOptionWithId } from '@/types/form'

import i18n from '@/plugins/i18n'

import { Settings } from '../types/app'

export const useSettingsStore = defineStore('settings', () => {
  const defaultSettings = computed<Settings>(() => {
    numbro.setLanguage('en-US')
    return {
      locale: 'en-US',
      currency: 'USD',
      timezone: 'Europe/Paris'
    }
  })

  const settings = useLocalStorage('settings', defaultSettings.value)

  // Based on ISO 4217
  const availableCurrencies = computed<Array<FormSelectOptionWithId<string>>>(() => {
    return currencies
  })

  const availableTimezones = computed<Array<FormSelectOptionWithId<string>>>(() => {
    return timezones
  })

  const availableLanguages = computed<Array<FormSelectOptionWithId<string>>>(() => {
    return languages
  })

  /**
   * Returns the date-fns locale based on settings.
   * Important, this is not the same locale as the one defined in vue-i18n instance.
   * @todo see if we can take advantage of vue-i18n date formating
   */
  const locale = computed(() => {
    switch (settings.value.locale) {
      default:
        return enUS
    }
  })

  const numbroLanguage = (settings: Settings): string => {
    switch (settings.locale) {
      default:
        return 'en-US'
    }
  }

  const updateSettings = async (newSettings: Settings): Promise<Record<string, any>> => {
    if (newSettings.locale !== settings.value.locale) {
      i18n.global.locale.value = newSettings.locale
      numbro.setLanguage(numbroLanguage(newSettings))
    }
    settings.value = newSettings
    return Promise.resolve({ data: true })
  }

  return {
    defaultSettings,
    settings,
    availableCurrencies,
    availableTimezones,
    availableLanguages,
    locale,
    updateSettings
  }
})
